import React, { useEffect, useState } from 'react';
import { HiOutlineFilter } from 'react-icons/hi';
import { MultiValue, SingleValue } from 'react-select';
import { RecoilState, useRecoilState, useResetRecoilState } from 'recoil';
import { twMerge } from 'tailwind-merge';

import { trackEvent } from '@/utils/trackEvent';

import { Filter } from '../../types/Filter';
import { cn } from '../../utils/cn';
import { Button } from '../atoms/Button';
import { DatePicker, DatePickerRange } from '../atoms/DatePicker';
import SelectInput from '../atoms/Select';

interface SideFilterProps {
  filters: Filter[];
  atom: RecoilState<any>;
  applyChanges: (validation: boolean) => void;
  children?: React.ReactNode;
  floatButtonClassName?: string;
  refetchOnChange: boolean;
  disabled?: boolean;
  isSearchable?: boolean;
  buttonClassName?: string;
}

const SideFilter: React.FC<SideFilterProps> = ({
  filters,
  atom,
  applyChanges,
  children,
  floatButtonClassName = '',
  refetchOnChange,
  disabled = false,
  isSearchable = true,
  buttonClassName = '',
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [filtersData, setFiltersData] = useRecoilState(atom);
  const resetFilters = useResetRecoilState(atom);

  const handleOpenFilter = () => {
    setIsOpen((old) => !old);
  };

  const SelectOption = (key: string, isMulti: boolean) => {
    return (selectedOptions: MultiValue<any> | SingleValue<any>) => {
      setFiltersData((prevFilters: any) => {
        if (isMulti)
          return {
            ...prevFilters,
            [key]: (selectedOptions as MultiValue<any>).map(
              (option) => option.value,
            ),
          };
        else return { ...prevFilters, [key]: [selectedOptions.value] };
      });
    };
  };

  const handleResetFilters = () => {
    resetFilters();
    setIsOpen(false);
    applyChanges(true);
  };

  const handleApplyFilters = () => {
    trackEvent(atom.key, 'filter_content', '');
    setIsOpen(false);
    applyChanges(false);
  };

  useEffect(() => {
    if (refetchOnChange) {
      filters.forEach((filter) => {
        if (filter.asyncFn) {
          filter.asyncFn('', filtersData);
        }
      });
    }
  }, [filtersData]);

  return (
    <div
      className={`fixed top-0 h-screen w-64 bg-primary ${
        isOpen ? 'right-0' : '-right-64'
      } z-40 transition-all duration-500`}
    >
      <div className="relative h-full w-full px-4 py-6">
        <button
          type="button"
          className={twMerge(
            'absolute -left-8 top-16 rounded-lg bg-primary p-2 py-3 pr-4',
            floatButtonClassName,
          )}
          onClick={handleOpenFilter}
        >
          <HiOutlineFilter className="h-5 w-5" color="white" />
        </button>

        <div
          className={cn('flex h-full flex-col overflow-hidden', {
            hidden: !isOpen,
          })}
        >
          <h1 className="mb-8 text-2xl font-bold text-white">Filtros</h1>

          <div className="flex h-full flex-col overflow-hidden">
            <div className="custom-scrollbar flex h-full flex-col gap-2 overflow-y-scroll px-2">
              {children}
              {filters.map((filter) => {
                const isDisabled = filter.isDisabled(filtersData);

                return (
                  <div key={filter.key} className="flex flex-col gap-1">
                    <h3
                      className={cn(
                        'text-sm font-bold text-white',
                        isDisabled && 'text-[#AAA]',
                      )}
                    >
                      {filter.name}
                    </h3>

                    {filter.key !== 'timeRange' ? (
                      <SelectInput
                        onChange={SelectOption(filter.key, filter.isMulti)}
                        options={filter.options}
                        asyncFn={
                          filter.asyncFn
                            ? (search) => filter.asyncFn!(search, filtersData)
                            : undefined
                        }
                        defaultValue={filter.defaultValue}
                        value={filtersData[filter.key]}
                        filtersData={filtersData}
                        isDisabled={isDisabled}
                        isMulti={filter.isMulti}
                        isSearchable={isSearchable}
                      />
                    ) : (
                      <DatePicker
                        value={filtersData?.[filter.key] as DatePickerRange}
                        onSelect={(val) => {
                          setFiltersData((prevFilters: any) => ({
                            ...prevFilters,
                            [filter.key]: {
                              startDate: val?.startDate,
                              endDate:
                                val?.startDate && !val?.endDate
                                  ? val.startDate
                                  : val?.endDate,
                            },
                          }));
                        }}
                        showClearButton={false}
                        showShortCuts={true}
                        className={{
                          p: 'text-white',
                          button: 'h-9 rounded-lg hover:ring-white/50',
                        }}
                      />
                    )}
                  </div>
                );
              })}
            </div>
            <div className="flex flex-col items-center gap-2">
              <Button
                variant={'secondary'}
                className={cn(' mt-9 w-full', buttonClassName)}
                onClick={handleApplyFilters}
                disabled={disabled}
              >
                Aplicar Filtros
              </Button>

              <Button
                variant={'link'}
                className="text-white underline"
                onClick={handleResetFilters}
              >
                Limpar filtros
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SideFilter;
