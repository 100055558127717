import Bell from '../assets/icons/bell.svg';
import Calendar from '../assets/icons/calendar.svg';
import FlowChart from '../assets/icons/flow-chart.svg';
import Gear from '../assets/icons/gear.svg';
import Home from '../assets/icons/home.svg';
import Role from '../assets/icons/role.svg';
import School from '../assets/icons/school.svg';
import { routes } from '../routes/routes';
import { SideBarItems } from '../types/SideBarItems';
export const sideBarItems: SideBarItems = [
  {
    ...routes.home,
    icon: <img src={Home} alt="Ícone Home" className="mr-2 w-4 flex-none" />,
  },
  {
    name: 'Escalas e Férias',
    icon: <img src={Calendar} alt="Ícone Escala" className="w-4 flex-none" />,
    subItems: [routes.schedule, routes.employees],
  },
  {
    name: 'Treinamentos',
    icon: (
      <img src={School} alt="Ícone Treinamentos" className="w-4 flex-none" />
    ),
    subItems: [
      routes.trainingMatrix,
      routes.trainingControl,
      routes.trainingSuppliers,
      routes.trainingFiles,
    ],
  },
  {
    name: 'Cargos e Requisitos',
    icon: <img src={Role} className="w-4 flex-none" />,

    subItems: [routes.jobTitle, routes.requirementsControl],
  },
  {
    name: 'Estrutura e Organograma',
    icon: (
      <img src={FlowChart} alt="Ícone Organograma" className="w-4 flex-none" />
    ),

    subItems: [routes.organizationChart, routes.fixedAllocations],
  },
  {
    ...routes.pendencies,
    icon: (
      <img src={Bell} alt="Ícone Pendências" className="mr-2 w-4 flex-none" />
    ),
  },
  {
    name: 'Cadastro',
    icon: <img src={Gear} alt="Ícone Engrenagem" className="w-4 flex-none" />,
    subItems: [routes.boardRegister, routes.employees],
  },
];
