import { z } from 'zod';

import { CombinedJobTitle } from '@/types/JobTitle';

type CombinedJobTitleKeys = keyof CombinedJobTitle;

export const RoleConstant: { value: CombinedJobTitleKeys; label: string }[] = [
  { value: 'name', label: 'Título do cargo' },
  { value: 'board', label: 'Diretoria' },
  { value: 'management', label: 'Gerência' },
  { value: 'seniorityLevelName', label: 'Senioridade do Cargo' },
  { value: 'workStations', label: 'Local de Trabalho' },
  { value: 'objective', label: 'Objetivo do Cargo' },
  { value: 'responsibilities', label: 'Funções e Responsabilidades' },
  { value: 'experience', label: 'Experiência' },
  { value: 'skillsAndCompetencies', label: 'Habilidades e Competências' },
  { value: 'workStations', label: 'GHE por local de trabalho' },
];

const JobTitleFilterOption = z
  .object({
    id: z
      .union([z.number(), z.string()])
      .transform((id) => String(id))
      .pipe(z.string()),
    name: z.string(),
  })
  .transform(({ id, name }) => ({
    value: id,
    label: name,
  }));

export const JobTitleFilterOptionsSchema = z.object({
  titles: z.array(JobTitleFilterOption).default([]),
  seniorityLevels: z.array(JobTitleFilterOption).default([]),
  companies: z.array(JobTitleFilterOption).default([]),
  boards: z.array(JobTitleFilterOption).default([]),
  managements: z.array(JobTitleFilterOption).default([]),
  workStations: z.array(JobTitleFilterOption).default([]),
  // requirementTypes: z.array(JobTitleFilterSingleOption).default([]),
  // educationalRequirements: z.array(JobTitleFilterOption).default([]),
  // professionalRequirements: z.array(JobTitleFilterOption).default([]),
  // otherRequirements: z.array(JobTitleFilterOption).default([]),
});

export type JobTitleFiltersOptions = z.infer<
  typeof JobTitleFilterOptionsSchema
>;
