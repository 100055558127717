const translationBreadCrumbs: { [key: string]: string } = {
  home: 'Página Inicial',
  'planejamento-de-escalas-e-ferias': 'Planejamento de Escalas e Férias',
  'visualizar-pendencias': 'Visualizar Pendências',
  'cargos-e-requisitos': 'Cargos',
  'controle-de-requisitos': 'Controle de Requisitos',
  colaboradores: 'Colaboradores',
  'idas-extras': 'Idas Extras ao Polo',
  realocacoes: 'Solicitação de Realocação',
  'matriz-treinamento': 'Matriz de Treinamento',
  'controle-treinamentos': 'Controle de Treinamentos',
  'planejamento-de-turma': 'Planejamento de Turma',
  ficheiros: 'Ficheiros',
  'visualizar-fornecedores': 'Visualizar Fornecedores',
  detalhamento: 'Detalhamento das Turmas',
  'historico-treinamentos': 'Histórico de Treinamentos',
  'visualizar-estrutura-organograma': 'Estrutura e Organograma',
  'cadastro-alocacoes-fixas': 'Alocações Fixas',
  'cadastro-de-diretoria': 'Cadastro de Diretoria',
  'sem-permissao': 'Sem Permissão',
};

const disabledBreadCrumbs: string[] = ['detalhamento'];

export { disabledBreadCrumbs, translationBreadCrumbs };
