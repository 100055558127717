import { UserTypesEnum } from '@/enums/UserTypesEnum';
import { getActualRole } from '@/utils/handleSavePermissions';

export const useRoles = () => {
  const roles = getActualRole();
  const hasSomeRoles = (requiredRoles: UserTypesEnum[]) => {
    if (roles?.includes(UserTypesEnum.Admin)) return true;
    if (!requiredRoles.length) return true;
    return roles?.some((role) => requiredRoles.includes(role));
  };

  const hasEveryRoles = (requiredRoles: UserTypesEnum[]) => {
    if (roles?.includes(UserTypesEnum.Admin)) return true;
    if (!requiredRoles.length) return true;

    return requiredRoles.every((role) => roles?.includes(role));
  };

  return { hasSomeRoles, hasEveryRoles };
};
