/* eslint-disable no-unused-vars */
import { FormControl } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { format, max, min } from 'date-fns';
import { useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import InputMask from 'react-input-mask';
import Select, { Theme } from 'react-select';
import { toast } from 'react-toastify';
import { z } from 'zod';

import { queryClient } from '@/App';
import { FormSchema } from '@/constants/formSchema/TrainingFileTeamInstructorFormSchema';
import { TransportOptionsLabels } from '@/constants/TransportConstants';
import api from '@/services/apiSgft';
import { TrainingFileInstructor } from '@/types/TrainingFile';
import { cn } from '@/utils/cn';
import { removeTimeZone } from '@/utils/formatDate';

import { Button } from '../atoms/Button';
import ControlledSimpleSelect from '../atoms/ControlledSimpleSelect';
import Line from '../atoms/Line';
import Modal from '../atoms/Modal';
import { SingleDatePicker } from '../atoms/SingleDatePicker';
import Spinner from '../atoms/Spinner';
import { Checkbox } from '../ui/checkbox';
import { FormField, FormItem, FormLabel } from '../ui/form';
import { styles } from '../ui/selectStyles';
import ValueContainer from '../ui/valueContainer';

interface TrainingFileInstructorEditModalProps {
  isOpen: boolean;
  onClose: () => void;
  instructor?: TrainingFileInstructor;
  trainingFileId: number;
  trainingFilePeriod: {
    startDate: string;
    endDate: string;
  };
}
export const TrainingFileInstructorEditModal = ({
  isOpen,
  onClose,
  instructor,
  trainingFileId,
  trainingFilePeriod,
}: TrainingFileInstructorEditModalProps) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const methods = useForm<z.infer<typeof FormSchema>>({
    defaultValues: {
      entryDate: instructor?.entryDate
        ? removeTimeZone(new Date(instructor?.entryDate))
        : undefined,
      exitDate: instructor?.exitDate
        ? removeTimeZone(new Date(instructor?.exitDate))
        : undefined,
      name: instructor?.name,
      logisticPlanning: !!instructor?.logisticPlanning,
      address: instructor?.address,
      extraAccommodation: instructor?.extraAccommodation,
      accommodationEntryDate: instructor?.accommodationEntryDate
        ? removeTimeZone(new Date(instructor?.accommodationEntryDate))
        : undefined,
      accommodationExitDate: instructor?.accommodationExitDate
        ? removeTimeZone(new Date(instructor?.accommodationExitDate))
        : undefined,
      extraMeal: instructor?.extraMeal,
      needTransport: instructor?.needTransport,
      requestiaId: instructor?.requestiaId,
      route: instructor?.route,
      phoneNumber: instructor?.phoneNumber,
      isThirdParty: !!instructor?.employeeId,
      planningDays: instructor?.trainingPlanningInstructors.map(
        (instructorDay) => ({
          date: new Date(instructorDay.date),
          needTransport: instructorDay.needTransport,
          transportOption:
            instructorDay?.transportOption || TransportOptionsLabels[0].value,
          requestiaId: instructorDay.requestiaId,
          address: instructorDay.address,
          trainingName: instructorDay.trainingName,
          planningInstructorId: instructorDay.id,
        }),
      ),
    },
  });
  const { register, handleSubmit, control, watch, setValue } = methods;
  const phoneNumber = watch('phoneNumber');
  const logisticPlanningWatch = watch('logisticPlanning');
  const extraAccommodationWatch = watch('extraAccommodation');

  const needTransportWatch = watch('needTransport');
  const entryDateWatch = watch('entryDate');

  const exitDateWatch = watch('exitDate');
  const isThirdParty = watch('isThirdParty');

  const sortedTrainingPlanningInstructors = useMemo(() => {
    return instructor?.trainingPlanningInstructors.sort((a, b) => {
      if (a.trainingName < b.trainingName) return -1;
      if (a.trainingName > b.trainingName) return 1;
      return new Date(a.date).getTime() - new Date(b.date).getTime();
    });
  }, [instructor?.trainingPlanningInstructors]);

  const handleSubmitInstructor = async (data: z.infer<typeof FormSchema>) => {
    setIsSubmitting(true);
    try {
      if (instructor) {
        await api.put(
          `training-files/${trainingFileId}/instructor/${instructor.id}`,
          data,
        );
      } else {
        await api.post(`training-files/${trainingFileId}/instructor/`, data);
      }
      toast.success('Sucesso ao inserir o instrutor', {
        theme: 'colored',
        toastId: 'success',
      });

      queryClient.invalidateQueries({ queryKey: ['training-files'] });

      queryClient.invalidateQueries({ queryKey: ['training-file-details'] });
      queryClient.invalidateQueries({
        queryKey: ['training-file-instructors'],
      });
      onClose();
    } catch (error) {
      const errorMessage =
        error instanceof AxiosError
          ? error.response?.data.message
          : 'Erro desconhecido';

      toast.error(`Erro ao inserir o instrutor: ${errorMessage}`, {
        theme: 'colored',
        toastId: 'error',
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const minDate = max([
    new Date(trainingFilePeriod.startDate),
    new Date(entryDateWatch || trainingFilePeriod.startDate),
  ])
    .toISOString()
    .split('T')[0];

  const maxDate = min([
    new Date(trainingFilePeriod.endDate),
    new Date(exitDateWatch || trainingFilePeriod.endDate),
  ])
    .toISOString()
    .split('T')[0];

  const fetchEmployees = async () => {
    try {
      const response = await api.get(`/employees`, {
        params: {
          size: 100000,
          page: 1,
        },
      });

      return response.data.employees;
    } catch (e) {
      toast.error('Erro ao carregar os dados', {
        theme: 'colored',
        toastId: 'error',
      });
      throw e;
    }
  };

  const { data: employees, isLoading: isEmployeeLoading } = useQuery(
    ['employee'],
    fetchEmployees,
    {
      retry: false,
      cacheTime: 0,
    },
  );

  const instructorsOptions =
    (employees &&
      employees?.map(
        (employee: {
          name: string;
          employee_number: string;
          position: string;
        }) => ({
          value: employee?.name,
          label: `${employee?.name.toString()} - Cargo: ${employee?.position?.toString()}`,
        }),
      )) ||
    [];

  const theme: (theme: Theme) => Theme = (theme) => ({
    ...theme,
    borderRadius: 4,
    spacing: {
      ...theme.spacing,
      controlHeight: 20,
    },
    colors: {
      ...theme.colors,
      neutral60: '#E5E5E5',
    },
  });

  console.log(instructor);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      className="w-[50rem]"
      title={'Inserir Instrutor'}
    >
      {isEmployeeLoading ? (
        <div className="my-4 flex h-full w-full items-center rounded-md bg-white">
          <Spinner size={50} />
        </div>
      ) : (
        <FormProvider {...methods}>
          <form
            onSubmit={handleSubmit(handleSubmitInstructor)}
            className="flex h-full w-full flex-col justify-between gap-4 px-4 py-4"
          >
            <div>
              <FormField
                control={control}
                name="isThirdParty"
                render={({ field }) => (
                  <FormItem className="flex flex-row items-start space-x-3 space-y-0">
                    <FormControl>
                      <Checkbox
                        checked={!!field.value}
                        onCheckedChange={(e) => {
                          setValue('name', '');
                          field.onChange(e);
                        }}
                        disabled={!!instructor}
                      />
                    </FormControl>
                    <div className="space-y-1 leading-none">
                      <FormLabel className="font-semibold  text-primary">
                        Instrutor Interno
                      </FormLabel>
                    </div>
                  </FormItem>
                )}
              />
            </div>
            <div>
              {isThirdParty ? (
                instructor && !!instructor.employeeId ? (
                  <div>
                    <span
                      className={
                        'font-graphie text-[10px] text-xs font-medium text-gray-600'
                      }
                    >
                      Colaborador<span className="text-red">*</span>
                    </span>
                    <input
                      required
                      {...register('name')}
                      className={cn(
                        'h-8 w-full rounded-sm px-2 text-sm text-gray-700 ring-1 ring-gray-300 ',
                      )}
                      type="text"
                      disabled={!!instructor?.employeeId || false}
                    />
                  </div>
                ) : (
                  <FormField
                    control={control}
                    name="name"
                    render={({ field }) => (
                      <FormItem className="flex w-full flex-row items-start space-x-3 space-y-0">
                        <FormControl className="w-full">
                          <span
                            className={
                              'font-graphie text-[10px] text-xs font-medium text-gray-600'
                            }
                          >
                            Colaborador<span className="text-red">*</span>
                          </span>
                          <Select
                            isSearchable
                            options={instructorsOptions}
                            isMulti={undefined}
                            onChange={(newValue: any) => {
                              setValue('name', newValue?.value);
                              const selectedEmployee = employees.find(
                                (employee: { name: string }) =>
                                  employee.name === newValue?.value,
                              );
                              if (selectedEmployee) {
                                setValue('address', selectedEmployee.address);
                              }
                            }}
                            placeholder=""
                            required={true}
                            key={'name'}
                            name={'name'}
                            className={`w-full rounded-lg font-graphie text-xs font-medium uppercase text-gray-600`}
                            theme={theme}
                            styles={styles}
                            components={{ ValueContainer }}
                            noOptionsMessage={() => 'Nenhuma opção encontrada'}
                            menuPosition="fixed"
                          />
                        </FormControl>
                      </FormItem>
                    )}
                  />
                )
              ) : (
                <div className="flex w-full flex-col justify-between gap-1">
                  <span className="text-xs text-gray-600">
                    Nome do instrutor <span className="text-red">*</span>
                  </span>
                  <input
                    required
                    {...register('name')}
                    className={cn(
                      'h-8 w-full rounded-sm px-2 text-sm text-gray-700 ring-1 ring-gray-300 ',
                    )}
                    type="text"
                    disabled={!!instructor || false}
                  />
                </div>
              )}
            </div>

            <div className="flex gap-4">
              <div className="flex w-full flex-col justify-between gap-1">
                <SingleDatePicker
                  name="entryDate"
                  minDate={removeTimeZone(new Date(minDate))}
                  maxDate={removeTimeZone(new Date(maxDate))}
                  form={methods}
                  label="Data de embarque"
                  required
                />
              </div>
              <div className="flex w-full flex-col justify-between gap-1">
                <SingleDatePicker
                  name="exitDate"
                  minDate={removeTimeZone(new Date(minDate))}
                  maxDate={removeTimeZone(new Date(maxDate))}
                  form={methods}
                  label="Data de desembarque"
                  required
                />
              </div>
            </div>
            <div className="flex flex-col gap-2">
              <FormField
                control={control}
                name="logisticPlanning"
                render={({ field }) => (
                  <FormItem className="flex flex-row items-start space-x-3 space-y-0">
                    <FormControl>
                      <Checkbox
                        checked={!!field.value}
                        onCheckedChange={field.onChange}
                      />
                    </FormControl>
                    <div className="space-y-1 leading-none">
                      <FormLabel className="font-semibold  text-primary">
                        Planejamento Logístico
                      </FormLabel>
                    </div>
                  </FormItem>
                )}
              />
              <Line className="mb-1" />
            </div>
            {logisticPlanningWatch && (
              <>
                <div className="flex flex-col gap-2">
                  <FormField
                    control={control}
                    name="extraMeal"
                    render={({ field }) => (
                      <FormItem className="flex flex-row items-start space-x-3 space-y-0">
                        <FormControl>
                          <Checkbox
                            checked={!!field.value}
                            onCheckedChange={field.onChange}
                          />
                        </FormControl>
                        <div className="space-y-1 leading-none">
                          <FormLabel className="font-semibold  text-primary">
                            Refeição extra
                          </FormLabel>
                        </div>
                      </FormItem>
                    )}
                  />
                  <Line className="mb-1" />
                </div>
                <div className="flex flex-col gap-2">
                  <FormField
                    control={control}
                    name="extraAccommodation"
                    render={({ field }) => (
                      <FormItem className="flex flex-row items-start space-x-3 space-y-0">
                        <FormControl>
                          <Checkbox
                            checked={!!field.value}
                            onCheckedChange={field.onChange}
                          />
                        </FormControl>
                        <div className="space-y-1 leading-none">
                          <FormLabel className="font-semibold  text-primary">
                            Alojamento
                          </FormLabel>
                        </div>
                      </FormItem>
                    )}
                  />
                  <Line className="mb-1" />

                  <div className="flex h-12 w-full gap-4">
                    <div className="flex w-full flex-col justify-between gap-1">
                      <SingleDatePicker
                        name="accommodationEntryDate"
                        minDate={removeTimeZone(new Date(minDate))}
                        maxDate={removeTimeZone(new Date(maxDate))}
                        form={methods}
                        label="Entrada"
                        disabled={!extraAccommodationWatch}
                      />
                    </div>
                    <div className="flex w-full flex-col justify-between gap-1">
                      <SingleDatePicker
                        name="accommodationExitDate"
                        minDate={removeTimeZone(new Date(minDate))}
                        maxDate={removeTimeZone(new Date(maxDate))}
                        form={methods}
                        label="Saída"
                        disabled={!extraAccommodationWatch}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex flex-col gap-2">
                  <FormField
                    control={control}
                    name="needTransport"
                    render={({ field }) => (
                      <FormItem className="flex flex-row items-start space-x-3 space-y-0">
                        <FormControl>
                          <Checkbox
                            checked={!!field.value}
                            onCheckedChange={field.onChange}
                          />
                        </FormControl>
                        <div className="space-y-1 leading-none">
                          <FormLabel className="font-semibold  text-primary">
                            Transporte
                          </FormLabel>
                        </div>
                      </FormItem>
                    )}
                  />
                  <Line className="mb-1" />

                  <div className="flex h-12 w-full gap-4">
                    <div className="flex w-full flex-col justify-between gap-1">
                      <span className="text-xs text-gray-600">
                        Endereço padrão
                      </span>
                      <input
                        disabled={!needTransportWatch}
                        {...register('address')}
                        className="h-full w-full rounded-sm px-2 text-sm text-gray-700 ring-1 ring-gray-300 disabled:bg-gray-100"
                        type="text"
                      />
                    </div>
                    <div className="flex h-12 w-full flex-col justify-between gap-1">
                      <span className="text-xs text-gray-600">
                        Telefone/Celular padrão
                      </span>
                      <InputMask
                        mask="(99) 99999-9999"
                        disabled={!needTransportWatch}
                        {...register('phoneNumber')}
                        className="h-full w-full rounded-sm px-2 text-sm text-gray-700 ring-1 ring-gray-300 disabled:bg-gray-100"
                        type="text"
                        value={phoneNumber || ''}
                      />
                    </div>
                  </div>

                  <div className="flex flex-col gap-2 rounded-md bg-gray-100 p-3">
                    <p className="text-sm font-semibold text-gray-700">
                      Detalhamento dos Dias
                    </p>
                    <div className="flex flex-col space-y-4">
                      {!sortedTrainingPlanningInstructors ? (
                        <p className="text-sm text-gray-700">
                          Adicione o instrutor em uma turma para visualizar o
                          detalhamento do dia.
                        </p>
                      ) : (
                        sortedTrainingPlanningInstructors?.map(
                          (instructorDay, index) => {
                            return (
                              <div
                                key={index}
                                className="flex w-full flex-col gap-4"
                              >
                                <div className="flex w-full gap-4">
                                  <div className="flex w-28 flex-col gap-3">
                                    <div className="leading-none">
                                      <FormLabel className="text-xs font-normal text-gray-600">
                                        Necessidade
                                      </FormLabel>
                                    </div>
                                    <FormField
                                      control={control}
                                      name={`planningDays.${index}.needTransport`}
                                      render={({ field }) => (
                                        <FormItem className="flex flex-row items-center justify-center space-x-3 space-y-0">
                                          <FormControl>
                                            <Checkbox
                                              disabled={!needTransportWatch}
                                              checked={field.value}
                                              onCheckedChange={field.onChange}
                                            />
                                          </FormControl>
                                        </FormItem>
                                      )}
                                    />
                                  </div>
                                  <div className="flex w-full flex-col justify-between gap-1">
                                    <span className="text-xs text-gray-600">
                                      Treinamento
                                    </span>
                                    <input
                                      readOnly
                                      disabled
                                      {...register(
                                        `planningDays.${index}.trainingName`,
                                      )}
                                      className="h-full w-full overflow-hidden text-ellipsis rounded-sm px-2 text-sm text-gray-700 ring-1 ring-gray-300 disabled:bg-gray-100"
                                      type="text"
                                      defaultValue={instructorDay.trainingName}
                                      title={instructorDay.trainingName}
                                    />
                                  </div>
                                  <div className="flex w-full flex-col justify-between gap-1">
                                    <span className="text-xs text-gray-600">
                                      Data
                                    </span>
                                    <input
                                      readOnly
                                      disabled
                                      value={format(
                                        removeTimeZone(
                                          new Date(instructorDay.date),
                                        ),
                                        'dd/MM/yyyy',
                                      )}
                                      className="h-full w-full rounded-sm bg-transparent px-2 text-sm text-gray-700 ring-1 ring-gray-300 disabled:cursor-not-allowed disabled:bg-gray-200"
                                      type="text"
                                    />
                                  </div>
                                  <div className="flex w-full flex-col justify-between gap-1">
                                    <span className="text-xs text-gray-600">
                                      Endereço
                                    </span>
                                    <input
                                      disabled={!needTransportWatch}
                                      {...register(
                                        `planningDays.${index}.address`,
                                      )}
                                      className="h-full w-full rounded-sm px-2 text-sm text-gray-700 ring-1 ring-gray-300 disabled:bg-gray-100"
                                      type="text"
                                      defaultValue={instructorDay.address}
                                    />
                                  </div>
                                </div>

                                <div className="flex w-full justify-center gap-4">
                                  <div className="flex h-11 w-[45%] flex-col justify-center gap-1">
                                    <ControlledSimpleSelect
                                      title={'Tipo de Realocação'}
                                      name={`planningDays.${index}.transportOption`}
                                      width="14rem"
                                      values={TransportOptionsLabels}
                                      control={control}
                                    />
                                  </div>
                                  <div className="flex h-11 w-[30%] flex-col justify-center gap-0">
                                    <span className="text-xs text-gray-600">
                                      Requestia
                                    </span>
                                    <input
                                      disabled={true}
                                      {...register(
                                        `planningDays.${index}.requestiaId`,
                                      )}
                                      value={instructorDay.requestiaId}
                                      className="h-full w-full rounded-sm px-2 text-sm text-gray-700 ring-1 ring-gray-300 disabled:bg-gray-100"
                                      type="text"
                                    />
                                  </div>
                                </div>
                              </div>
                            );
                          },
                        )
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}
            <div className="flex w-full justify-end gap-4">
              <Button
                variant="outline"
                className="w-28 text-primary ring-1 ring-primary"
                type="button"
                onClick={onClose}
              >
                Cancelar
              </Button>
              <Button
                variant="default"
                className="w-28 text-white"
                isLoading={isSubmitting}
              >
                Salvar
              </Button>
            </div>
          </form>
        </FormProvider>
      )}
    </Modal>
  );
};
