import React, { useEffect } from 'react';
import { CgPushChevronLeft } from 'react-icons/cg';
import { useLocation } from 'react-router-dom';
import { useRecoilState } from 'recoil';

import { useRoles } from '@/hooks/useRoles';

import { sideBarItems } from '../../constants/sideBarItems';
import { sidebarIsOpenAtom } from '../../state/sidebarIsOpen.atom';
import Accordion from '../atoms/Accordion';
import ActiveLink from '../atoms/ActiveLink';

const Sidebar: React.FC = () => {
  const location = useLocation();
  const [isOpen, setIsOpen] = useRecoilState(sidebarIsOpenAtom);

  const handleChangeSideBar = () => {
    setIsOpen((old) => !old);
  };

  useEffect(() => {
    const onLocationChanged = () => {
      setIsOpen(false);
    };

    onLocationChanged();
  }, [location, setIsOpen]);

  const { hasSomeRoles } = useRoles();
  return (
    <aside
      className={`flex h-screen flex-col gap-2 overflow-hidden bg-white px-3 pb-2 pt-10 transition-all duration-500 ${
        isOpen
          ? 'z-10 w-[200px] sm:w-[250px] md:w-[320px] lg:w-[340px]'
          : 'w-16'
      }`}
    >
      <img
        src="/logo.png"
        alt="Logo Origem"
        className={`-ml-1 ${
          !isOpen
            ? 'mb-[calc(2rem+15px)] h-[50px] w-[135px] md:w-[140px]'
            : 'mb-8 h-[50px] w-[120px] md:h-[65px] md:w-[185px]'
        } max-w-none resize-none transition-all duration-500`}
      />

      <div className="custom-scrollbar overflow-auto">
        {sideBarItems.map((sideBarItem) => {
          let hasPermission = false;
          if (!sideBarItem.subItems?.length) {
            hasPermission = hasSomeRoles(sideBarItem.requiredRoles ?? []);
          } else {
            hasPermission = sideBarItem.subItems.some((subItem) =>
              hasSomeRoles(subItem.requiredRoles ?? []),
            );
          }
          if (!hasPermission) {
            return <React.Fragment key={sideBarItem.name} />;
          }
          return sideBarItem.subItems ? (
            <Accordion
              key={sideBarItem.name}
              title={sideBarItem.name}
              icon={sideBarItem.icon}
              buttonClassName={`${
                sideBarItem.subItems
                  .map((sub) => sub.path)
                  .includes(location.pathname)
                  ? 'bg-btn-light'
                  : ''
              }`}
              forceClose={!isOpen}
              onClick={() => {
                setIsOpen(true);
              }}
            >
              {sideBarItem.subItems.length > 0 && (
                <div className="ml-2 flex flex-col gap-1 pt-2">
                  {sideBarItem.subItems?.map((subItem) => {
                    if (
                      subItem?.requiredRoles &&
                      !hasSomeRoles(subItem.requiredRoles)
                    )
                      return null;

                    return (
                      <ActiveLink
                        className="flex-none text-sm"
                        key={subItem.name}
                        to={subItem.path}
                      >
                        {subItem.name}
                      </ActiveLink>
                    );
                  })}
                </div>
              )}
            </Accordion>
          ) : (
            <ActiveLink
              className="w-full flex-none  text-sm"
              key={sideBarItem.name}
              to={sideBarItem.path!}
            >
              <div className="flex w-full items-center justify-start">
                {sideBarItem.icon}
                {isOpen ? sideBarItem.name : ''}
              </div>
            </ActiveLink>
          );
        })}
      </div>

      <div className="mt-auto flex min-h-[60px] items-center justify-between">
        <button
          className=" flex items-center gap-2 rounded border-none bg-transparent px-3 py-2 font-bold text-primary hover:bg-[#f5f5f5]"
          onClick={handleChangeSideBar}
        >
          <CgPushChevronLeft
            className={`transition-all duration-200 ${!isOpen && 'rotate-180'}`}
          />
        </button>

        {isOpen && (
          <img
            src="/origem.png"
            alt="Logo Origem"
            className="w-[80px] max-w-none resize-none md:w-[103px]"
          />
        )}
      </div>
    </aside>
  );
};

export default Sidebar;
