import { Navigate, RouteObject } from 'react-router-dom';

import RootLayout from '../components/templates/RootLayout';
import Login from '../pages/Login';
import { ProtectedRoute } from './protectedRoute';
import { routes } from './routes';

const pages: RouteObject[] = [
  {
    path: '/',
    element: <RootLayout />,
    children: [
      {
        element: <Navigate to="/home" />,
        path: '/',
        index: true,
      },
      ...Object.entries(routes).map(([, route]) => ({
        path: route.path,
        element: (
          <ProtectedRoute requiredRoles={route.requiredRoles}>
            {route.element}
          </ProtectedRoute>
        ),
      })),
    ],
  },
  {
    path: 'login',
    element: <Login />,
  },
];

export default pages;
