import * as AccordionPrimitive from '@radix-ui/react-accordion';
import React, { PropsWithChildren, useEffect, useState } from 'react';
import { MdExpandMore } from 'react-icons/md';
import { toast } from 'react-toastify';

import { queryClient } from '@/App';
import { CompanyTag } from '@/components/atoms/CompanyTag';
import SimpleMenu from '@/components/atoms/Menu';
import ModalConfirmation from '@/components/molecules/ConfirmationModal';
import { RegisterGeneralInformationJobTitleModal } from '@/components/molecules/RegisterGeneralInformationJobTitleModal';
import api from '@/services/apiSgft';
import { GroupedJobTitle, JobTitleStatus } from '@/types/JobTitle';
import { userIsRhOrAdmin } from '@/utils/handleSavePermissions';

interface JobTitleAccordionProps extends PropsWithChildren {
  groupedJobTitle: GroupedJobTitle;
  subJobTitleIds: number[];
  selectedJobTitleIds: number[];
  setSelectedJobTitleIds: (ids: number[]) => void;
  selectedAllChecked: boolean;
  setSelectedAllChecked: (checked: boolean) => void;
  jobTitleStatus: JobTitleStatus;
  defaultExpanded?: boolean;
  className?: string;
  exportChecked?: boolean;
}

export const JobTitleAccordion = ({
  groupedJobTitle,
  jobTitleStatus,
  subJobTitleIds,
  selectedJobTitleIds,
  setSelectedJobTitleIds,
  selectedAllChecked,
  setSelectedAllChecked,
  defaultExpanded,
  children,
  exportChecked,
}: JobTitleAccordionProps) => {
  const [isJobTitleModalOpen, setIsJobTitleModalOpen] = useState(false);
  const [
    isUpdateJobTitleConfirmationDialogOpen,
    setIsUpdateJobTitleConfirmationDialogOpen,
  ] = useState(false);
  const [isCreatingIssueToUpdateJobTitle, setIsCreatingIssueToUpdateJobTitle] =
    useState(false);
  const [
    isSendJobTitleToInDeactivationModalOpen,
    setIsSendJobTitleToInDeactivationModalOpen,
  ] = useState(false);
  const [isDeactivateJobTitleModalOpen, setIsDeactivateJobTitleModalOpen] =
    useState(false);
  const isRhOrAdmin = userIsRhOrAdmin();
  const [sentToValidation, setSentToValidation] = useState(
    groupedJobTitle.sentToQsmsValidation,
  );
  const uniqueCompanies = [
    ...new Map(
      groupedJobTitle.managements.map((management) => {
        const company = management.board.company;
        return [company.id, company];
      }),
    ).values(),
  ];

  const handleCreateIssueToUpdateJobTitle = async () => {
    setIsCreatingIssueToUpdateJobTitle(true);
    try {
      const response = await api.post('/job-title/create-issue', {
        jobTitleId: groupedJobTitle.id,
      });
      window.open(response.data.issueUrl);
    } catch (error) {
      console.error(error);
    } finally {
      setIsCreatingIssueToUpdateJobTitle(false);
      setIsUpdateJobTitleConfirmationDialogOpen(false);
    }
  };
  const [isChecked, setIsChecked] = useState(false || selectedAllChecked);

  useEffect(() => {
    if (!subJobTitleIds.every((id) => selectedJobTitleIds.includes(id))) {
      setIsChecked(false);
    } else {
      setIsChecked(true);
    }
  }, [selectedJobTitleIds, subJobTitleIds]);

  const handleCheckboxClick = (event: any) => {
    if (isChecked) {
      setSelectedJobTitleIds(
        selectedJobTitleIds.filter((id) => !subJobTitleIds.includes(id)),
      );

      if (selectedAllChecked) {
        setSelectedAllChecked(false);
      }
    } else {
      setSelectedJobTitleIds(
        Array.from(new Set([...selectedJobTitleIds, ...subJobTitleIds])),
      );
    }

    setIsChecked(!isChecked || selectedAllChecked);
    event.stopPropagation();
  };
  const handleEdit = () => {
    if (isRhOrAdmin) {
      setIsJobTitleModalOpen(true);
    } else {
      setIsUpdateJobTitleConfirmationDialogOpen(true);
    }
  };
  const handleSendToInDeactivation = async () => {
    try {
      const response = await api.put(
        `job-title/send-to-in-deactivation/${groupedJobTitle.id}`,
      );
      toast.success(response.data, {
        theme: 'colored',
        toastId: 'success',
      });
      queryClient.invalidateQueries(['jobTitle']);
      queryClient.invalidateQueries(['jobTitlesCount']);
    } catch {
      toast.error('Erro ao desativar cargo', {
        theme: 'colored',
        toastId: 'error',
      });
    }
  };

  const handleDeactivate = async () => {
    try {
      const response = await api.put(
        `job-title/deactivate/${groupedJobTitle.id}`,
      );
      toast.success(response.data, {
        theme: 'colored',
        toastId: 'success',
      });
      queryClient.invalidateQueries(['jobTitle']);
      queryClient.invalidateQueries(['jobTitlesCount']);
    } catch {
      toast.error('Erro ao desativar cargo', {
        theme: 'colored',
        toastId: 'error',
      });
    }
  };

  const handleSendToValidation = async () => {
    try {
      const response = await api.put(
        `job-title/send-created-issue-to-qsms-approval/${groupedJobTitle.id}`,
      );
      toast.success(response.data, {
        theme: 'colored',
        toastId: 'success',
      });
      setSentToValidation(true);
    } catch {
      toast.error('Erro ao enviar para validação de QSMS', {
        theme: 'colored',
        toastId: 'error',
      });
    }
  };
  const options = [
    ...(jobTitleStatus === 'active' || jobTitleStatus === 'inCreation'
      ? [
          {
            name: 'Editar Cargo',
            onClick: handleEdit,
            disabled: sentToValidation,
          },
        ]
      : []),
    ...(jobTitleStatus === 'active'
      ? [
          {
            name: 'Desativar Cargo',
            onClick: () => setIsSendJobTitleToInDeactivationModalOpen(true),
            disabled: !isRhOrAdmin,
          },
        ]
      : []),

    ...(jobTitleStatus === 'inDeactivation'
      ? [
          {
            name: 'Desativar Cargo',
            onClick: () => setIsDeactivateJobTitleModalOpen(true),
            disabled: !isRhOrAdmin,
          },
        ]
      : []),
    ...(jobTitleStatus === 'inCreation'
      ? [
          {
            name: 'Enviar para validação de QSMS',
            onClick: handleSendToValidation,
            disabled: sentToValidation,
          },
        ]
      : []),
  ];

  return (
    <>
      <AccordionPrimitive.Root
        type="multiple"
        className="w-full rounded-[10px]"
        defaultValue={defaultExpanded ? ['1'] : []}
      >
        <AccordionPrimitive.Item
          value="1"
          className="w-full rounded-[10px] bg-white"
        >
          <AccordionPrimitive.Header className="relative w-full rounded-[10px] bg-white">
            <AccordionPrimitive.Trigger className="group flex w-full grow rounded-[10px] px-5 py-[18px]">
              <div className="flex w-full items-center gap-4">
                {exportChecked && (
                  <input
                    type="checkbox"
                    className="h-5 w-5 cursor-pointer accent-primary"
                    checked={isChecked}
                    onClick={handleCheckboxClick}
                  />
                )}
                <h3 className="whitespace-nowrap text-left text-base font-medium text-[#4A4A4A]">
                  {groupedJobTitle.title}
                </h3>
                <div className="ml-auto flex items-center justify-end gap-4">
                  <div className="flex items-center gap-2">
                    {uniqueCompanies.map((company) => (
                      <CompanyTag companyName={company.name} key={company.id} />
                    ))}
                  </div>
                  <MdExpandMore className="text-3xl text-primary transition-transform duration-300 ease-[cubic-bezier(0.87,_0,_0.13,_1)] group-data-[state=open]:rotate-180" />
                </div>
                <SimpleMenu
                  options={options}
                  upDotsButton
                  upDotsButtonColor="action"
                  disabled={options.length === 0}
                />
              </div>
            </AccordionPrimitive.Trigger>
          </AccordionPrimitive.Header>
          <AccordionPrimitive.Content className="overflow-hidden rounded-[10px] bg-white px-5 text-[15px] data-[state=closed]:animate-slideUp data-[state=open]:animate-slideDown">
            <section className="flex flex-col gap-2 pb-4 pt-[10px]">
              {children}
            </section>
          </AccordionPrimitive.Content>
        </AccordionPrimitive.Item>
      </AccordionPrimitive.Root>
      {isJobTitleModalOpen && (
        <RegisterGeneralInformationJobTitleModal
          isOpen={isJobTitleModalOpen}
          onClose={() => setIsJobTitleModalOpen(false)}
          data={groupedJobTitle}
          type={'edit'}
        />
      )}
      <ModalConfirmation
        isOpen={isUpdateJobTitleConfirmationDialogOpen}
        confirmAction={handleCreateIssueToUpdateJobTitle}
        title="Confirmação de Edição"
        onClose={() => setIsUpdateJobTitleConfirmationDialogOpen(false)}
        description={`Ao prosseguir com esta ação, você será redirecionado para o BPMS para editar o cargo ${groupedJobTitle.title}`}
        isLoading={isCreatingIssueToUpdateJobTitle}
      />

      <ModalConfirmation
        isOpen={isSendJobTitleToInDeactivationModalOpen}
        confirmAction={handleSendToInDeactivation}
        title="Confirmação de Desativação"
        onClose={() => setIsSendJobTitleToInDeactivationModalOpen(false)}
        description={`Ao prosseguir com esta ação, o cargo ${groupedJobTitle.title} será enviado para desativação`}
        isLoading={isCreatingIssueToUpdateJobTitle}
      />

      <ModalConfirmation
        isOpen={isDeactivateJobTitleModalOpen}
        confirmAction={handleDeactivate}
        title="Confirmação de Desativação"
        onClose={() => setIsDeactivateJobTitleModalOpen(false)}
        description={`Prossiga somente se todas as medidas já foram realizadas para desativar o cargo ${groupedJobTitle.title}`}
        isLoading={isCreatingIssueToUpdateJobTitle}
      />
    </>
  );
};
