import { ReactFlow } from '@xyflow/react';
import { forwardRef, HTMLAttributes } from 'react';
import { useRecoilValue } from 'recoil';

import { hideEmployeesAtom } from '@/state/HideEmployeesChart.atom';
import { PositionChartDoubleClickArgs } from '@/types/PositionChartDoubleClickArgs';

import ErrorIcon from '../../assets/icons/error.svg';
import ConnectionLine from '../atoms/ConnectionLine';
import CustomNode from '../molecules/CustomNode';

const nodeTypes = {
  custom: CustomNode,
};

const edgeTypes = {
  custom: ConnectionLine,
};

export interface Position {
  id: number;
  positionId: number;
  board: { id: number; name: string; company?: { id: number; name: string } };
  management: { id: number; name: string };
  coordination: { id: number; name: string };
  name: string;
  regime: string;
  actualEmployees?: number;
  amountOfExpectedEmployees?: number;
  amountOfAllocatedEmployees: number;

  active?: boolean;
  workStation: { id: number; name: string; pole: string };
  numberOfReports: number;
  reportsTo: { id: number; name: string }[];
  isIntermediary: boolean;
  isParent?: false;
  generalInformationJobTitle: {
    id: number;
    name: string;
  };
  otherCompanyConnection: {
    id: number;
    name: string;
  };
}

export interface PositionWithAllocation extends Position {
  isFixedAllocation: boolean;
  headCount: number;
  employee?: {
    id: number;
    name: string;
    alias?: string;
    employeeNumber: string;
    email: string;
    company: string;
    thirdParty: boolean;
    scaleCode?: string;
    cnpj_company: {
      id: number;
      name: string;
    };
  };
}

export interface SubFlow {
  subFlow: boolean;
  handleChange?: (data: PositionChartDoubleClickArgs) => void;
}
export interface Graph {
  board?: string;
  management?: string;
  graph: { positions: (Position | PositionWithAllocation)[] }[];
  coordination?: string;
}
interface Props extends HTMLAttributes<HTMLDivElement> {
  data?: Graph;
  handleChange?: (data: PositionChartDoubleClickArgs) => void;
  defaultViewPort?: { x: number; y: number; zoom: number };
  toPrint?: boolean;
}

const ChartGraph = forwardRef<HTMLDivElement, Props>(
  (
    {
      data,
      handleChange,
      defaultViewPort = {
        x: innerWidth > 1500 ? 100 : 150,
        y: 0,
        zoom: innerWidth > 1500 ? 0.9 : 0.8,
      },
      toPrint = false,
    },
    ref,
  ) => {
    const hideEmployees = useRecoilValue(hideEmployeesAtom);
    const positionsIds = new Map<
      number,
      {
        counted: boolean;
        total: number;
        initialX: number;
        initialY: number;
        reportsTo: { id: number; name: string }[];
        numberOfReports: number;
      }
    >();
    const employeesPositions = new Map<number, number>();

    if (!data || !data.graph) {
      return (
        <div className="h-full w-full px-[1%]">
          <div className="flex h-full w-full flex-col items-center justify-center rounded-md">
            <img src={ErrorIcon} alt="Ícone de erro" className="w-24" />
            <div className="flex flex-col items-center text-center">
              Por favor, adicione um filtro válido!
            </div>
          </div>
        </div>
      );
    }

    const nodeWidth = 165;
    const nodeHeight = 155;
    const nodeSmallHeigth = 72;

    const sortByParent = (
      data: (PositionWithAllocation | Position)[][],
    ): (PositionWithAllocation | Position)[][] => {
      return data.map((array) => {
        const parentMap = new Map<
          string,
          (PositionWithAllocation | Position)[]
        >();
        array.forEach((item) => {
          const { reportsTo } = item;
          const key = reportsTo
            .slice()
            .sort((a, b) => a.id - b.id)
            .map((report) => report.id)
            .join(',');

          if (!parentMap.has(key)) {
            parentMap.set(key, []);
          }
          const items = parentMap.get(key);
          if (items) {
            items.push(item);
          }
        });
        const sortedKeys = Array.from(parentMap.keys()).sort();
        const sortedArray = sortedKeys.flatMap((key) => {
          const items = parentMap.get(key);
          return items || [];
        });

        return sortedArray;
      });
    };

    const reorderAssessorData = (
      data: (PositionWithAllocation | Position)[][],
    ) => {
      const result = [];

      for (const innerArray of data) {
        const assessors = innerArray.filter((item) => item.isIntermediary);
        const nonAssessors = innerArray.filter((item) => !item.isIntermediary);

        if (assessors.length > 0) {
          result.push(assessors);
        }
        if (nonAssessors.length > 0) {
          result.push(nonAssessors);
        }
      }

      return result;
    };

    const splitIntoChunks = <T,>(array: T[], size: number): T[][] => {
      const chunks: T[][] = [];
      for (let i = 0; i < array.length; i += size) {
        chunks.push(array.slice(i, i + size));
      }
      return chunks;
    };

    const processData = (data: (PositionWithAllocation | Position)[][]) => {
      const result = [];

      for (const innerArray of data) {
        if (innerArray.some((item) => item.isIntermediary)) {
          result.push(
            ...splitIntoChunks(
              innerArray.filter((item) => item.isIntermediary),
              2,
            ),
          );
        } else {
          result.push(innerArray);
        }
      }

      return result;
    };

    const getDuplicatePositionIds = (
      data: (PositionWithAllocation | Position)[][],
    ): number[] => {
      const positionIdDetails = new Map<
        number,
        { count: number; numberOfReportsSet: Set<number> }
      >();

      data.forEach((positionList) => {
        positionList.forEach((position) => {
          const { positionId, numberOfReports } = position;

          if (!positionIdDetails.has(positionId)) {
            positionIdDetails.set(positionId, {
              count: 0,
              numberOfReportsSet: new Set(),
            });
          }

          const details =
            positionIdDetails && positionIdDetails.get(positionId);
          if (details) {
            details.count += 1;
            if (numberOfReports !== undefined) {
              details.numberOfReportsSet.add(numberOfReports);
            }
          }
        });
      });

      return Array.from(positionIdDetails.entries())
        .filter(
          ([, { count, numberOfReportsSet }]) =>
            count > 1 &&
            numberOfReportsSet.size === 1 &&
            !numberOfReportsSet.has(0),
        )
        .map(([positionId]) => positionId);
    };

    const rearrangeDataByPositionId = (
      data: (PositionWithAllocation | Position)[],
    ) => {
      const grouped = data.reduce(
        (groups, item) => {
          if (!groups[item.positionId]) {
            groups[item.positionId] = [];
          }
          groups[item.positionId].push(item);
          return groups;
        },
        {} as { [key: number]: (PositionWithAllocation | Position)[] },
      );

      const groupedSorted = Object.entries(grouped).map(
        ([positionId, group]) => {
          const withReports = group.filter((item) => item.numberOfReports > 0);
          const withoutReports = group.filter(
            (item) => item.numberOfReports === 0,
          );
          return {
            positionId: Number(positionId),
            group: [...withReports, ...withoutReports],
          };
        },
      );
      const groupedWithMultipleItems = groupedSorted.filter(
        (group) => group.group.length > 1,
      );
      const groupedWithSingleItem = groupedSorted.filter(
        (group) => group.group.length === 1,
      );

      const finalSorted = [
        ...groupedWithMultipleItems.flatMap((group) => group.group),
        ...groupedWithSingleItem.flatMap((group) => group.group),
      ];

      return finalSorted;
    };

    let newData = data.graph.map((item) => item.positions);
    newData = sortByParent(newData);
    newData = reorderAssessorData(newData);
    newData = processData(newData);
    const repeatedIds = getDuplicatePositionIds(newData);

    const itemsPerRow =
      newData[newData.length - 1].length > 15
        ? 8
        : newData[newData.length - 1].length > 7
          ? 6
          : newData[newData.length - 1].length;

    const spacing = 60;
    const totalParents = newData.filter((el) => el[0]?.isParent).length;
    const initialY = 50;
    let parentsPerRow = 0;
    newData[newData.length - 1] = rearrangeDataByPositionId(
      newData[newData.length - 1],
    );
    const initialNodes = newData
      .map((el, level) => {
        let initialX: number;
        let x;
        if (level === newData.length - 1 && el.length > itemsPerRow) {
          const x: any[] = [];
          for (let i = 0; i < Math.ceil(el.length / itemsPerRow); i++) {
            const group = el.slice(i * itemsPerRow, (i + 1) * itemsPerRow);
            const groupLength =
              i === Math.ceil(el.length / itemsPerRow) - 1
                ? group.length
                : itemsPerRow;

            initialX =
              (innerWidth - 142) / 2 -
              (groupLength * nodeWidth + (groupLength - 1) * spacing) / 2;

            x.push(
              ...group.map((item, idx) => {
                employeesPositions.set(item.id, item.positionId);
                if (
                  !positionsIds.has(item.positionId || item.id) ||
                  item.numberOfReports === 0
                ) {
                  positionsIds.set(item.positionId || item.id, {
                    counted: false,
                    total: 1,
                    initialX: initialX + idx * (nodeWidth + spacing),
                    initialY: initialY + (level + i) * (nodeHeight + spacing),
                    reportsTo: item.reportsTo,
                    numberOfReports: item.numberOfReports,
                  });
                } else {
                  const existingData = positionsIds.get(
                    item.positionId || item.id,
                  );
                  if (existingData) {
                    positionsIds.set(item.positionId || item.id, {
                      counted: false,
                      total: existingData.total + 1,
                      initialX: existingData.initialX,
                      initialY: existingData.initialY,
                      reportsTo: item.reportsTo,
                      numberOfReports: item.numberOfReports,
                    });
                  }
                }
                return {
                  id: item.id.toString(),
                  position: {
                    x: initialX + idx * (nodeWidth + spacing),
                    y:
                      initialY +
                      (level + i) * (nodeHeight + spacing) -
                      totalParents * (nodeHeight - nodeSmallHeigth) +
                      (repeatedIds.includes(item.positionId || item.id)
                        ? 6
                        : 0),
                  },
                  data: { ...item, active: true, hideEmployees, handleChange },
                  type: 'custom',
                  parentId: item.positionId || item.id,
                  style: {},
                };
              }),
            );
          }
          return x;
        } else {
          initialX =
            (innerWidth - 142) / 2 -
            (el.length * nodeWidth + (el.length - 1) * spacing) / 2;

          x = el.map((e, idx) => {
            const hasChildren =
              level < newData.length - 1 &&
              newData[level + 1].filter((child) =>
                child.reportsTo.some((report) => report.id === el[0].id),
              ).length > 0;
            employeesPositions.set(e.id, e.positionId);
            if (
              !positionsIds.has(e.positionId || e.id) ||
              e.numberOfReports === 0
            ) {
              positionsIds.set(e.positionId || e.id, {
                counted: false,
                total: 1,
                initialX: initialX + idx * (nodeWidth + spacing),
                initialY: initialY + level * (nodeHeight + spacing),
                reportsTo: e.reportsTo,
                numberOfReports: e.numberOfReports,
              });
            } else {
              const existingData = positionsIds.get(e.positionId || e.id);
              if (existingData) {
                positionsIds.set(e.positionId || e.id, {
                  counted: false,
                  total: existingData.total + 1,
                  initialX: existingData.initialX,
                  initialY: existingData.initialY,
                  reportsTo: e.reportsTo,
                  numberOfReports: existingData.numberOfReports,
                });
              }
            }

            return {
              id: e.id.toString(),
              position: {
                x:
                  initialX +
                  idx * (nodeWidth + spacing) -
                  (el.length === 1 && el[0].isIntermediary && !hasChildren
                    ? 110
                    : 0),
                y:
                  initialY +
                  level * (nodeHeight + spacing) -
                  parentsPerRow * (nodeHeight - nodeSmallHeigth) +
                  (repeatedIds.includes(e.positionId || e.id) ? 6 : 0),
              },
              data: {
                ...e,
                active: true,
                handleChange,
                hideEmployees,
                isOdd: idx % 2 !== 0,
                hasChildren,
              },
              type: 'custom',
              parentId: e.positionId || e.id,
              style: {},
            };
          });
        }
        if (newData[level][0].isParent) {
          parentsPerRow++;
        }
        return x;
      })
      .flat();

    positionsIds.forEach((el, key) => {
      if (el.total > 1) {
        initialNodes.push({
          id: `p${key}`,
          position: { x: el.initialX - 15, y: el.initialY - 1 },
          style: {
            backgroundColor: 'white',
            border: '1px solid gray',
            borderRadius: '8px',
            zIndex: -1,
            cursor: 'pointer',
            height: 170,
            width: el.total * (195 + 30) - 30,
          },
          type: 'custom',
          data: {
            subflow: {
              height: 170,
              width: el.total * (195 + 30) - 30,
              positionId: key,
            },
            handleChange,
          },
        });
      }
    });

    const initialEdges = initialNodes
      .flatMap(({ data: el }) => {
        const positionData = positionsIds.get(el?.positionId);
        if (positionData && positionData.total > 1) {
          if (positionData.counted) return [];
          positionsIds.set(el.positionId, {
            ...positionData,
            counted: true,
          });
          return positionData.reportsTo.map(
            (reportsToId: { id: { toString: () => string } }) => ({
              id: `ep${el.positionId}-${reportsToId.id}`,
              source: reportsToId.id.toString(),
              target: `p${el.positionId}`,
              type: 'custom',
              data: { spacing },
            }),
          );
        } else {
          if (el?.reportsTo?.length > 0) {
            let lastId = '';
            return el.reportsTo.map(
              (reportsToId: { id: { toString: () => string } }) => {
                const positionId = employeesPositions.get(
                  Number(reportsToId.id),
                );
                const positionData = positionId
                  ? positionsIds.get(positionId)
                  : undefined;

                if (positionData?.counted) {
                  const actualId = `e${el.id}-${positionId}`;
                  if (
                    positionData?.numberOfReports > 0 &&
                    lastId !== actualId
                  ) {
                    positionsIds.set(positionId ?? 0, {
                      ...positionData,
                      numberOfReports: positionData.numberOfReports - 1,
                    });
                    lastId = actualId;
                    return {
                      id: `e${el.id}-${positionId}`,
                      source: `p${positionId}`,
                      target: el.id.toString(),
                      type: 'custom',
                      data: { ...el, spacing },
                    };
                  }
                  return {};
                } else {
                  return {
                    id: `e${el.id}-${reportsToId.id}`,
                    source: reportsToId.id.toString(),
                    target: el.id.toString(),
                    type: 'custom',
                    data: { ...el, spacing },
                  };
                }
              },
            );
          } else {
            return [];
          }
        }
      })
      .filter((el) => Object.keys(el).length > 0 && el.source !== '');

    if (toPrint) {
      const levels =
        newData.length -
        1 +
        Math.ceil(newData[newData.length - 1].length / itemsPerRow);
      if (levels > 6) {
        defaultViewPort.zoom = 0.55;
        defaultViewPort.x = defaultViewPort.x + (innerWidth > 1550 ? 300 : 265);
      } else if (levels > 5) {
        defaultViewPort.zoom = 0.65;
        defaultViewPort.x = defaultViewPort.x + (innerWidth > 1550 ? 200 : 215);
      } else if (levels > 4 || itemsPerRow > 7) {
        defaultViewPort.zoom = 0.75;
        defaultViewPort.x = defaultViewPort.x + (innerWidth > 1550 ? 150 : 135);
      }
    }

    return (
      <ReactFlow
        nodes={initialNodes}
        edges={initialEdges}
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        ref={ref}
        proOptions={{ hideAttribution: true }}
        defaultViewport={defaultViewPort}
      />
    );
  },
);

ChartGraph.displayName = 'ChartGraph';
export default ChartGraph;
