import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { useRecoilValue } from 'recoil';

import EmptyIcon from '@/assets/icons/empty.svg';
import Spinner from '@/components/atoms/Spinner';
import BasicTabs from '@/components/molecules/BasicTabs';
import SideFilter from '@/components/molecules/SideFilter';
import TrainingFileModal from '@/components/molecules/TrainingFileModal';
import TrainingFileCard from '@/components/organisms/TrainingFileCard';
import { Button } from '@/components/ui/button';
import { filters } from '@/constants/filters/TrainingFilesFilter';
import { trainingFileStatus } from '@/constants/trainingFileStatus';
import api from '@/services/apiSgft';
import { trainingFilesFiltersAtom } from '@/state/TrainingFilesFilter.atom';
import { TrainingFile } from '@/types/TrainingFile';

const TrainingFiles = () => {
  const [isCreateTrainingFileModalOpen, setIsCreateTrainingFileModalOpen] =
    useState(false);
  const filterValues = useRecoilValue(trainingFilesFiltersAtom);
  const [filteredData, setFilteredData] = useState(filterValues);
  const fetchTrainingFile = async (): Promise<Array<TrainingFile>> => {
    try {
      const response = await api.get('/training-files', {
        params: {
          status: filteredData.status,
          suppliers: filteredData.suppliers,
          trainings: filteredData.trainings,
          startDate: filteredData.timeRange.startDate,
          endDate: filteredData.timeRange.endDate,
          trainingType: filteredData.trainingType,
          trainingFileNumber: filteredData.trainingFileNumber,
          trainingFileName: filteredData.trainingFileName,

          responsible: filteredData.responsible,
        },
      });
      return response.data;
    } catch (e) {
      toast.error('Erro ao carregar os dados', {
        theme: 'colored',
        toastId: 'error',
      });
      throw e;
    }
  };

  const { data: trainingFiles, isLoading } = useQuery(
    ['training-files', filteredData],
    fetchTrainingFile,
    { retry: false },
  );

  const handleOpenModal = () => {
    setIsCreateTrainingFileModalOpen(true);
  };
  const handleCloseModal = () => {
    setIsCreateTrainingFileModalOpen(false);
  };
  const completedTrainingFiles = trainingFiles?.filter((trainingFile) =>
    [trainingFileStatus.CONCLUDED, trainingFileStatus.FINISHED].includes(
      trainingFile.status,
    ),
  );
  const plannedTrainingFiles = trainingFiles?.filter(
    (trainingFile) =>
      ![trainingFileStatus.CONCLUDED, trainingFileStatus.FINISHED].includes(
        trainingFile.status,
      ),
  );
  const handleApplyFilter = (isReseting: boolean) => {
    if (isReseting) {
      setFilteredData(() => ({
        trainings: [],
        suppliers: [],
        status: [],
        timeRange: {
          endDate: undefined,
          startDate: undefined,
        },
        trainingFileNumber: [],
        trainingFileName: [],
        trainingType: [],
        responsible: [],
      }));
    } else {
      setFilteredData(() => ({
        ...filterValues,
      }));
    }
  };
  return (
    <div className="relative flex h-full max-h-screen w-full flex-col items-start">
      <SideFilter
        refetchOnChange
        filters={filters}
        atom={trainingFilesFiltersAtom}
        applyChanges={handleApplyFilter}
      />
      {isCreateTrainingFileModalOpen && (
        <TrainingFileModal
          isOpen={isCreateTrainingFileModalOpen}
          onClose={handleCloseModal}
        />
      )}
      <div className="absolute -top-10 right-20 flex items-center">
        <Button
          className="mx-5 my-2 h-7  rounded-full px-5 text-xs font-medium text-white"
          onClick={handleOpenModal}
        >
          Criar Ficheiro
        </Button>
      </div>
      <div className="max-h-[85vh] w-full">
        <BasicTabs
          background="transparent"
          tabsNames={['Planejados', 'Concluídos']}
          tabs={
            isLoading
              ? [
                  <div
                    className="flex h-full w-full items-center justify-center"
                    key="loading-1"
                  >
                    <Spinner size={50} />
                  </div>,
                  <div
                    className="flex h-full w-full items-center justify-center"
                    key="loading-2"
                  >
                    <Spinner size={50} />
                  </div>,
                ]
              : [
                  <div
                    key="training-files-opened"
                    className="flex w-full min-w-min flex-col gap-2 pt-2 "
                  >
                    {plannedTrainingFiles?.length ? (
                      plannedTrainingFiles?.map((trainingFile) => {
                        return (
                          <TrainingFileCard
                            key={trainingFile.id}
                            trainingFile={trainingFile}
                          />
                        );
                      })
                    ) : (
                      <div className="flex h-full min-h-[50vh] w-full flex-col items-center justify-center rounded-md bg-white">
                        <img src={EmptyIcon} className="w-40" />
                        <div className="w-full text-center text-sm">
                          <p>Nenhum ficheiro encontrado com esses parâmetros</p>
                          <span className="font-semibold text-blue-800">
                            Cadastre um ou altere os valores dos filtros para
                            visualizar!
                          </span>
                        </div>
                      </div>
                    )}
                  </div>,
                  <div
                    key="training-files-opened"
                    className="flex w-full min-w-min flex-col gap-2 py-2 "
                  >
                    {completedTrainingFiles?.length ? (
                      completedTrainingFiles?.map((trainingFile) => {
                        return (
                          <TrainingFileCard
                            key={trainingFile.id}
                            trainingFile={trainingFile}
                          />
                        );
                      })
                    ) : (
                      <div className="flex h-full min-h-[50vh] w-full flex-col items-center justify-center rounded-md bg-white">
                        <img src={EmptyIcon} className="w-40" />
                        <div className="w-full text-center text-sm">
                          <p>Nenhum ficheiro encontrado com esses parâmetros</p>
                          <span className="font-semibold text-blue-800">
                            Cadastre um ou altere os valores dos filtros para
                            visualizar!
                          </span>
                        </div>
                      </div>
                    )}
                  </div>,
                ]
          }
        />
      </div>
    </div>
  );
};

export default TrainingFiles;
