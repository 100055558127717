export enum UserTypesEnum {
  Admin = 'SGFT.Admin',
  Sop = 'SGFT.Sop',
  Rh = 'SGFT.Rh',
  Regulatorio = 'SGFT.Regulatorio',
  RhTreinamento = 'SGFT.Training.Rh',
  QsmsTreinamento = 'SGFT.Training.Qsms',
  User = 'SGFT.User',
  ReadOnly = 'SGFT.ReadOnly',
  OnDutyWorker = 'SGFT.OnDutyWorker',
  Manager = 'SGFT.Manager',
  Director = 'SGFT.Directors',
  Coordinator = 'SGFT.Coordinator',
}
