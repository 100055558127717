/* eslint-disable array-callback-return */
import api from '../../services/apiSgft';
import { JobTrainingMatrixFilters } from '../../state/JobTraningMatrixFilter.atom';
import { Filter } from '../../types/Filter';
import { trainingTypeOptions } from '../trainingTypeOptions';

const fetchFilter = async (
  field: string,
  search: string,
  atom?: JobTrainingMatrixFilters,
  jobTitleType?: string,
) => {
  const response = await api.get(`${field}`, {
    params: {
      search,
      size: 1000000,
      page: 1,
      locations: atom?.location,
      jobTitles: atom?.jobTitle,
      trainings: atom?.training,
      trainingType: atom?.trainingType,
      management: jobTitleType === 'management' ? [] : atom?.management,
      areaCoordination: atom?.areaCoordination,
      workstation: atom?.workstation,
      getAll: false,
    },
  });

  if (field === 'trainings/locations') {
    return response.data
      .filter((it: { name: string }) => it.name !== 'Bacia do Espírito Santo')
      .map((it: { name: string }) => ({
        label:
          it.name === 'TAMAC'
            ? 'Terminal Aquaviáro de Maceió (TAMAC)'
            : it.name,
        value: it.name,
      }));
  }
  if (jobTitleType === 'management') {
    const uniqueValuesSet = new Set(
      response.data.map((it: { name: string }) => it.name),
    );
    return Array.from(uniqueValuesSet).map((management) => ({
      label: management,
      value: management,
    }));
  }
  if (jobTitleType === 'areaCoordination') {
    const uniqueValuesSet = new Set(
      response.data.map(
        (it: { areaCoordination: string }) => it.areaCoordination,
      ),
    );
    return Array.from(uniqueValuesSet).map((areaCoordination) => ({
      label: areaCoordination,
      value: areaCoordination,
    }));
  }
  if (jobTitleType === 'workstation') {
    const uniqueValuesSet = new Set(
      response.data.map((it: { workstation: string }) => it.workstation),
    );
    return Array.from(uniqueValuesSet).map((workstation) => ({
      label: workstation,
      value: workstation,
    }));
  }
  if (jobTitleType === 'jobTitle') {
    const uniqueValuesSet = new Set(
      response.data.map((it: { name: string }) => it.name),
    );
    return Array.from(uniqueValuesSet).map((jobTitle) => ({
      label: jobTitle,
      value: jobTitle,
    }));
  }
  return response.data
    .filter((it: { name: string }) => it.name !== 'TAMAC')
    .map((it: { name: string }) => {
      return {
        label: it.name,
        value: it.name,
      };
    });
};

export const filters: Filter[] = [
  {
    key: 'trainingType',
    name: 'Tipo de Treinamento',
    options: trainingTypeOptions.filter((el) => el.value !== 'Desenvolvimento'),
    isMulti: false,
    asyncFn: undefined,
    isDisabled: () => false,
  },
  {
    key: 'location',
    name: 'Matriz',
    options: [],
    isMulti: false,
    asyncFn: (search, atom?: JobTrainingMatrixFilters) =>
      fetchFilter('trainings/locations', search, atom),
    isDisabled: () => false,
  },
  {
    key: 'management',
    name: 'Gerência',
    options: [],
    isMulti: true,
    asyncFn: (search, atom?: JobTrainingMatrixFilters) =>
      fetchFilter('management/filter', search, atom, 'management'),
    isDisabled: (atom: JobTrainingMatrixFilters) => atom.location.length === 0,
  },
  {
    key: 'areaCoordination',
    name: 'Coordenação',
    options: [],
    isMulti: true,
    asyncFn: (search, atom?: JobTrainingMatrixFilters) =>
      fetchFilter('job-titles/filter', search, atom, 'areaCoordination'),
    isDisabled: (atom: JobTrainingMatrixFilters) => atom.location.length === 0,
  },
  {
    key: 'workstation',
    name: 'Local de Trabalho',
    options: [],
    isMulti: true,
    asyncFn: (search, atom?: JobTrainingMatrixFilters) =>
      fetchFilter('job-titles/filter', search, atom, 'workstation'),
    isDisabled: (atom: JobTrainingMatrixFilters) => atom.location.length === 0,
  },
  {
    key: 'jobTitle',
    name: 'Função',
    options: [],
    isMulti: true,
    asyncFn: (search, atom?: JobTrainingMatrixFilters) =>
      fetchFilter('job-titles/filter', search, atom, 'jobTitle'),
    isDisabled: (atom: JobTrainingMatrixFilters) => atom.location.length === 0,
  },
  {
    key: 'training',
    name: 'Treinamento',
    options: [],
    isMulti: true,
    asyncFn: (search, atom?: JobTrainingMatrixFilters) =>
      fetchFilter('trainings/filter', search, atom),
    isDisabled: (atom: JobTrainingMatrixFilters) => atom.location.length === 0,
  },
];
