import { useState } from 'react';

import { cn } from '@/utils/cn';

import Modal from './Modal';

interface Props {
  text: string;
  title: string;
  ifEmptyString?: string;
  className?: string;
  reduceStringCount?: number;
}

export const TableCellWithShowMore = ({
  text,
  title,
  className,
  ifEmptyString,
  reduceStringCount = 250,
}: Props) => {
  const [showMore, setShowMore] = useState(false);
  let textToRender = text;

  const handleTransformText = (text: string, maxLength: number) => {
    return text.length > maxLength
      ? text.substring(0, maxLength) + '...'
      : text;
  };

  if (reduceStringCount) {
    textToRender = handleTransformText(text, reduceStringCount);
  }
  return (
    <>
      <Modal
        isOpen={showMore}
        onClose={() => setShowMore(!showMore)}
        title={title}
        className="max-w-[50vw]"
      >
        <p className="whitespace-pre-wrap px-4 pb-6 text-[#4A4A4A]">
          {text} {text}
        </p>
      </Modal>

      <div className={cn('flex items-center', className)}>
        <p className="text-[11px] text-[#4A4A4A]">
          {textToRender ?? ifEmptyString}
          {text.length !== textToRender.length && (
            <button onClick={() => setShowMore(!showMore)}>
              <p className="ml-1 font-medium text-primary">Ver mais</p>
            </button>
          )}
        </p>
      </div>
    </>
  );
};
