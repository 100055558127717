/* eslint-disable no-unused-vars */
import Menu from '@mui/icons-material/Menu';
import { forwardRef, useImperativeHandle, useState } from 'react';
import { toast } from 'react-toastify';

import { TrainingType } from '@/constants/TrainingPlanning';
import api from '@/services/apiSgft';
import { ShortTraining } from '@/types/Training';

import LightTooltip from '../atoms/LightTooltip';
import SimpleMenu from '../atoms/Menu';

interface TrainingHeaderProps {
  training: ShortTraining;
  canSeeTraining: boolean | undefined;
  canSave: boolean | undefined;
  isEditMode: boolean;
  handleEditTraining: (trainingId: number) => void;
  handleDesactivateTraining: (trainingId: number) => void;
  handleDeleteTraining: (trainingId: number) => void;
  handleTransformTrainingName: (title: string, maxLength: number) => string;
  handleMapAllJobTitles: (trainingId: number) => Promise<void>;
  handleSkipMapping: (trainingId: number) => Promise<void>;
}

const TrainingHeader = forwardRef(
  (
    {
      training,
      canSeeTraining,
      canSave,
      isEditMode,
      handleEditTraining,
      handleDesactivateTraining,
      handleDeleteTraining,
      handleTransformTrainingName,
      handleMapAllJobTitles,
      handleSkipMapping,
    }: TrainingHeaderProps,
    ref,
  ) => {
    const [sentForMapping, setSentForMapping] = useState(
      training.sentForMapping,
    );
    const handleSendMapping = async () => {
      try {
        await api.post(`/trainings/mapping/${training.id}`);
        setSentForMapping(true);
        toast.success('Mapeamento enviado com sucesso', {
          toastId: 'success',
          theme: 'colored',
        });
      } catch (e: any) {
        toast.error(
          e?.response?.data?.message ?? 'Erro ao enviar para mapeamento',
          {
            toastId: 'error',
            theme: 'colored',
          },
        );
      }
    };
    let options = [
      {
        name: 'Cadastro do Treinamento',
        onClick: () => handleEditTraining(training.id),
        disabled: false,
      },
      {
        name: 'Desativar Treinamento',
        onClick: () => handleDesactivateTraining(training.id),
        disabled: !canSave,
      },
      {
        name: 'Mapear Todas as Funções',
        onClick: () => handleMapAllJobTitles(training.id),
        disabled: !isEditMode,
        tooltip:
          'É necessário que todas as funções estejam carregadas para realizar o mapeamento',
      },
    ];
    if (training.trainingType === TrainingType.Procedimento) {
      options = [
        ...options,
        {
          name: 'Sem Alteração de Mapeamento',
          onClick: () => handleSkipMapping(training.id),
          disabled: !training.isResponsible,
        },
        {
          name: 'Enviar Mapeamento',
          onClick: handleSendMapping,
          disabled: !canSave || !!sentForMapping || !training.isReadyToMap,
        },
      ];
    }
    useImperativeHandle(ref, () => ({
      triggerEdit: () => handleEditTraining(training.id),
      triggerDesactivate: () => handleDesactivateTraining(training.id),
      triggerDelete: () => handleDeleteTraining(training.id),
      triggerMapAllJobTitles: () => handleMapAllJobTitles(training.id),
      triggerSkipMapping: () => handleSkipMapping(training.id),
    }));

    return (
      <div className="relative flex h-12 items-center justify-between overflow-visible align-middle">
        <LightTooltip title={training.name} arrow placement="top-start">
          <div className="ml-2 line-clamp-2 block max-h-20 w-full overflow-hidden break-words text-center">
            {handleTransformTrainingName(training.name, 35)}
          </div>
        </LightTooltip>
        {canSeeTraining && (
          <SimpleMenu
            options={options}
            iconComponent={<Menu fontSize="inherit" />}
            menuItemsClassName="right-0 left-auto"
          />
        )}
      </div>
    );
  },
);

TrainingHeader.displayName = 'TrainingHeader';

export default TrainingHeader;
