import { UserTypesEnum } from '@/enums/UserTypesEnum';
import { msalInstance } from '@/main';

export const userCanSave = (
  selectedUserManagement: number | null,
  actualManagement: number,
) => {
  const roles = getActualRole();

  if (roles?.includes(UserTypesEnum.Admin)) return true;
  if (roles?.includes(UserTypesEnum.Rh)) return true;
  if (roles?.includes(UserTypesEnum.Manager)) return true;
  return actualManagement === selectedUserManagement;
};

export const userIsRhOrAdmin = () => {
  const roles = getActualRole();

  if (roles?.includes(UserTypesEnum.Admin)) {
    return true;
  }

  return roles?.includes(UserTypesEnum.Rh);
};

export const userCanSaveTraining = () => {
  const roles = getActualRole();

  if (roles?.includes(UserTypesEnum.Admin)) {
    return true;
  }
  return roles?.includes(UserTypesEnum.QsmsTreinamento);
};

export const userCanSeeTraining = () => {
  const roles = getActualRole();

  if (roles?.includes(UserTypesEnum.Admin)) {
    return true;
  }
  return (
    roles?.includes(UserTypesEnum.QsmsTreinamento) ||
    roles?.includes(UserTypesEnum.RhTreinamento)
  );
};

export const userCanSeeTrainingControl = () => {
  const roles = getActualRole();

  if (
    roles?.includes(UserTypesEnum.Admin) ||
    roles?.includes(UserTypesEnum.QsmsTreinamento) ||
    roles?.includes(UserTypesEnum.RhTreinamento)
  ) {
    return true;
  }
  if (roles?.includes(UserTypesEnum.ReadOnly)) {
    return false;
  }
  return true;
};

export const userCanSeeTrainingControlButtons = () => {
  const roles = getActualRole();

  if (
    roles?.includes(UserTypesEnum.Admin) ||
    roles?.includes(UserTypesEnum.QsmsTreinamento) ||
    roles?.includes(UserTypesEnum.RhTreinamento)
  ) {
    return true;
  }
  if (roles?.includes(UserTypesEnum.ReadOnly)) {
    return false;
  }
  return false;
};
export const userCanSeeTeamStructureForms = () => {
  const roles = getActualRole();
  if (roles?.includes(UserTypesEnum.ReadOnly)) {
    return false;
  }
  if (roles?.includes(UserTypesEnum.Admin)) {
    return true;
  }
  return roles?.includes(UserTypesEnum.Rh);
};

export const getActualRole = (): UserTypesEnum[] => {
  return msalInstance
    ? (msalInstance?.getActiveAccount()?.idTokenClaims
        ?.roles as UserTypesEnum[]) ?? []
    : [];
};

export const isInRole = (role: UserTypesEnum) => {
  return getActualRole()?.includes(role);
};

export const isReadOnlyUser = () => {
  const roles = getActualRole();
  return roles?.includes(UserTypesEnum.ReadOnly);
};

export const shouldDisableScheduleMenu = () => {
  const roles = getActualRole();

  if (roles?.includes(UserTypesEnum.OnDutyWorker)) {
    return false;
  }

  return roles?.includes(UserTypesEnum.ReadOnly);
};

export const isOnDutyWorker = () => {
  const roles = getActualRole();
  return roles?.includes(UserTypesEnum.OnDutyWorker);
};

export const userCanSaveAllocation = () => {
  const roles = getActualRole();

  if (roles?.includes(UserTypesEnum.Admin)) {
    return true;
  }

  return roles?.includes(UserTypesEnum.Rh);
};

export const isAdmin = () => {
  return getActualRole()?.includes(UserTypesEnum.Admin);
};

export const userCanEditRequirementControl = () => {
  const roles = getActualRole();

  if (roles?.includes(UserTypesEnum.Admin)) {
    return true;
  }

  return roles?.includes(UserTypesEnum.Rh);
};

export const isRegulatorio = () => {
  return getActualRole()?.includes(UserTypesEnum.Regulatorio);
};

export const userCanSeeRequirementControl = () => {
  const roles = getActualRole();

  if (roles?.includes(UserTypesEnum.Admin)) {
    return true;
  }

  return roles?.includes(UserTypesEnum.Rh);
};

export const isManagerOrDirector = () => {
  const roles = getActualRole();

  if (
    roles?.includes(UserTypesEnum.Manager) ||
    roles?.includes(UserTypesEnum.Director)
  ) {
    return true;
  }

  return false;
};
