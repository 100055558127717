import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import Select, { Theme } from 'react-select';
import { toast } from 'react-toastify';

import { SelectOption } from '@/constants/TrainingConstants';
import { TrainingType } from '@/constants/TrainingPlanning';
import api from '@/services/apiSgft';
import { Training } from '@/types/Training';

import ControlledSimpleSelect from '../atoms/ControlledSimpleSelect';
import Spinner from '../atoms/Spinner';
import { styles } from '../ui/selectStyles';
import ValueContainer from '../ui/valueContainer';

interface RegulatoryTrainingCellProps {
  // TODO type this
  methods: UseFormReturn<any, any, any>;
  updateDueInputs: () => void;
  idx: number;
  remove: (index: number) => void;
  handleUpdateName: () => void;
}

// TODO is not just regularory, is procedure too
export const RegulatoryTrainingCell = ({
  methods,
  idx,
  updateDueInputs,
  handleUpdateName,
}: RegulatoryTrainingCellProps) => {
  const trainingTypeWatch = methods.watch(`trainings.${idx}.trainingType`);
  const trainingIdWatch = methods.watch(`trainings.${idx}.id`);
  const [locations, setLocations] = useState<
    { value: string; label: string }[]
  >([]);
  const [modalities, setModalities] = useState<
    { value: string; label: string }[]
  >([]);
  const fetchTrainings = async (): Promise<Training[]> => {
    try {
      const response = await api.get('/trainings', {
        params: {
          getAll: true,
          trainingType: [trainingTypeWatch],
        },
      });
      return response.data;
    } catch (e) {
      toast.error('Erro ao carregar treinamentos', {
        theme: 'colored',
        toastId: 'error',
      });
      throw e;
    }
  };

  const theme: (theme: Theme) => Theme = (theme) => ({
    ...theme,
    borderRadius: 4,
    spacing: {
      ...theme.spacing,
      controlHeight: 20,
    },
    colors: {
      ...theme.colors,
      neutral60: '#E5E5E5',
    },
  });

  const { data, isLoading } = useQuery(
    ['suppliers-trainings', trainingTypeWatch],
    fetchTrainings,
    {
      retry: false,
    },
  );

  const loadLocationAndModalities = async (inputId?: number) => {
    let id = inputId;
    if (data) {
      if (methods.getValues(`trainings.${idx}.id`) && !inputId) {
        id = methods.getValues(`trainings.${idx}.id`);
      }
      if (id) {
        const training = data.filter((el) => el.id === id)[0];
        if (training) {
          await defineLocationsToSelect(training);
        }
        setModalities(
          training?.modalities.map((el: string) => {
            return { value: el, label: el };
          }),
        );
      }
    }
  };
  // TODO This is not ideal. Is only existing because the 'Corporativo' is a location considered in the matrix as a property of training. To solve this structuraly, we need to change the way that the matrix work and review all that it touches
  const defineLocationsToSelect = async (training: Training) => {
    try {
      const response = await api.get('/trainings/locations', {
        params: { trainingType: [training.trainingType] },
      });
      let availableLocations: { name: string }[] = response.data || [];
      availableLocations = availableLocations.filter(
        (loc: { name: string }) => loc.name !== 'Corporativo',
      );
      if (training?.locations.includes('Corporativo')) {
        setLocations(
          availableLocations.map((el: { name: string }) => ({
            value: el.name,
            label: el.name,
          })),
        );
      } else {
        setLocations(
          training?.locations.map((el: string) => ({ value: el, label: el })),
        );
      }
    } catch (error) {
      toast.error('Erro ao carregar localizações', {
        theme: 'colored',
        toastId: 'error',
      });
    }
  };

  useEffect(() => {
    loadLocationAndModalities();
  }, [data]);

  const handleUpdateTraining = (e?: SelectOption) => {
    const id = e ? Number(e.value) : undefined;
    methods.setValue(`trainings.${idx}.id`, id);
    loadLocationAndModalities(Number(e?.value));
    const name = data?.find((el) => Number(el.id) === Number(id))?.name;
    methods.setValue(`trainings.${idx}.name`, name);
    handleUpdateName();
  };

  const trainingOptions = data
    ?.filter((el) => el.active || el.id === trainingIdWatch)
    .map((el) => {
      let label = el.name;
      if (el.trainingType === TrainingType.Procedimento) {
        label = label.split(' (REVISÃO')[0];
      }
      return { value: el.id, label };
    });

  return (
    <>
      {!data || isLoading ? (
        <div className="my-4 flex h-full w-full items-center rounded-md">
          <Spinner size={25} />
        </div>
      ) : (
        <>
          <div className="flex w-full flex-col items-start">
            <div className={'text-[12px] text-[#4A4A4A]'}>
              Treinamento
              <span className="text-red">*</span>
            </div>
            <Select
              isSearchable
              options={trainingOptions}
              onChange={(e: any) => handleUpdateTraining(e)}
              placeholder=""
              required
              key={`trainings.${idx}.id`}
              name={`trainings.${idx}.id`}
              className={`h-[37px] w-full bg-transparent font-graphie text-sm font-medium text-primary *:rounded-lg`}
              theme={theme}
              styles={styles}
              components={{ ValueContainer }}
              noOptionsMessage={() => 'Nenhuma opção encontrada'}
              menuPosition="fixed"
              defaultValue={
                trainingOptions
                  ? trainingOptions.find((el) => el.value === trainingIdWatch)
                  : []
              }
            />
          </div>
          <ControlledSimpleSelect
            title={'Polo de Aplicação'}
            required
            control={methods.control}
            name={`trainings.${idx}.locations`}
            multiple
            width="100%"
            values={locations}
            height="38px"
            disabled={!methods.getValues(`trainings.${idx}.id`)}
          />
          <ControlledSimpleSelect
            title={'Modalidade'}
            required
            control={methods.control}
            name={`trainings.${idx}.modalities`}
            multiple
            width="100%"
            height="38px"
            values={modalities}
            onChange={updateDueInputs}
            disabled={!methods.getValues(`trainings.${idx}.id`)}
          />
        </>
      )}
    </>
  );
};
