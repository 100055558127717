import { Checkbox } from '@mui/material';
import { ColumnDef } from '@tanstack/react-table';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { twMerge } from 'tailwind-merge';

import AvailabilityTooltipIcon from '@/components/atoms/AvailabilityTooltipIcon';
import { ControlledJustificationDialog } from '@/components/atoms/ControlledJustificationDialog';
import HtmlTooltip from '@/components/atoms/HtmlTooltip';
import SimpleMenu from '@/components/atoms/Menu';
import TableCellDate from '@/components/atoms/TableCellDate';
import TableCellStatus from '@/components/atoms/TableCellStatus';
import { AvailabilityTableCell } from '@/components/molecules/AvailabilityTableCell';
import ConfirmationModal from '@/components/molecules/ConfirmationModal';
import { StatusTableCell } from '@/components/molecules/StatusTableCell';
import Table from '@/components/organisms/Table';
import { AvailabilityTrainingData } from '@/types/AvailabilityTrainingData';
import { StatusTrainingData } from '@/types/StatusTrainingData';
import { ExpirationStatus } from '@/types/TrainingControlTypes';
import { ExtraordinaryAllocationModal } from '@/types/TrainingPlanning';
import { TrainingPlanningForm } from '@/types/TrainingPlanningForm';
import { removeTimeZone } from '@/utils/formatDate';
import { getFirstAndLastName } from '@/utils/getFirstAndLastName';

import TableData from '../../components/atoms/TableData';
import { Availability } from '../TrainingPlanning';

export const employeeColumns: (
  asyncTraining: boolean,
  trainingPlanning: TrainingPlanningForm,
  setTrainingPlanning: (a: TrainingPlanningForm) => void,
) => Array<ColumnDef<any>> = (
  asyncTraining: boolean,
  trainingPlanning: TrainingPlanningForm,
  setTrainingPlanning: (a: TrainingPlanningForm) => void,
) => [
  {
    id: 'select',
    meta: {
      headerClassName: 'sticky left-[-0.1rem] z-10 bg-white',
      stickyClassName: 'sticky left-[-0.1rem] z-10 bg-inherit',
    },
    header: () => {
      return (
        <div className="flex w-36 items-center justify-center">
          <Checkbox
            disabled={true}
            checked={
              !!trainingPlanning.selectedEmployee.find(
                (el) => el.employee === 'all',
              )
            }
            onChange={() => {
              if (
                trainingPlanning.selectedEmployee.find(
                  (el) => el.employee === 'all',
                )
              ) {
                setTrainingPlanning({
                  selectedEmployee: [
                    ...trainingPlanning.selectedEmployee.filter(
                      (el) => el.isExtraordinaryAllocation,
                    ),
                  ],
                  isAllEmployeeSelected: false,
                  trainingDate: trainingPlanning.trainingDate,
                  extraordinaryAllocationId: '',
                });
              } else {
                setTrainingPlanning({
                  selectedEmployee: [
                    ...trainingPlanning.selectedEmployee.filter(
                      (el) => el.isExtraordinaryAllocation,
                    ),
                    {
                      employee: 'all',
                      extraordinaryAllocationMotive: '',
                      isExtraordinaryAllocation: false,
                      isThirdParty: false,
                    },
                  ],
                  isAllEmployeeSelected: true,
                  trainingDate: trainingPlanning.trainingDate,
                  extraordinaryAllocationId: '',
                });
              }
            }}
          />
        </div>
      );
    },
    cell: (props) => {
      const handleSubmit = async (employee: any, isChecked: boolean) => {
        try {
          const selectedEmployee = trainingPlanning.selectedEmployee.find(
            (emp) => emp.employee.employeeNumber === employee.employeeNumber,
          );

          if (
            trainingPlanning.selectedEmployee.find(
              (emp) => emp.employee === 'all',
            ) &&
            employee.employeeNumber
          ) {
            const updatedEmployees = trainingPlanning.selectedEmployee.filter(
              (emp) =>
                emp.employee !== 'all' &&
                emp.employee.employeeNumber !== employee.employeeNumber,
            );

            setTrainingPlanning({
              selectedEmployee: updatedEmployees,
              isAllEmployeeSelected: false,
              trainingDate: trainingPlanning.trainingDate,
              extraordinaryAllocationId: '',
            });

            return;
          }

          if (selectedEmployee && !isChecked) {
            const updatedEmployees = trainingPlanning.selectedEmployee.filter(
              (emp) => emp.employee.employeeNumber !== employee.employeeNumber,
            );
            setTrainingPlanning({
              selectedEmployee: updatedEmployees,
              isAllEmployeeSelected: false,
              trainingDate: trainingPlanning.trainingDate,
              extraordinaryAllocationId: '',
            });
          } else {
            setTrainingPlanning({
              selectedEmployee: [
                ...trainingPlanning.selectedEmployee,
                {
                  employee,
                  extraordinaryAllocationMotive: '',
                  isExtraordinaryAllocation: false,
                  isThirdParty: false,
                },
              ],
              isAllEmployeeSelected: false,
              trainingDate: trainingPlanning.trainingDate,
              extraordinaryAllocationId: '',
            });
          }
        } catch (e) {
          toast.error('Erro ao selecionar colaborador', {
            theme: 'colored',
            toastId: 'error',
          });
        }
      };
      const available = props.row.original.availabilityByDay.every(
        (it: { availability: { availability: Availability } }) =>
          it.availability.availability === Availability.Disponivel,
      );
      return (
        <div className={`flex w-full items-center justify-center`}>
          <Checkbox
            checked={
              !!trainingPlanning.selectedEmployee.find(
                (emp) =>
                  emp.employee.employeeNumber ===
                  (props.row.original.employeeNumber as string),
              ) ||
              (!!trainingPlanning.selectedEmployee.find(
                (emp) => emp.employee === 'all',
              ) &&
                props.row.original?.availability?.availability ===
                  Availability.Disponivel)
            }
            disabled={!available}
            onChange={(e) => handleSubmit(props.row.original, e.target.checked)}
          />
        </div>
      );
    },
  },
  {
    accessorKey: 'name',
    header: 'Colaborador',
    enableSorting: true,
    meta: {
      headerClassName: 'sticky left-[8.9rem] z-10 bg-white',
      stickyClassName: 'sticky left-[8.9rem] z-10 bg-inherit',
    },
    cell: (props) => {
      return (
        <span
          className={`flex items-center justify-center pb-2 pl-2 pr-4 pt-2`}
        >
          <TableData
            title={props.row.original.alias ?? (props.getValue() as string)}
            tableValue={
              props.row.original.alias ??
              getFirstAndLastName(props.getValue() as string)
            }
            className={twMerge(
              'w-44 items-center rounded-sm px-3 text-center ',
            )}
          />
        </span>
      );
    },
  },
  {
    accessorKey: 'employeeNumber',
    header: 'Chapa',
    enableSorting: true,
    meta: {
      headerClassName: 'sticky left-[20.9rem] z-10 bg-white',
      stickyClassName: 'sticky left-[20.9rem] z-10 bg-inherit',
    },
    cell: (props) => {
      return (
        <span
          className={`flex items-center justify-center pb-2 pl-2 pr-4 pt-2`}
        >
          {' '}
          <TableData
            tableValue={props.getValue()}
            className={twMerge('items-center justify-center px-3 text-left ')}
          />
        </span>
      );
    },
  },
  {
    accessorKey: 'availabilityByDay',
    header: 'Disponibilidade',
    enableSorting: true,
    cell: (props) => {
      const availabilityData = props.getValue() as AvailabilityTrainingData;

      return (
        <span className="flex items-center justify-center pl-2 pr-4">
          <AvailabilityTableCell availabilityData={availabilityData} />
        </span>
      );
    },
  },
  {
    accessorKey: 'trainings',
    header: 'Treinamentos por dias',
    enableSorting: true,
    cell: (props) => {
      const trainings = props.getValue() as any[];
      const title = `${trainings?.length} ${trainings?.length === 1 ? 'Treinamento' : 'Treinamentos'}`;
      return (
        <span className="flex w-60 items-center justify-center">
          <HtmlTooltip
            title={
              trainings?.length > 0 ? (
                <div
                  style={{
                    whiteSpace: 'pre-line',
                    minHeight: '60px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    justifyContent: 'space-between',
                    padding: '8px',
                    gap: '8px',
                    width: 750,
                    background: 'white',
                  }}
                >
                  <span className="text-lg font-semibold text-[#193CB9]">
                    Treinamentos por dia
                  </span>
                  <Table
                    columns={trainingColumns}
                    data={trainings}
                    containerClassname="text-center w-full"
                    trBodyClassname="even:bg-white"
                  />
                </div>
              ) : null
            }
            arrow
            placement="top-start"
          >
            <div className="flex w-full items-center justify-center">
              <p
                className={twMerge(
                  'p-2 text-xs text-gray-600',
                  'flex h-6 items-center justify-center px-3 text-center text-blue-800',
                )}
              >
                {title as string}
              </p>
            </div>
          </HtmlTooltip>
        </span>
      );
    },
  },
  {
    accessorKey: 'expirationStatus',
    header: 'Situação de Vencimento',
    enableSorting: true,
    cell: (props) => {
      const value = props.getValue() as ExpirationStatus | null;
      return (
        <div className="mr-5 flex w-40 justify-center">
          <span className="flex items-center justify-center pl-8 pr-4">
            {value === null || value === undefined ? (
              '-'
            ) : (
              <TableCellStatus status={value} />
            )}
          </span>
        </div>
      );
    },
  },
  {
    accessorKey: 'management',
    header: 'Gerência',
    enableSorting: true,
    cell: (props) => {
      const tableValue = props.getValue() as string;

      return (
        <span className="flex items-center justify-center pl-2 pr-4">
          <TableData
            tableValue={tableValue}
            title={props.getValue() as string}
            className={twMerge(
              'w-44 items-center rounded-sm px-3 text-center ',
            )}
          />
        </span>
      );
    },
  },
  {
    accessorKey: 'coordination',
    header: 'Coordenação',
    enableSorting: true,
    cell: (props) => {
      const tableValue = props.getValue() as string;

      return (
        <span className="flex items-center justify-center pl-2 pr-4">
          <TableData
            tableValue={tableValue}
            title={props.getValue() as string}
            className={twMerge(
              'w-44  items-center rounded-sm px-3 text-center ',
            )}
          />
        </span>
      );
    },
  },
  {
    accessorKey: 'role',
    header: 'Função',
    enableSorting: true,
    cell: (props) => {
      const tableValue = props.getValue() as string;

      return (
        <span className="flex items-center justify-center pl-2 pr-4">
          <TableData
            tableValue={tableValue}
            title={props.getValue() as string}
            className={twMerge(
              'w-44 items-center overflow-hidden rounded-sm px-3 text-center ',
            )}
          />
        </span>
      );
    },
  },
  {
    accessorKey: 'regime',
    header: 'Regime',
    enableSorting: true,
    cell: (props) => (
      <span className="flex items-center justify-center pl-2 pr-4">
        <TableData
          tableValue={props.getValue()}
          title={props.getValue() as string}
          className={twMerge('w-44  items-center rounded-sm px-3 text-center ')}
        />
      </span>
    ),
  },
  {
    accessorKey: 'rmStatus',
    header: 'Situação RM',
    enableSorting: true,
    cell: (props) => (
      <span className="flex items-center justify-center pl-2 pr-4">
        <TableData
          title={props.getValue() as string}
          tableValue={props.getValue()}
          className={twMerge('w-28 items-center rounded-sm px-3 text-center ')}
        />
      </span>
    ),
  },
  {
    accessorKey: 'statusByDay',
    header: 'Situação do colaborador',
    enableSorting: true,
    cell: (props) => (
      <span className="flex items-center justify-center pl-2 pr-4">
        <StatusTableCell statusData={props.getValue() as StatusTrainingData} />
      </span>
    ),
  },
  {
    accessorKey: 'dayOffByDay',
    header: 'Hora extra',
    enableSorting: true,
    cell: (props) => (
      <span className="flex items-center justify-center pl-2 pr-4">
        <StatusTableCell statusData={props.getValue() as StatusTrainingData} />
      </span>
    ),
  },
  {
    accessorKey: 'realization',
    header: 'Realização',
    enableSorting: true,
    cell: (props) => (
      <span className="flex items-center justify-center pl-2 pr-4">
        <TableData
          tableValue={props.getValue()}
          className={twMerge('w-28 items-center rounded-sm px-3 text-center ')}
        />
      </span>
    ),
  },
  {
    accessorKey: 'state',
    header: 'Estado de moradia',
    enableSorting: true,
    cell: (props) => (
      <span className="flex items-center  justify-center pl-2 pr-4">
        <TableData
          tableValue={props.getValue()}
          className={twMerge(
            'w-28 items-center justify-center rounded-sm px-3  text-center ',
          )}
        />
      </span>
    ),
  },
  {
    accessorKey: 'workStation',
    header: 'Local de trabalho',
    enableSorting: true,
    cell: (props) => (
      <span className="flex items-center justify-center pl-2 pr-4">
        <TableData
          tableValue={props.getValue()}
          className={twMerge(
            'flex h-6 w-28 items-center justify-center overflow-visible rounded-sm px-3 text-center',
          )}
        />
      </span>
    ),
  },
];

export const previewEmployeeColumns: (
  trainingPlanning: TrainingPlanningForm,
  setTrainingPlanning: (a: TrainingPlanningForm) => void,
) => Array<ColumnDef<any>> = (
  trainingPlanning: TrainingPlanningForm,
  setTrainingPlanning: (a: TrainingPlanningForm) => void,
) => {
  const handleSelect = async (employee: any, isChecked: boolean) => {
    try {
      const selectedEmployee = trainingPlanning.selectedEmployee.find(
        (emp) => emp.employee.employeeNumber === employee.employeeNumber,
      );

      if (
        trainingPlanning.selectedEmployee.find(
          (emp) => emp.employee === 'all',
        ) &&
        employee.employeeNumber
      ) {
        const updatedEmployees = trainingPlanning.selectedEmployee.filter(
          (emp) =>
            emp.employee !== 'all' &&
            emp.employee.employeeNumber !== employee.employeeNumber,
        );

        setTrainingPlanning({
          selectedEmployee: updatedEmployees,
          isAllEmployeeSelected: false,
          trainingDate: trainingPlanning.trainingDate,
          extraordinaryAllocationId: '',
        });

        return;
      }

      if (selectedEmployee && !isChecked) {
        const updatedEmployees = trainingPlanning.selectedEmployee.filter(
          (emp) => emp.employee.employeeNumber !== employee.employeeNumber,
        );
        setTrainingPlanning({
          selectedEmployee: updatedEmployees,
          isAllEmployeeSelected: false,
          trainingDate: trainingPlanning.trainingDate,
          extraordinaryAllocationId: '',
        });
      } else {
        setTrainingPlanning({
          selectedEmployee: [
            ...trainingPlanning.selectedEmployee,
            {
              employee,
              extraordinaryAllocationMotive: '',
              isExtraordinaryAllocation: false,
              isThirdParty: false,
            },
          ],
          isAllEmployeeSelected: false,
          trainingDate: trainingPlanning.trainingDate,
          extraordinaryAllocationId: '',
        });
      }
    } catch (e) {
      toast.error('Erro ao selecionar colaborador', {
        theme: 'colored',
        toastId: 'error',
      });
    }
  };

  return [
    {
      id: 'select',
      header: () => {
        return <div className="flex w-36 items-center justify-center"></div>;
      },
      meta: {
        headerClassName: 'sticky left-[-0.1rem] z-10 bg-white',
        stickyClassName: 'sticky left-[-0.1rem] z-10 bg-inherit',
      },
      cell: (props) => {
        const employee = trainingPlanning.selectedEmployee.find(
          (el) =>
            el.employee.employeeNumber === props.row.original.employeeNumber,
        );
        const available = props.row.original.availabilityByDay.every(
          (it: { availability: { availability: Availability } }) =>
            it.availability.availability === Availability.Disponivel,
        );

        return (
          <div className={`flex w-full items-center justify-center`}>
            <Checkbox
              checked={
                !!trainingPlanning.selectedEmployee.find(
                  (emp) =>
                    emp.employee.employeeNumber ===
                    (props.row.original.employeeNumber as string),
                ) ||
                (!!trainingPlanning.selectedEmployee.find(
                  (emp) => emp.employee === 'all',
                ) &&
                  props.row.original?.availability?.availability ===
                    Availability.Disponivel)
              }
              disabled={!available && !employee?.isExtraordinaryAllocation}
              onChange={(e) =>
                handleSelect(props.row.original, e.target.checked)
              }
            />
          </div>
        );
      },
    },
    {
      id: 'actions',
      header: '',
      meta: {
        headerClassName: 'sticky left-[-0.1rem] bg-white',
        stickyClassName: 'sticky left-[-0.1rem] bg-inherit',
      },
      cell: (props) => {
        const employee = trainingPlanning.selectedEmployee.find(
          (el) =>
            el.employee.employeeNumber === props.row.original.employeeNumber,
        );
        const [isModalOpen, setIsModalOpen] = useState(false);
        const [isExtraordinaryModalOpen, setIsExtraordinaryModalOpen] =
          useState(false);
        const [selectedEmployee, setSelectedEmployee] = useState<any>(null);

        const openModal = (employee: any) => {
          setSelectedEmployee(employee);
          setIsModalOpen(true);
        };

        const closeModal = () => {
          setIsModalOpen(false);
          setSelectedEmployee(null);
        };

        const confirmRemoval = () => {
          if (selectedEmployee) {
            handleSelect(selectedEmployee, false);
          }
          closeModal();
        };

        const handleExtraordinaryAllocation = async () => {
          const justification = methods.getValues('justification');
          const updatedEmployee = {
            ...selectedEmployee,
            extraordinaryAllocationMotive: justification,
            isExtraordinaryAllocation: true,
          };

          const updatedSelectedEmployee = [
            ...trainingPlanning.selectedEmployee.filter(
              (emp) =>
                emp.employee.employeeNumber !== selectedEmployee.employeeNumber,
            ),
            {
              employee: updatedEmployee,
              extraordinaryAllocationMotive: justification,
              isExtraordinaryAllocation: true,
              isThirdParty: updatedEmployee.isThirdParty,
            },
          ];

          setTrainingPlanning({
            selectedEmployee: updatedSelectedEmployee,
            isAllEmployeeSelected: trainingPlanning.isAllEmployeeSelected,
            trainingDate: trainingPlanning.trainingDate,
            extraordinaryAllocationId:
              trainingPlanning.extraordinaryAllocationId,
          });
        };

        const openExtraordinaryModal = (employee: any) => {
          setSelectedEmployee(employee);
          setIsExtraordinaryModalOpen(true);
        };

        const closeExtraordinaryModal = () => {
          setIsExtraordinaryModalOpen(false);
          setSelectedEmployee(null);
        };

        const confirmExtraordinaryAllocation = () => {
          if (selectedEmployee) {
            handleExtraordinaryAllocation();
          }
          closeExtraordinaryModal();
        };
        const methods = useForm<ExtraordinaryAllocationModal>();

        const options = [
          {
            name: 'Remover',
            onClick: () => openModal(props.row.original),
            disabled: false,
          },
          {
            name: 'Alocação extraordinária',
            onClick: () => openExtraordinaryModal(props.row.original),
            disabled: false,
          },
        ];
        const available = props.row.original.availabilityByDay.every(
          (it: { availability: { availability: Availability } }) =>
            it.availability.availability === Availability.Disponivel,
        );
        const justification = methods.watch('justification');

        return (
          <div>
            {!available && !employee?.isExtraordinaryAllocation && (
              <SimpleMenu options={options} upDotsButton />
            )}
            <ConfirmationModal
              title="Confirmação de Remoção"
              description="Ao prosseguir com esta ação o colaborador será removido deste planejamento de treinamento"
              isOpen={isModalOpen}
              onClose={() => setIsModalOpen(false)}
              confirmAction={confirmRemoval}
            />
            <ControlledJustificationDialog
              title={'Confirmação de Alocação Extraordinária'}
              name={'justification'}
              methods={methods}
              onConfirmation={confirmExtraordinaryAllocation}
              disabled={!justification}
              isOpen={isExtraordinaryModalOpen}
              description="Para continuar com essa ação de alocação extraordinária, é necessário acrescentar uma justificativa."
              fieldTitle="Justificativa de alteração"
              closeDialog={() => setIsExtraordinaryModalOpen(false)}
            />
          </div>
        );
      },
    },
    {
      accessorKey: 'name',
      header: 'Colaborador',
      enableSorting: true,
      meta: {
        headerClassName: 'sticky left-[8.9rem] z-10 bg-white',
        stickyClassName: 'sticky left-[8.9rem] z-10 bg-inherit',
      },
      cell: (props) => {
        const isExtraordinary = trainingPlanning.selectedEmployee.find(
          (el) =>
            el.employee.employeeNumber === props.row.original.employeeNumber,
        )?.isExtraordinaryAllocation;
        const extraordinaryMotive = trainingPlanning.selectedEmployee.find(
          (el) =>
            el.employee.employeeNumber === props.row.original.employeeNumber,
        )?.extraordinaryAllocationMotive;
        return (
          <span
            className={`flex items-center justify-center pb-2 pl-2 pr-4 pt-2`}
          >
            <TableData
              title={props.row.original.alias ?? (props.getValue() as string)}
              tableValue={
                props.row.original.alias ??
                getFirstAndLastName(props.getValue() as string)
              }
              className={twMerge(
                'w-44 items-center rounded-sm px-3 text-left ',
              )}
            />
            {isExtraordinary && (
              <AvailabilityTooltipIcon motive={extraordinaryMotive || ''} />
            )}
          </span>
        );
      },
    },
    {
      accessorKey: 'employeeNumber',
      header: 'Chapa',
      enableSorting: true,
      meta: {
        headerClassName: 'sticky left-[20.9rem] z-10 bg-white',
        stickyClassName: 'sticky left-[20.9rem] z-10 bg-inherit',
      },
      cell: (props) => {
        return (
          <span
            className={`flex items-center justify-center pb-2 pl-2 pr-4 pt-2`}
          >
            <TableData
              tableValue={props.getValue()}
              className={twMerge('items-center justify-center px-3 text-left ')}
            />
          </span>
        );
      },
    },
    {
      accessorKey: 'availabilityByDay',
      header: 'Disponibilidade',
      enableSorting: true,
      cell: (props) => {
        const availabilityData = props.getValue() as AvailabilityTrainingData;

        return (
          <span className="flex items-center justify-center pl-2 pr-4">
            <AvailabilityTableCell availabilityData={availabilityData} />
          </span>
        );
      },
    },
    {
      accessorKey: 'trainings',
      header: 'Treinamentos por dia',
      enableSorting: true,
      cell: (props) => {
        const trainings = props.getValue() as any[];
        const title = `${trainings?.length} ${trainings?.length === 1 ? 'Treinamento' : 'Treinamentos'}`;
        return (
          <span className="flex w-60 items-center  justify-center">
            <HtmlTooltip
              title={
                trainings?.length > 0 ? (
                  <div
                    style={{
                      whiteSpace: 'pre-line',
                      minHeight: '60px',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                      justifyContent: 'space-between',
                      padding: '8px',
                      gap: '8px',
                      width: 800,
                    }}
                  >
                    <span className="text-lg font-semibold text-[#193CB9]">
                      Treinamentos do dia{' '}
                      {trainingPlanning.trainingDate?.toLocaleDateString()}
                    </span>
                    <Table
                      columns={trainingColumns}
                      data={trainings}
                      containerClassname="text-center w-full"
                      trBodyClassname="even:bg-white"
                    />
                  </div>
                ) : null
              }
              arrow
              placement="top-start"
            >
              <div className="flex w-full items-center justify-center">
                <p
                  className={twMerge(
                    'p-2 text-xs text-gray-600',
                    'flex h-6 items-center justify-center px-3 text-center text-blue-800',
                  )}
                >
                  {title as string}
                </p>
              </div>
            </HtmlTooltip>
          </span>
        );
      },
    },
    {
      accessorKey: 'expirationStatus',
      header: 'Situação de Vencimento',
      enableSorting: true,
      cell: (props) => {
        const value = props.getValue() as ExpirationStatus | null;
        return (
          <div className="mr-5 flex w-40 justify-center">
            <span className="flex items-center justify-center pl-8 pr-4">
              {value === null || value === undefined ? (
                '-'
              ) : (
                <TableCellStatus status={value} />
              )}
            </span>
          </div>
        );
      },
    },
    {
      accessorKey: 'management',
      header: 'Gerência',
      enableSorting: true,
      cell: (props) => {
        const tableValue = props.getValue() as string;

        return (
          <span className="flex items-center justify-center pl-2 pr-4">
            <TableData
              tableValue={tableValue}
              title={props.getValue() as string}
              className={twMerge(
                'w-44 items-center rounded-sm px-3 text-center ',
              )}
            />
          </span>
        );
      },
    },
    {
      accessorKey: 'coordination',
      header: 'Coordenação',
      enableSorting: true,
      cell: (props) => {
        const tableValue = props.getValue() as string;

        return (
          <span className="flex items-center justify-center pl-2 pr-4">
            <TableData
              tableValue={tableValue}
              title={props.getValue() as string}
              className={twMerge(
                'w-44  items-center rounded-sm px-3 text-center ',
              )}
            />
          </span>
        );
      },
    },
    {
      accessorKey: 'role',
      header: 'Função',
      enableSorting: true,
      cell: (props) => {
        const tableValue = props.getValue() as string;

        return (
          <span className="flex items-center justify-center pl-2 pr-4">
            <TableData
              tableValue={tableValue}
              title={props.getValue() as string}
              className={twMerge(
                'w-44 items-center overflow-hidden rounded-sm px-3 text-center ',
              )}
            />
          </span>
        );
      },
    },
    {
      accessorKey: 'regime',
      header: 'Regime',
      enableSorting: true,
      cell: (props) => (
        <span className="flex items-center justify-center pl-2 pr-4">
          <TableData
            tableValue={props.getValue()}
            title={props.getValue() as string}
            className={twMerge(
              'w-44  items-center rounded-sm px-3 text-center ',
            )}
          />
        </span>
      ),
    },
    {
      accessorKey: 'rmStatus',
      header: 'Situação RM',
      enableSorting: true,
      cell: (props) => (
        <span className="flex items-center justify-center pl-2 pr-4">
          <TableData
            title={props.getValue() as string}
            tableValue={props.getValue()}
            className={twMerge(
              'w-28 items-center rounded-sm px-3 text-center ',
            )}
          />
        </span>
      ),
    },
    {
      accessorKey: 'statusByDay',
      header: 'Situação do colaborador',
      enableSorting: true,
      cell: (props) => (
        <span className="flex items-center justify-center pl-2 pr-4">
          <StatusTableCell
            statusData={props.getValue() as StatusTrainingData}
          />
        </span>
      ),
    },
    {
      accessorKey: 'dayOffByDay',
      header: 'Hora extra',
      enableSorting: true,
      cell: (props) => (
        <span className="flex items-center justify-center pl-2 pr-4">
          <StatusTableCell
            statusData={props.getValue() as StatusTrainingData}
          />
        </span>
      ),
    },
    {
      accessorKey: 'realization',
      header: 'Realização',
      enableSorting: true,
      cell: (props) => (
        <span className="flex items-center justify-center pl-2 pr-4">
          <TableData
            tableValue={props.getValue()}
            className={twMerge(
              'w-28 items-center rounded-sm px-3 text-center ',
            )}
          />
        </span>
      ),
    },
    {
      accessorKey: 'state',
      header: 'Estado de moradia',
      enableSorting: true,
      cell: (props) => (
        <span className="flex items-center  justify-center pl-2 pr-4">
          <TableData
            tableValue={props.getValue()}
            className={twMerge(
              'w-28 items-center justify-center rounded-sm px-3  text-center ',
            )}
          />
        </span>
      ),
    },
    {
      accessorKey: 'workStation',
      header: 'Local de trabalho',
      enableSorting: true,
      cell: (props) => (
        <span className="flex items-center justify-center pl-2 pr-4">
          <TableData
            tableValue={props.getValue()}
            className={twMerge(
              'flex h-6 w-28 items-center justify-center rounded-sm px-3 text-center ',
            )}
          />
        </span>
      ),
    },
  ];
};

export const previewExternalEmployeeColumns: (
  trainingPlanning: TrainingPlanningForm,
  setTrainingPlanning: (a: TrainingPlanningForm) => void,
) => Array<ColumnDef<any>> = (
  trainingPlanning: TrainingPlanningForm,
  setTrainingPlanning: (a: TrainingPlanningForm) => void,
) => [
  {
    id: 'select',
    header: () => {
      return <div className="flex w-16 items-center justify-center"></div>;
    },
    meta: {
      headerClassName: 'sticky left-[-0.1rem] z-10 bg-white',
      stickyClassName: 'sticky left-[-0.1rem] z-10 bg-inherit',
    },
    cell: (props) => {
      const handleSubmit = async (employee: any, isChecked: boolean) => {
        try {
          const selectedEmployee = trainingPlanning.selectedEmployee.find(
            (emp) => emp.employee.employeeNumber === employee.employeeNumber,
          );

          if (
            trainingPlanning.selectedEmployee.find(
              (emp) => emp.employee === 'all',
            ) &&
            employee.employeeNumber
          ) {
            const updatedEmployees = trainingPlanning.selectedEmployee.filter(
              (emp) =>
                emp.employee !== 'all' &&
                emp.employee.employeeNumber !== employee.employeeNumber,
            );

            setTrainingPlanning({
              selectedEmployee: updatedEmployees,
              isAllEmployeeSelected: false,
              trainingDate: trainingPlanning.trainingDate,
              extraordinaryAllocationId: '',
            });

            return;
          }

          if (selectedEmployee && !isChecked) {
            const updatedEmployees = trainingPlanning.selectedEmployee.filter(
              (emp) => emp.employee.employeeNumber !== employee.employeeNumber,
            );
            setTrainingPlanning({
              selectedEmployee: updatedEmployees,
              isAllEmployeeSelected: false,
              trainingDate: trainingPlanning.trainingDate,
              extraordinaryAllocationId: '',
            });
          } else {
            setTrainingPlanning({
              selectedEmployee: [
                ...trainingPlanning.selectedEmployee,
                {
                  employee,
                  extraordinaryAllocationMotive: '',
                  isExtraordinaryAllocation: false,
                  isThirdParty: false,
                },
              ],
              isAllEmployeeSelected: false,
              trainingDate: trainingPlanning.trainingDate,
              extraordinaryAllocationId: '',
            });
          }
        } catch (e) {
          toast.error('Erro ao selecionar colaborador', {
            theme: 'colored',
            toastId: 'error',
          });
        }
      };
      return (
        <div className={`flex w-full items-center justify-center`}>
          <Checkbox
            checked={
              !!trainingPlanning.selectedEmployee.find(
                (emp) =>
                  emp.employee.employeeNumber ===
                  (props.row.original.employeeNumber as string),
              ) ||
              (!!trainingPlanning.selectedEmployee.find(
                (emp) => emp.employee === 'all',
              ) &&
                props.row.original?.availability?.availability ===
                  Availability.Disponivel)
            }
            onChange={(e) => handleSubmit(props.row.original, e.target.checked)}
          />
        </div>
      );
    },
  },
  {
    accessorKey: 'name',
    header: 'Colaborador',
    enableSorting: true,
    meta: {
      headerClassName: 'sticky left-[8.9rem] z-10 bg-white',
      stickyClassName: 'sticky left-[8.9rem] z-10 bg-inherit',
    },
    cell: (props) => {
      const isExtraordinary = trainingPlanning.selectedEmployee.find(
        (el) =>
          el.employee.employeeNumber === props.row.original.employeeNumber,
      )?.isExtraordinaryAllocation;
      const extraordinaryMotive = trainingPlanning.selectedEmployee.find(
        (el) =>
          el.employee.employeeNumber === props.row.original.employeeNumber,
      )?.extraordinaryAllocationMotive;
      return (
        <span
          className={`flex items-center justify-center pb-2 pl-2 pr-4 pt-2`}
        >
          <TableData
            title={props.row.original.alias ?? (props.getValue() as string)}
            tableValue={
              props.row.original.alias ??
              getFirstAndLastName(props.getValue() as string)
            }
            className={twMerge(
              'flex h-6 w-full items-center rounded-sm px-3 text-left uppercase ',
            )}
          />
          {isExtraordinary && (
            <AvailabilityTooltipIcon motive={extraordinaryMotive || ''} />
          )}
        </span>
      );
    },
  },
  {
    accessorKey: 'employeeNumber',
    header: 'Chapa',
    enableSorting: true,
    meta: {
      headerClassName: 'sticky left-[20.9rem] z-10 bg-white',
      stickyClassName: 'sticky left-[20.9rem] z-10 bg-inherit',
    },
    cell: (props) => {
      return (
        <span
          className={`flex items-center justify-center pb-2 pl-2 pr-4 pt-2`}
        >
          <TableData
            tableValue={props.getValue()}
            className={twMerge(
              'flex h-6 items-center justify-center px-3 text-left ',
            )}
            containerClassName={'justify-center'}
          />
        </span>
      );
    },
  },
  {
    accessorKey: 'cpf',
    header: 'CPF',
    meta: {
      headerClassName: 'w-36',
    },
    enableSorting: true,
    cell: (props) => {
      const formatCPF = (cpf: string) => {
        const numbers = cpf.replace(/\D/g, '');
        return numbers.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
      };

      const tableValue = props.getValue() as string;
      const formattedCPF = formatCPF(tableValue);

      return (
        <span className="flex items-center justify-center pl-2 pr-4">
          <TableData
            tableValue={formattedCPF}
            title={formattedCPF}
            className={twMerge(
              'flex h-8 w-44 items-center overflow-hidden rounded-sm px-3 text-center ',
            )}
            containerClassName={'justify-center'}
          />
        </span>
      );
    },
  },
  {
    accessorKey: 'company',
    header: 'Empresa',
    enableSorting: true,
    cell: (props) => {
      let tableValue = props.getValue() as string;
      tableValue =
        tableValue.length > 50
          ? `${tableValue.substring(0, 40)}...`
          : tableValue;

      return (
        <span className="flex items-center justify-center pl-2 pr-4">
          <TableData
            tableValue={tableValue}
            title={props.getValue() as string}
            className={twMerge(
              'flex h-8 w-44 items-center overflow-hidden rounded-sm px-3 text-center text-black',
            )}
            containerClassName={'justify-center'}
          />
        </span>
      );
    },
  },
  {
    accessorKey: 'company',
    header: 'Empresa',
    enableSorting: true,
    cell: (props) => {
      let tableValue = props.getValue() as string;
      tableValue =
        tableValue.length > 50
          ? `${tableValue.substring(0, 40)}...`
          : tableValue;

      return (
        <span className="flex items-center justify-center pl-2 pr-4">
          <TableData
            tableValue={tableValue}
            title={props.getValue() as string}
            className={twMerge(
              'flex h-8 w-44 items-center overflow-hidden rounded-sm px-3 text-center text-black',
            )}
            containerClassName={'justify-center'}
          />
        </span>
      );
    },
  },
  {
    accessorKey: 'role',
    header: 'Função',
    enableSorting: true,
    cell: (props) => {
      const tableValue = props.getValue() as string;

      return (
        <div className="flex items-center justify-center pl-2 pr-4">
          <TableData
            tableValue={tableValue}
            title={props.getValue() as string}
            className={twMerge(
              'overflow-hidden text-ellipsis whitespace-nowrap rounded-sm px-3 text-center uppercase ',
            )}
            containerClassName={'justify-center w-72'}
          />
        </div>
      );
    },
  },
  {
    accessorKey: 'trainings',
    header: 'Treinamentos por dia',
    enableSorting: true,
    cell: (props) => {
      const trainings = props.getValue() as any[];
      const title = `${trainings?.length} ${trainings?.length === 1 ? 'Treinamento' : 'Treinamentos'}`;
      return (
        <span className="flex w-full items-center  justify-center">
          <HtmlTooltip
            title={
              trainings?.length > 0 ? (
                <div
                  style={{
                    whiteSpace: 'pre-line',
                    minHeight: '60px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    justifyContent: 'space-between',
                    padding: '8px',
                    gap: '8px',
                    width: 800,
                  }}
                >
                  <span className="text-lg font-semibold text-[#193CB9]">
                    Treinamentos do dia{' '}
                    {trainingPlanning.trainingDate?.toLocaleDateString()}
                  </span>
                  <Table
                    columns={trainingColumns}
                    data={trainings}
                    containerClassname="text-center w-full"
                    trBodyClassname="even:bg-white"
                  />
                </div>
              ) : null
            }
            arrow
            placement="top-start"
          >
            <div className="flex w-full items-center justify-center">
              <p
                className={twMerge(
                  'p-2 text-xs text-gray-600',
                  'flex h-6 items-center justify-center px-3 text-center text-blue-800',
                )}
              >
                {title as string}
              </p>
            </div>
          </HtmlTooltip>
        </span>
      );
    },
  },
];

export const trainingColumns: Array<ColumnDef<any>> = [
  {
    accessorKey: 'name',
    header: 'Treinamento',
    enableSorting: false,
    cell: (props) => (
      <span className="flex w-full overflow-hidden">
        <TableData
          tableValue={props.getValue()}
          title={props.getValue() as string}
          className="w-48 overflow-hidden text-ellipsis whitespace-nowrap px-2 text-center"
        />
      </span>
    ),
  },
  {
    accessorKey: 'date',
    header: 'Data',
    enableSorting: false,
    cell: (props) => {
      return (
        <span className="flex items-center justify-center  pl-2 pr-4">
          <TableCellDate
            date={removeTimeZone(new Date(props.getValue() as string))}
            className="text-center"
          />
        </span>
      );
    },
  },
  {
    accessorKey: 'startHour',
    header: 'Horário de início',
    enableSorting: false,
    cell: (props) => {
      return (
        <span className="flex items-center justify-center  pl-2 pr-4">
          <TableData
            tableValue={props.getValue()}
            className={twMerge('flex items-center rounded-sm px-3 text-left ')}
          />
        </span>
      );
    },
  },
  {
    accessorKey: 'endHour',
    header: 'Horário de fim',
    enableSorting: false,
    cell: (props) => {
      return (
        <span className="mx-4 flex items-center  justify-center pl-2 pr-4">
          <TableData
            tableValue={props.getValue()}
            className={twMerge('flex items-center rounded-sm px-3 text-left ')}
          />
        </span>
      );
    },
  },
  {
    accessorKey: 'location',
    header: 'Local',
    enableSorting: false,
    cell: (props) => (
      <span className="flex items-center justify-center  pr-4">
        <TableData
          tableValue={props.getValue()}
          className={twMerge('flex  items-center rounded-sm px-3 ')}
        />
      </span>
    ),
  },
];

export const extraordinaryAllocationColumns: (
  trainingPlanning: TrainingPlanningForm,
  setTrainingPlanning: (a: TrainingPlanningForm) => void,
) => Array<ColumnDef<any>> = (
  trainingPlanning: TrainingPlanningForm,
  setTrainingPlanning: (a: TrainingPlanningForm) => void,
) => [
  {
    id: 'select',
    meta: {
      headerClassName: 'sticky left-[-0.1rem] z-10 bg-white',
      stickyClassName: 'sticky left-[-0.1rem] z-10 bg-inherit',
    },
    cell: (props) => {
      const handleSubmit = async (employee: any, isChecked: boolean) => {
        try {
          if (
            isChecked &&
            trainingPlanning.extraordinaryAllocationId !==
              employee?.employeeNumber
          ) {
            const updatedSelectedEmployee =
              trainingPlanning.selectedEmployee.filter(
                (el) =>
                  el.employee.employeeNumber !==
                  trainingPlanning.extraordinaryAllocationId,
              );
            updatedSelectedEmployee.push({
              employee,
              extraordinaryAllocationMotive: '',
              isExtraordinaryAllocation: true,
              isThirdParty: false,
            });

            setTrainingPlanning({
              selectedEmployee: updatedSelectedEmployee,
              isAllEmployeeSelected: false,
              trainingDate: trainingPlanning.trainingDate,
              extraordinaryAllocationId: employee?.employeeNumber,
            });
          } else if (!isChecked) {
            const updatedSelectedEmployee =
              trainingPlanning.selectedEmployee.filter(
                (el) => el.employee.employeeNumber !== employee?.employeeNumber,
              );

            setTrainingPlanning({
              selectedEmployee: updatedSelectedEmployee,
              extraordinaryAllocationId: '',
              isAllEmployeeSelected: false,
              trainingDate: trainingPlanning.trainingDate,
            });
          }
        } catch (e) {
          toast.error('Erro ao selecionar colaborador', {
            theme: 'colored',
            toastId: 'error',
          });
        }
      };
      const availabilityData = props.row.original
        ?.availabilityByDay as AvailabilityTrainingData;

      return (
        <div className={`flex w-full items-center justify-center`}>
          <Checkbox
            checked={
              !!(
                trainingPlanning.extraordinaryAllocationId ===
                (props.row.original.employeeNumber as string)
              )
            }
            disabled={
              availabilityData.every(
                (it) =>
                  it.availability.availability === Availability.Disponivel,
              ) && props.row.original.extraordinaryModalHasTrainingControl
            }
            onChange={(e) => handleSubmit(props.row.original, e.target.checked)}
          />
        </div>
      );
    },
  },
  {
    accessorKey: 'name',
    header: 'Colaborador',
    enableSorting: true,
    meta: {
      headerClassName: 'sticky left-[2rem] z-10 bg-white',
      stickyClassName: 'sticky left-[2rem] z-10 bg-inherit',
    },
    cell: (props) => {
      return (
        <span
          className={`flex items-center justify-center pb-2 pl-2 pr-4 pt-2`}
        >
          <TableData
            title={props.row.original.alias ?? (props.getValue() as string)}
            tableValue={
              props.row.original.alias ??
              getFirstAndLastName(props.getValue() as string)
            }
            className={twMerge(
              'flex h-6 w-44 items-center rounded-sm px-3 text-center ',
            )}
          />
        </span>
      );
    },
  },
  {
    accessorKey: 'employeeNumber',
    header: 'Chapa',
    enableSorting: true,
    meta: {
      headerClassName: 'sticky left-[14rem] z-10 bg-white',
      stickyClassName: 'sticky left-[14rem] z-10 bg-inherit',
    },
    cell: (props) => {
      return (
        <span
          className={`flex items-center justify-center pb-2 pl-2 pr-4 pt-2`}
        >
          {' '}
          <TableData
            tableValue={props.getValue()}
            className={twMerge(
              'flex h-6 items-center justify-center px-3 text-left ',
            )}
          />
        </span>
      );
    },
  },
  {
    accessorKey: 'availabilityByDay',
    header: 'Disponibilidade',
    enableSorting: true,
    cell: (props) => {
      const availabilityData = props.getValue() as AvailabilityTrainingData;

      return (
        <span className="flex items-center justify-center pl-2 pr-4">
          <AvailabilityTableCell availabilityData={availabilityData} />
        </span>
      );
    },
  },
  {
    accessorKey: 'trainings',
    header: 'Treinamentos por dia',
    enableSorting: true,
    cell: (props) => {
      const trainings = props.getValue() as any[];
      const title = `${trainings?.length} ${trainings?.length === 1 ? 'Treinamento' : 'Treinamentos'}`;
      return (
        <span className="flex w-60 items-center  justify-center">
          <HtmlTooltip
            title={
              trainings?.length > 0 ? (
                <div
                  style={{
                    whiteSpace: 'pre-line',
                    minHeight: '60px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    justifyContent: 'space-between',
                    padding: '8px',
                    gap: '8px',
                    width: 800,
                  }}
                >
                  <span className="text-lg font-semibold text-[#193CB9]">
                    Treinamentos do dia{' '}
                    {trainingPlanning.trainingDate?.toLocaleDateString()}
                  </span>
                  <Table
                    columns={trainingColumns}
                    data={trainings}
                    containerClassname="text-center w-full"
                    trBodyClassname="even:bg-white"
                  />
                </div>
              ) : null
            }
            arrow
            placement="top-start"
          >
            <div className="flex w-full items-center justify-center">
              <p
                className={twMerge(
                  'p-2 text-xs text-gray-600',
                  'flex h-6 items-center justify-center px-3 text-center text-blue-800',
                )}
              >
                {title as string}
              </p>
            </div>
          </HtmlTooltip>
        </span>
      );
    },
  },
  {
    accessorKey: 'expirationStatus',
    header: 'Situação de Vencimento',
    enableSorting: true,
    cell: (props) => {
      const value = props.getValue() as ExpirationStatus | null;
      return (
        <div className="mr-5 flex w-40 justify-center">
          <span className="flex items-center justify-center pl-8 pr-4">
            {value === null || value === undefined ? (
              '-'
            ) : (
              <TableCellStatus status={value} />
            )}
          </span>
        </div>
      );
    },
  },
  {
    accessorKey: 'management',
    header: 'Gerência',
    enableSorting: true,
    cell: (props) => {
      const tableValue = props.getValue() as string;

      return (
        <span className="flex items-center justify-center pl-2 pr-4">
          <TableData
            tableValue={tableValue}
            title={props.getValue() as string}
            className={twMerge(
              'flex h-6 w-44 items-center rounded-sm px-3 text-center ',
            )}
          />
        </span>
      );
    },
  },
  {
    accessorKey: 'coordination',
    header: 'Coordenação',
    enableSorting: true,
    cell: (props) => {
      const tableValue = props.getValue() as string;

      return (
        <span className="flex items-center justify-center pl-2 pr-4">
          <TableData
            tableValue={tableValue}
            title={props.getValue() as string}
            className={twMerge(
              'flex h-6  w-44  items-center rounded-sm px-3 text-center ',
            )}
          />
        </span>
      );
    },
  },
  {
    accessorKey: 'role',
    header: 'Função',
    enableSorting: true,
    cell: (props) => {
      const tableValue = props.getValue() as string;
      return (
        <span className="flex items-center justify-center pl-2 pr-4">
          <TableData
            tableValue={tableValue}
            title={props.getValue() as string}
            className={twMerge(
              'flex h-8 w-44 items-center overflow-hidden rounded-sm px-3 text-center ',
            )}
          />
        </span>
      );
    },
  },
  {
    accessorKey: 'regime',
    header: 'Regime',
    enableSorting: true,
    cell: (props) => (
      <span className="flex items-center justify-center pl-2 pr-4">
        <TableData
          tableValue={props.getValue()}
          title={props.getValue() as string}
          className={twMerge(
            'flex h-8  w-44  items-center rounded-sm px-3 text-center ',
          )}
        />
      </span>
    ),
  },
  {
    accessorKey: 'rmStatus',
    header: 'Situação RM',
    enableSorting: true,
    cell: (props) => (
      <span className="flex items-center justify-center pl-2 pr-4">
        <TableData
          title={props.getValue() as string}
          tableValue={props.getValue()}
          className={twMerge(
            'flex h-6 w-28 items-center rounded-sm px-3 text-center ',
          )}
        />
      </span>
    ),
  },
  {
    accessorKey: 'statusByDay',
    header: 'Situação do colaborador',
    enableSorting: true,
    cell: (props) => (
      <span className="flex items-center justify-center pl-2 pr-4">
        <StatusTableCell statusData={props.getValue() as StatusTrainingData} />
      </span>
    ),
  },
  {
    accessorKey: 'dayOffByDay',
    header: 'Hora extra',
    enableSorting: true,
    cell: (props) => (
      <span className="flex items-center justify-center pl-2 pr-4">
        <StatusTableCell statusData={props.getValue() as StatusTrainingData} />
      </span>
    ),
  },
  {
    accessorKey: 'realization',
    header: 'Realização',
    enableSorting: true,
    cell: (props) => (
      <span className="flex items-center justify-center pl-2 pr-4">
        <TableData
          tableValue={props.getValue()}
          className={twMerge(
            'flex h-6 w-28 items-center rounded-sm px-3 text-center ',
          )}
        />
      </span>
    ),
  },
  {
    accessorKey: 'state',
    header: 'Estado de moradia',
    enableSorting: true,
    cell: (props) => (
      <span className="flex items-center  justify-center pl-2 pr-4">
        <TableData
          tableValue={props.getValue()}
          className={twMerge(
            'flex h-6 w-28 items-center justify-center rounded-sm px-3  text-center ',
          )}
        />
      </span>
    ),
  },
  {
    accessorKey: 'workStation',
    header: 'Local de trabalho',
    enableSorting: true,
    cell: (props) => (
      <span className="flex items-center justify-center pl-2 pr-4">
        <TableData
          tableValue={props.getValue()}
          className={twMerge(
            'flex h-6 w-28 items-center justify-center rounded-sm px-3 text-center ',
          )}
        />
      </span>
    ),
  },
];

export const extraordinaryThirdPartyAllocationColumns: (
  trainingPlanning: TrainingPlanningForm,
  setTrainingPlanning: (a: TrainingPlanningForm) => void,
) => Array<ColumnDef<any>> = (
  trainingPlanning: TrainingPlanningForm,
  setTrainingPlanning: (a: TrainingPlanningForm) => void,
) => [
  {
    id: 'select',
    meta: {
      headerClassName: 'z-10 bg-white',
      stickyClassName: 'z-10 bg-inherit',
    },
    cell: (props) => {
      const handleSubmit = async (employee: any, isChecked: boolean) => {
        try {
          let updatedSelectedEmployee = [...trainingPlanning.selectedEmployee];

          if (isChecked) {
            updatedSelectedEmployee.push({
              employee,
              extraordinaryAllocationMotive: '',
              isExtraordinaryAllocation: false,
              isThirdParty: true,
            });
          } else {
            updatedSelectedEmployee = updatedSelectedEmployee.filter(
              (el) => el.employee.employeeNumber !== employee?.employeeNumber,
            );
          }

          setTrainingPlanning({
            ...trainingPlanning,
            selectedEmployee: updatedSelectedEmployee,
          });
        } catch (e) {
          toast.error('Erro ao selecionar colaborador', {
            theme: 'colored',
            toastId: 'error',
          });
        }
      };

      const isChecked = trainingPlanning.selectedEmployee.some(
        (el) =>
          el.employee.employeeNumber === props.row.original.employeeNumber,
      );

      return (
        <div className="flex w-full items-center justify-center">
          <Checkbox
            checked={isChecked}
            onChange={(e) => handleSubmit(props.row.original, e.target.checked)}
          />
        </div>
      );
    },
  },
  {
    accessorKey: 'name',
    header: 'Colaborador',
    enableSorting: true,
    meta: {
      headerClassName: 'z-10 bg-white',
      stickyClassName: 'z-10 bg-inherit',
    },
    cell: (props) => {
      return (
        <span
          className={`flex items-center justify-center pb-2 pl-2 pr-4 pt-2`}
        >
          <TableData
            title={props.row.original.alias ?? (props.getValue() as string)}
            tableValue={
              props.row.original.alias ??
              getFirstAndLastName(props.getValue() as string)
            }
            className={twMerge(
              'flex h-6 w-44 items-center rounded-sm px-3 text-center uppercase ',
            )}
            containerClassName="justify-center"
          />
        </span>
      );
    },
  },
  {
    accessorKey: 'employeeNumber',
    header: 'Chapa',
    enableSorting: true,
    meta: {
      headerClassName: 'z-10 bg-white',
      stickyClassName: 'z-10 bg-inherit',
    },
    cell: (props) => {
      return (
        <span
          className={`flex items-center justify-center pb-2 pl-2 pr-4 pt-2`}
        >
          {' '}
          <TableData
            tableValue={props.getValue()}
            className={twMerge(
              'flex h-6 items-center justify-center px-3 text-left ',
            )}
            containerClassName="justify-center"
          />
        </span>
      );
    },
  },
  {
    accessorKey: 'cpf',
    header: 'CPF',
    enableSorting: true,
    meta: {
      headerClassName: 'z-10 bg-white',
      stickyClassName: 'z-10 bg-inherit',
    },
    cell: (props) => {
      const formatCPF = (cpf: string) => {
        const numbers = cpf.replace(/\D/g, '');
        return numbers.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
      };

      const tableValue = props.getValue() as string;
      const formattedCPF = formatCPF(tableValue);

      return (
        <span
          className={`flex items-center justify-center pb-2 pl-2 pr-4 pt-2`}
        >
          {' '}
          <TableData
            tableValue={formattedCPF}
            className={twMerge(
              'flex h-6 items-center justify-center px-3 text-left uppercase',
            )}
            containerClassName="justify-center"
          />
        </span>
      );
    },
  },
  {
    accessorKey: 'company',
    header: 'Empresa',
    enableSorting: true,
    meta: {
      headerClassName: 'z-10 bg-white',
      stickyClassName: 'z-10 bg-inherit',
    },
    cell: (props) => {
      return (
        <span
          className={`flex items-center justify-center pb-2 pl-2 pr-4 pt-2`}
        >
          {' '}
          <TableData
            tableValue={props.getValue()}
            className={twMerge(
              'flex h-6 items-center justify-center px-3 text-left uppercase text-black',
            )}
            containerClassName="justify-center"
          />
        </span>
      );
    },
  },
  {
    accessorKey: 'role',
    header: 'Função',
    enableSorting: true,
    meta: {
      headerClassName: 'z-10 bg-white',
      stickyClassName: 'z-10 bg-inherit',
    },
    cell: (props) => {
      const value = props.getValue() as string;
      const displayValue = value.split('-')[0];

      return (
        <div className="flex items-center justify-center pl-2 pr-4">
          <TableData
            tableValue={displayValue}
            title={displayValue}
            placement="top"
            className={twMerge(
              'overflow-hidden text-ellipsis whitespace-nowrap rounded-sm px-3 text-center uppercase',
            )}
            containerClassName={'justify-center w-72'}
          />
        </div>
      );
    },
  },
  {
    accessorKey: 'trainings',
    header: 'Treinamentos por dia',
    enableSorting: true,
    cell: (props) => {
      const trainings = props.getValue() as any[];
      const title = `${trainings?.length} ${trainings?.length === 1 ? 'Treinamento' : 'Treinamentos'}`;
      return (
        <span className="flex w-60 items-center  justify-center">
          <HtmlTooltip
            title={
              trainings?.length > 0 ? (
                <div
                  style={{
                    whiteSpace: 'pre-line',
                    minHeight: '60px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    justifyContent: 'space-between',
                    padding: '8px',
                    gap: '8px',
                    width: 800,
                  }}
                >
                  <span className="text-lg font-semibold text-[#193CB9]">
                    Treinamentos do dia{' '}
                    {trainingPlanning.trainingDate?.toLocaleDateString()}
                  </span>
                  <Table
                    columns={trainingColumns}
                    data={trainings}
                    containerClassname="text-center w-full"
                    trBodyClassname="even:bg-white"
                  />
                </div>
              ) : null
            }
            arrow
            placement="top-start"
          >
            <div className="flex w-full items-center justify-center">
              <p
                className={twMerge(
                  'p-2 text-xs text-gray-600',
                  'flex h-6 items-center justify-center px-3 text-center text-blue-800',
                )}
              >
                {title as string}
              </p>
            </div>
          </HtmlTooltip>
        </span>
      );
    },
  },
];
