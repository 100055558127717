import { ChangeEvent, useState } from 'react';
import { useRecoilState } from 'recoil';

import { UserTypesEnum } from '@/enums/UserTypesEnum';
import { useRoles } from '@/hooks/useRoles';
import { cn } from '@/lib/utils';
import { openJobtitleCreateModalAtom } from '@/state/CreatePositionModal.atom';
import { openJobtitleEditModalAtom } from '@/state/EditPositionModal.atom';
import { multipleReportsAtom } from '@/state/MultipleReports.atom';
import { multipleReportsPopoverAtom } from '@/state/MultipleReportsPopover.atom';
import { positionParentsAtom } from '@/state/PositionParents.atom';
import { reportsPopoverAtom } from '@/state/ReportsPopover.atom';
import { PositionChartDoubleClickArgs } from '@/types/PositionChartDoubleClickArgs';
import { getBackgroundColor } from '@/utils/getBackgroundColors';
import { getColor } from '@/utils/getColorByChart';

import Checkbox from '../atoms/Checkbox';
import SimpleMenu from '../atoms/Menu';
import { Position } from '../organisms/ChartGraph';
import JobtitleModal from './PositionModal';

interface Props {
  position: Position;
  handleChange?: (data: PositionChartDoubleClickArgs) => void;
}

const PositionCard = ({ position, handleChange }: Props) => {
  const { hasSomeRoles } = useRoles();
  const divergencesOnEmployeeNumber =
    position.amountOfExpectedEmployees !== position.amountOfAllocatedEmployees;
  const emptyPosition = position.amountOfAllocatedEmployees === 0;
  const divergenceColor = '#F4503A';
  const bgColor = getColor(position.regime);
  const textColor = getColor(`${position.regime}-text`);
  const border =
    position.board.company?.name === 'TAMAC'
      ? 'border-[2.5px] border-[#19B986]'
      : getColor(position.workStation.pole);
  const borderStyle = position.isIntermediary
    ? 'border-dashed'
    : 'border-solid';
  const poleColor =
    position.board.company?.name === 'TAMAC'
      ? '#19B986'
      : getBackgroundColor(position.workStation.pole);
  const bg = emptyPosition
    ? 'bg-[#C4C4C4]'
    : position.board.company?.name === 'TAMAC'
      ? 'bg-[#19B986]'
      : position.workStation.pole.trim() === 'Alagoas'
        ? 'bg-black'
        : `bg-[${poleColor}]`;
  const [isOpenCreate, setIsOpenCreate] = useRecoilState(
    openJobtitleCreateModalAtom,
  );
  const [isOpenEdit, setIsOpenEdit] = useRecoilState(openJobtitleEditModalAtom);
  const [isOpenDelete, setIsOpenDelete] = useState<boolean>(false);
  const [multipleReports, setMultipleReports] =
    useRecoilState(multipleReportsAtom);
  const [multipleReportsPopover, setMultipleReportsPopover] = useRecoilState(
    multipleReportsPopoverAtom,
  );
  const [reportsPopover, setReportsPopover] =
    useRecoilState(reportsPopoverAtom);
  const reportsColor = position.numberOfReports > 0 ? bg : 'bg-[#C4C4C4]';
  const [positionParents, setPositionParents] =
    useRecoilState(positionParentsAtom);
  const options = [
    {
      name: 'Criar nova posição de reporte',
      onClick: () => setIsOpenCreate(position.id),
      disabled: false,
    },
    {
      name: 'Criar nova posição de reportes múltiplos',
      onClick: () => {
        setMultipleReportsPopover(position.id);
        setMultipleReports({
          positionId: null,
          parentsId: [position.id],
        });
      },
      disabled: false,
    },
    {
      name: 'Editar posição',
      onClick: () => setIsOpenEdit(position.id),
      disabled: false,
    },
    {
      name: 'Editar reporte da posição',
      onClick: () => {
        setReportsPopover(position.id);
        setMultipleReports({
          positionId: position.id,
          parentsId: position.reportsTo.map((el) => el.id),
        });
      },
      disabled: false,
    },
    {
      name: 'Excluir posição',
      onClick: () => setIsOpenDelete(true),
      disabled: false,
    },
  ];

  return (
    <>
      <div
        className={cn(
          'flex h-[155px] w-[165px] cursor-pointer justify-center overflow-hidden rounded-lg font-graphie text-[#6F6F6F]',
          border,
          borderStyle,
          emptyPosition ? 'border-[#C4C4C4]' : '',
        )}
        onDoubleClick={() =>
          handleChange &&
          handleChange({
            positionFilter: position.id,
          })
        }
      >
        <div
          className={`relative flex  h-full w-full flex-col  items-center rounded-lg bg-white`}
        >
          <div className="absolute right-0 top-0 flex">
            <SimpleMenu
              options={options}
              upDotsButtonColor={
                !!multipleReportsPopover || !!reportsPopover
                  ? 'disabled'
                  : 'primary'
              }
              upDotsButton
              disabled={
                !!multipleReportsPopover ||
                !!reportsPopover ||
                !hasSomeRoles([UserTypesEnum.Rh])
              }
            />
          </div>
          <div className=" flex h-full w-full flex-col items-center justify-between">
            <div className="mb-2 flex w-full">
              {multipleReportsPopover && !position.isIntermediary && (
                <div className="z-[100] px-2">
                  <Checkbox
                    defaultChecked={
                      multipleReports.parentsId[0] === position.id
                    }
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      if (e.target.checked) {
                        setMultipleReports((prevState) => ({
                          ...prevState,
                          parentsId: [...prevState.parentsId, position.id],
                        }));
                      } else {
                        setMultipleReports((prevState) => ({
                          ...prevState,
                          parentsId: prevState.parentsId.filter(
                            (el: number) => el !== position.id,
                          ),
                        }));
                      }
                    }}
                  />
                </div>
              )}
              {reportsPopover &&
                ((positionParents.length === 0 &&
                  multipleReports.parentsId.length === 0) ||
                  multipleReports.parentsId.includes(position.id) ||
                  position.reportsTo
                    .map((el) => el.id)
                    .some((elem) => positionParents.includes(elem))) && (
                  <div className="z-[100] px-2">
                    <Checkbox
                      defaultChecked={multipleReports.parentsId.includes(
                        position.id,
                      )}
                      disabled={position.id === multipleReports.positionId}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        if (e.target.checked) {
                          setPositionParents(
                            position.reportsTo.map((el) => el.id),
                          );
                          setMultipleReports((prevState) => ({
                            ...prevState,
                            parentsId: [...prevState.parentsId, position.id],
                          }));
                        } else {
                          setMultipleReports((prevState) => ({
                            ...prevState,
                            parentsId: prevState.parentsId.filter(
                              (el: number) => el !== position.id,
                            ),
                          }));
                        }
                      }}
                    />
                  </div>
                )}
              <div
                className={cn(
                  'm-1 flex h-4 min-w-12 items-center justify-center rounded-lg bg-[#236BFF] px-1 text-[8px] text-white',
                  bg,
                )}
              >
                {position?.workStation?.name ?? '-'}
              </div>
            </div>
            <div className="my-1 flex w-full flex-1 flex-col items-center justify-between gap-2 px-[1px] font-medium">
              <p
                className={cn(
                  'px-1 text-center text-[10px]',
                  emptyPosition ? 'text-[#C4C4C4]' : '',
                )}
              >
                {position.name}
              </p>
              <div
                className={cn(
                  'flex h-[14px] w-[50px] items-center justify-center rounded-full text-[9px] font-semibold leading-[14px]',
                  textColor,
                  bgColor,
                )}
              >
                {position?.regime ?? '-'}
              </div>
            </div>

            <div className="flex w-full flex-col items-center">
              <p
                className={cn(
                  'my-[2px] px-1 text-[10px] font-medium',
                  divergencesOnEmployeeNumber
                    ? `text-[${[divergenceColor]}]`
                    : '',
                )}
              >
                <span>ALOCAÇÕES: </span>
                <span>{position.amountOfAllocatedEmployees}</span>/
                <span>{position.amountOfExpectedEmployees}</span>
              </p>
              <div
                className={cn(
                  'mt-[1px] flex h-[14px] w-5/6 items-center justify-center rounded-t text-center text-[10px] uppercase leading-[14px] text-[#ffffff]',
                  reportsColor,
                )}
              >
                {`${position?.numberOfReports} ${position?.numberOfReports === 1 ? 'reporte' : 'reportes'}`}
              </div>
            </div>
          </div>
        </div>
      </div>
      {isOpenCreate === position.id && (
        <JobtitleModal
          isOpen={isOpenCreate === position.id}
          onClose={() => setIsOpenCreate(null)}
          data={position}
          type="create"
        />
      )}
      {isOpenEdit === position.id && (
        <JobtitleModal
          isOpen={isOpenEdit === position.id}
          onClose={() => setIsOpenEdit(null)}
          data={position}
          type={'edit'}
        />
      )}
      {isOpenDelete && (
        <JobtitleModal
          isOpen={isOpenDelete}
          onClose={() => setIsOpenDelete(false)}
          data={position}
          type={'delete'}
        />
      )}
    </>
  );
};

export default PositionCard;
