import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button } from '../atoms/Button';

interface HomeCardsProps {
  title: string;
  description: string;
  img: string;
  path: string;
  className?: string;
  disabled?: boolean;
}

const HomeCards: FC<HomeCardsProps> = ({
  title,
  description,
  img,
  path,
  className,
  disabled = false,
}) => {
  const navigate = useNavigate();

  const handleClickButton = () => {
    return navigate(path);
  };

  return (
    <div
      className={`flex gap-4 rounded-xl bg-white px-4 py-4 md:px-7 md:py-5 md:pr-5 ${
        className ?? ''
      }`}
    >
      <section className="flex flex-1 flex-col justify-center gap-3">
        <h2 className="text-[0.800rem] font-bold text-primary md:text-2xl">
          {title}
        </h2>

        <p className="text-[0.6rem] font-medium text-secondary-light md:text-[0.75rem]">
          {description}
        </p>

        <div className="relative w-min">
          <Button
            className="mt-2  h-8 max-w-[80px] rounded-lg text-[0.75rem] font-medium md:w-32 md:max-w-[200px]"
            onClick={handleClickButton}
            disabled={disabled}
          >
            Acessar
          </Button>
        </div>
      </section>

      <img
        src={img}
        alt={title}
        className="aspect-square h-[200px] w-[100px] rounded-xl object-cover md:w-[210px]"
      />
    </div>
  );
};

export default HomeCards;
