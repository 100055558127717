import { useRoles } from '@/hooks/useRoles';
import { routes } from '@/routes/routes';

import HomeCards from '../../components/molecules/HomeCards';

const Home = () => {
  const { hasSomeRoles } = useRoles();
  const cards = [
    {
      title: 'Estrutura e Organograma',
      description: 'Visualização da estrutura e do organograma da Origem',
      img: '/chart.png',
      path: routes.organizationChart.path,
      requiredRoles: routes.organizationChart.requiredRoles,
    },
    {
      title: 'Cargos, Funções e Requisitos',
      description: 'Gestão de cargos e requisitos',
      img: '/magnifier.png',
      path: routes.jobTitle.path,
      requiredRoles: routes.jobTitle.requiredRoles,
    },
    {
      title: 'Planejamento de Escalas e Férias',
      description: 'Controle e gestão de escalas',
      img: '/calendar.png',
      path: routes.schedule.path,
      requiredRoles: routes.schedule.requiredRoles,
    },
    {
      title: 'Gestão de Treinamentos',
      description: 'Controle e planejamento dos treinamentos',
      img: '/laptop.png',
      path: routes.trainingMatrix.path,
      requiredRoles: routes.trainingMatrix.requiredRoles,
    },
  ];

  return (
    <div className="flex h-[1500px] w-full items-center justify-center px-[5%] pb-8 pt-6 md:h-full md:pb-0 md:pt-0">
      <div className="flex-col space-y-4 md:grid md:grid-cols-2 md:grid-rows-2 md:gap-4 md:space-y-0">
        {cards.map((card) => {
          const hasPermission = hasSomeRoles(card.requiredRoles);
          return (
            <HomeCards
              key={card.title}
              title={card.title}
              description={card.description}
              img={card.img}
              path={card?.path}
              className="h-[250px] max-w-[350px] md:max-w-[600px]"
              disabled={!hasPermission}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Home;
