import * as r from '@recoiljs/refine';
import { atom } from 'recoil';
import { urlSyncEffect } from 'recoil-sync';

export interface JobTrainingMatrixFilters
  extends Record<string, string[] | number[]> {
  trainingType: string[];
  location: string[];
  jobTitle: string[] | number[];
  training: string[];
  management: string[];
  areaCoordination: string[];
  workstation: string[];
}

const stringArrayChecker = r.array(r.string()) as r.Checker<string[]>;
const stringOrNumberArrayChecker = r.or(
  r.array(r.string()),
  r.array(r.number()),
) as r.Checker<string[] | number[]>;

const jobTrainingMatrixFiltersAtom = atom<JobTrainingMatrixFilters>({
  key: 'jobTrainingMatrixFilters',
  default: {
    trainingType: [],
    location: [],
    management: [],
    areaCoordination: [],
    workstation: [],
    jobTitle: [],
    training: [],
  },
  effects: [
    urlSyncEffect<JobTrainingMatrixFilters>({
      refine: r.object({
        trainingType: stringArrayChecker,
        location: stringArrayChecker,
        management: stringArrayChecker,
        areaCoordination: stringArrayChecker,
        workstation: stringArrayChecker,
        jobTitle: stringOrNumberArrayChecker,
        training: stringArrayChecker,
      }),
      history: 'push',
    }),
  ],
});

export { jobTrainingMatrixFiltersAtom };
