import { CircularProgress, IconButton } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { ColumnDef } from '@tanstack/react-table';
import { useState } from 'react';
import { BsPencilSquare } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import FileUploadComponent from '@/components/atoms/FileUpload';
import TableData from '@/components/atoms/TableData';
import { ViewFiles } from '@/components/atoms/ViewFiles';
import TrainingControlModal from '@/components/organisms/TrainingControlModal';
import { EvidenceFile } from '@/types/EvidenceFile';
import { getFirstAndLastName } from '@/utils/getFirstAndLastName';

import { queryClient } from '../../App';
import TableCellDate from '../../components/atoms/TableCellDate';
import TableCellStatus from '../../components/atoms/TableCellStatus';
import TableCellText from '../../components/atoms/TableCellText';
import api from '../../services/apiSgft';
import { TrainingControl } from '../../types/TrainingControl';
import { ExpirationStatus } from '../../types/TrainingControlTypes';
import { removeTimeZone } from '../../utils/formatDate';
import { Modality } from '../TrainingConstants';

export const columns: Array<ColumnDef<TrainingControl>> = [
  {
    id: 'edit',
    meta: {
      headerClassName: 'sticky left-0 z-10 pl-2 bg-white text-start',
      stickyClassName: 'sticky left-0 z-10 bg-inherit',
    },
    header: '',
    cell: (value) => {
      const [isTrainingControlModalOpen, setIsTrainingControlModalOpen] =
        useState(false);

      const handleOpenModal = () => {
        setIsTrainingControlModalOpen(true);
      };

      return (
        <div className="w-16">
          <IconButton
            sx={{
              margin: 1,
              height: '1.8rem',
              width: '1.8rem',
              background: '#1F3FA8',
              borderRadius: '100%',
              '&:hover': {
                background: '#4c4c73',
              },
              '&:disabled': {
                background: '#9ca3af',
              },
            }}
            aria-label="export"
            onClick={handleOpenModal}
            disabled={isTrainingControlModalOpen}
          >
            <BsPencilSquare color="white" height={'7px'} width={'7px'} />
          </IconButton>
          {isTrainingControlModalOpen && (
            <TrainingControlModal
              trainingControl={value.row.original}
              modalIsOpen={isTrainingControlModalOpen}
              handleClose={() => {
                setIsTrainingControlModalOpen(false);
              }}
            />
          )}
        </div>
      );
    },
  },
  {
    accessorFn: (row) => row.employee.name,
    header: 'NOME',
    meta: {
      headerClassName: 'sticky left-16 pl-1 bg-white text-start',
      stickyClassName: 'sticky left-16 z-10 bg-inherit',
    },
    cell: (props) => {
      const navigate = useNavigate();

      return (
        <div className={`flex w-32 items-start pl-2`}>
          <TableData
            tableValue={
              props.row.original.employee.alias ??
              getFirstAndLastName(props.getValue() as string)
            }
            className="flex w-40 cursor-pointer justify-start whitespace-nowrap text-[11px] font-semibold underline"
            title={props.getValue() as string}
            onClick={() =>
              navigate(
                `/home/colaborador/${props.row.original.employee.id.toString()}/historico-treinamentos`,
              )
            }
          />
        </div>
      );
    },
  },
  {
    accessorFn: (row) => row.employee.employeeNumber,
    header: 'CHAPA',
    meta: {
      headerClassName: 'sticky left-48 pl-1 bg-white text-start',
      stickyClassName: 'sticky left-48 z-10 bg-inherit',
    },
    cell: (props) => {
      return (
        <div className={`mr-5 flex w-24 items-start`}>
          <TableCellText
            text={(props.getValue() as string)?.toUpperCase()}
            align="start"
          />
        </div>
      );
    },
  },
  {
    accessorFn: (row) => row.employee.pole,
    header: 'POLO',
    meta: {
      headerClassName: 'text-start',
    },
    cell: (props) => (
      <div className="mr-5 flex w-20 items-start">
        <TableCellText
          text={
            props.getValue()
              ? (props.getValue() as string)?.toUpperCase()
              : 'N/A'
          }
          align="start"
        />
      </div>
    ),
  },
  {
    accessorFn: (row) => row.employee.management,
    header: 'GERÊNCIA',
    meta: {
      headerClassName: 'text-start',
    },
    cell: (props) => (
      <div className="mr-5 flex w-[8rem] items-start">
        <TableCellText
          text={
            props.getValue()
              ? (props.getValue() as string)?.toUpperCase()
              : 'N/A'
          }
          align="start"
          width="12rem"
        />
      </div>
    ),
  },
  {
    accessorFn: (row) => row.employee.areaCoordinator,
    header: 'COORDENAÇÃO',
    meta: {
      headerClassName: 'text-start',
    },
    cell: (props) => (
      <div className="mr-5 flex w-[8rem] items-start">
        <TableCellText
          text={
            props.getValue()
              ? (props.getValue() as string)?.toUpperCase()
              : 'N/A'
          }
          align="start"
        />
      </div>
    ),
  },
  {
    accessorFn: (row) => row.employee.workStation,
    header: 'LOCAL DE TRABALHO',
    meta: {
      headerClassName: 'text-start',
    },
    cell: (props) => (
      <div className="mr-5 flex w-36 items-start">
        <TableCellText
          text={
            props.getValue()
              ? (props.getValue() as string)?.toUpperCase()
              : 'N/A'
          }
          align="start"
        />
      </div>
    ),
  },
  {
    accessorFn: (row) => row.employee.role,
    header: 'FUNÇÃO',
    meta: {
      headerClassName: 'text-start',
    },
    cell: (props) => (
      <div className="mr-5 flex w-40 items-start">
        <TableCellText
          text={(props.getValue() as string)?.toUpperCase()}
          align="start"
          width="10rem"
        />
      </div>
    ),
  },

  {
    accessorFn: (row) => row.employee.status,
    header: 'SITUAÇÃO RM',
    meta: {
      headerClassName: 'text-start',
    },
    cell: (props) => (
      <div className="flex w-36 items-start justify-start">
        <TableCellText
          text={(props.getValue() as string)?.toUpperCase()}
          align="start"
        />
      </div>
    ),
  },
  {
    accessorFn: (row) => row.training.trainingType,
    header: 'TIPO DE TREINAMENTO',
    meta: {
      headerClassName: 'text-start',
    },
    cell: (props) => (
      <div className="mr-2 flex w-40 items-start justify-start">
        <TableCellText
          text={(props.getValue() as string)?.toUpperCase()}
          align="start"
        />
      </div>
    ),
  },
  {
    accessorFn: (row) => row.training.name,
    header: 'TREINAMENTO',
    meta: {
      headerClassName: 'text-start',
    },
    cell: (props) => (
      <div className="flex w-40 items-start justify-start">
        <TableCellText
          text={(props.getValue() as string)?.toUpperCase()}
          width="15rem"
          align="start"
        />
      </div>
    ),
  },
  {
    accessorFn: (row) => row.training.totalWorkload,
    header: 'CARGA HORÁRIA (H)',
    meta: {
      headerClassName: 'text-start',
    },
    cell: (props) => (
      <div className="flex w-36 items-center">
        <TableCellText text={props.getValue() as string} align="center" />
      </div>
    ),
  },
  {
    accessorFn: (row) => row.training.validity,
    header: 'VALIDADE (ANOS)',
    meta: {
      headerClassName: 'text-start',
    },
    cell: (props) => (
      <div className="flex w-36 items-center">
        <TableCellText text={props.getValue() as string} align="center" />
      </div>
    ),
  },
  {
    accessorKey: 'trainingModality',
    header: 'MODALIDADE',
    meta: {
      headerClassName: 'text-start',
    },
    cell: (props) => {
      return (
        <div className="flex w-36 items-center">
          <TableCellText
            text={
              props.getValue()
                ? (props.getValue() as string)?.toUpperCase()
                : ''
            }
            align="center"
          />
        </div>
      );
    },
  },
  {
    accessorKey: 'registerDate',
    header: 'DATA DE INSCRIÇÃO',
    meta: {
      headerClassName: 'text-start',
    },
    cell: (props) => {
      return (
        <div className="flex w-[12rem] items-start justify-start">
          <TableCellDate
            date={
              (props.getValue() as string) && props.getValue() !== '-'
                ? removeTimeZone(new Date(props.getValue() as string))
                : undefined
            }
          />
        </div>
      );
    },
  },
  {
    accessorKey: 'presencialCompletionDate',
    header: 'CONCLUSÃO (PRESENCIAL)',
    cell: (props) => {
      return (
        <div className="flex w-[8rem] items-start justify-between">
          <TableCellDate
            date={
              (props.getValue() as string) && props.getValue() !== '-'
                ? removeTimeZone(new Date(props.getValue() as string))
                : undefined
            }
          />
        </div>
      );
    },
  },
  {
    accessorKey: 'onlineCompletionDate',
    header: 'CONCLUSÃO (ONLINE)',
    cell: (props) => {
      return (
        <div className="flex w-[8rem] items-start justify-between">
          <TableCellDate
            date={
              (props.getValue() as string) && props.getValue() !== '-'
                ? removeTimeZone(new Date(props.getValue() as string))
                : undefined
            }
          />
        </div>
      );
    },
  },
  {
    accessorKey: 'presencialCompletionStatus',
    header: 'SITUAÇÃO DE TREINAMENTO (PRESENCIAL)',
    meta: {
      headerClassName: 'text-start',
    },
    cell: (props) => {
      return (
        <div className="ml-2 flex w-[13rem] items-center justify-between">
          <TableCellText
            text={
              props.getValue()
                ? (props.getValue() as string)?.toUpperCase()
                : ''
            }
            align="start"
            width="13rem"
            className="whitespace-normal py-0"
          />
        </div>
      );
    },
  },
  {
    accessorKey: 'onlineCompletionStatus',
    header: 'SITUAÇÃO DE TREINAMENTO (ONLINE)',
    meta: {
      headerClassName: 'text-start',
    },
    cell: (props) => {
      return (
        <div className="ml-2 flex w-[13rem] items-center justify-between">
          <TableCellText
            text={
              props.getValue()
                ? (props.getValue() as string)?.toUpperCase()
                : ''
            }
            align="start"
            width="13rem"
            className="whitespace-normal py-0"
          />
        </div>
      );
    },
  },
  {
    accessorKey: 'expirationStatus',
    header: 'SITUAÇÃO DE VALIDADE',
    meta: {
      headerClassName: 'text-start',
    },
    cell: (props) => (
      <div className="mr-5 flex w-40 justify-center">
        <TableCellStatus
          status={props.getValue() as ExpirationStatus}
          upperCase={true}
        />
      </div>
    ),
  },
  {
    accessorKey: 'updatedAt',
    header: 'ÚLTIMA MODIFICAÇÃO',
    meta: {
      headerClassName: 'text-start',
    },
    cell: (props) => (
      <div className="flex w-40">
        <TableCellDate
          date={removeTimeZone(new Date(props.getValue() as string))}
          className="flex justify-start"
        />
      </div>
    ),
  },
  {
    accessorKey: 'fileUploadPresencial',
    header: () => (
      <div
        style={{ display: 'inline-block', textAlign: 'center', width: '100%' }}
      >
        UPLOAD DE EVIDÊNCIAS
        <br />
        (PRESENCIAL)
      </div>
    ),
    meta: {
      headerClassName: 'text-start',
    },
    cell: (props) => {
      const [isLoading, setIsLoading] = useState(false);
      const historicId = props.row.original?.presencialEquivalentHistoric?.id;
      const employeeName = props.row.original.employee.name;
      const fetchEvidences = async () => {
        if (!historicId) {
          return [];
        }
        try {
          const response = await api.get(
            `training-control/training-control-history/${historicId}/evidences/`,
          );
          return response.data.map((el: File) => {
            return {
              ...el,
              employeeName,
            };
          });
        } catch (error: any) {
          toast.error(
            error?.response?.data?.message ??
              'Erro ao buscar arquivos de evidências!',
            {
              theme: 'colored',
              toastId: 'error',
            },
          );
        }
      };
      const { data: files, isFetching: isFetchingFiles } = useQuery<
        EvidenceFile[]
      >(['get-evidence-files', historicId], fetchEvidences, {
        retry: false,
        refetchOnMount: false,
      });
      async function handleFilesSelected(files: File[], id: string) {
        setIsLoading(true);
        const formData = new FormData();
        for (const file of files) {
          formData.append('files', file);
        }

        formData.append('id', id);

        try {
          await api.post(
            `/training-control/${props.row.original.id}/upload-evidence/${Modality.Presencial}`,
            formData,
          );
          toast.success('Arquivo(s) foram salvos com sucesso!', {
            theme: 'colored',
          });
        } catch (e: any) {
          const errorMessage =
            e?.response?.status === 400
              ? e?.response?.data?.message
              : 'Não foi possível salvar o(s) arquivo(s) enviado(s)!';
          toast.error(errorMessage, {
            theme: 'colored',
            toastId: 'error',
          });
        } finally {
          setIsLoading(false);
          queryClient.invalidateQueries({
            queryKey: ['training-control'],
          });
          queryClient.invalidateQueries({
            queryKey: ['get-evidence-files'],
          });
          queryClient.invalidateQueries({
            queryKey: ['employee-trainings'],
          });
        }
      }
      const handleDeleteEvidence = async (evidenceId: number) => {
        try {
          await api.delete(`training-control/delete-evidence/${evidenceId}`);
          queryClient.invalidateQueries({
            queryKey: ['get-evidence-files'],
          });
          toast.success(`Evidência deletada com sucesso!`, {
            theme: 'colored',
            toastId: 'success',
          });
          queryClient.invalidateQueries({
            queryKey: ['employee-trainings'],
          });
        } catch {
          toast.error('Não foi possível deletar a evidência!', {
            theme: 'colored',
            toastId: 'error',
          });
        }
      };
      if (props.row.original.presencialCompletionDate !== '-')
        return (
          <div className="w-30 ml-1 flex items-start justify-center">
            {isLoading ? (
              <CircularProgress className="ml-8" />
            ) : (
              <>
                <FileUploadComponent
                  onFilesSelected={(filesList: FileList | null) => {
                    const filesArray = filesList ? Array.from(filesList) : [];
                    handleFilesSelected(
                      filesArray,
                      String(props.row.original.id),
                    );
                  }}
                  rowId={`${String(props.row.original.id)}-presencial`}
                />
                <ViewFiles
                  files={files ?? []}
                  onDeleteFunction={handleDeleteEvidence}
                  deleteFileDescrption={`do histórico do colaborador ${props.row.original.employee.name} não poderá ser recuperado`}
                  isLoading={isFetchingFiles}
                />
              </>
            )}
          </div>
        );
    },
  },
  {
    accessorKey: 'fileUploadOnline',
    header: () => (
      <div
        style={{ display: 'inline-block', textAlign: 'center', width: '100%' }}
      >
        UPLOAD DE EVIDÊNCIAS
        <br />
        (ONLINE)
      </div>
    ),
    meta: {
      headerClassName: 'text-start',
    },
    cell: (props) => {
      const [isLoading, setIsLoading] = useState(false);
      const historicId = props.row.original?.onlineEquivalentHistoric?.id;
      const employeeName = props.row.original.employee.name;
      const fetchEvidences = async () => {
        if (!historicId) {
          return [];
        }
        try {
          const response = await api.get(
            `training-control/training-control-history/${historicId}/evidences/`,
          );
          return response.data.map((el: File) => {
            return {
              ...el,
              employeeName,
            };
          });
        } catch (error: any) {
          toast.error(
            error?.response?.data?.message ??
              'Erro ao buscar arquivos de evidências!',
            {
              theme: 'colored',
              toastId: 'error',
            },
          );
        }
      };
      const { data: files, isFetching: isFetchingFiles } = useQuery<
        EvidenceFile[]
      >(['get-evidence-files', historicId], fetchEvidences, {
        retry: false,
        refetchOnMount: false,
      });
      async function handleFilesSelected(files: File[], id: string) {
        setIsLoading(true);
        const formData = new FormData();
        for (const file of files) {
          formData.append('files', file);
        }

        formData.append('id', id);

        try {
          await api.post(
            `/training-control/${props.row.original.id}/upload-evidence/${Modality.Online}`,
            formData,
          );
          toast.success('Arquivo(s) foram salvos com sucesso!', {
            theme: 'colored',
          });
        } catch (e: any) {
          const errorMessage =
            e?.response?.status === 400
              ? e?.response?.data?.message
              : 'Não foi possível salvar o(s) arquivo(s) enviado(s)!';
          toast.error(errorMessage, {
            theme: 'colored',
            toastId: 'error',
          });
        } finally {
          setIsLoading(false);
          queryClient.invalidateQueries({
            queryKey: ['training-control'],
          });
          queryClient.invalidateQueries({
            queryKey: ['get-evidence-files'],
          });
          queryClient.invalidateQueries({
            queryKey: ['employee-trainings'],
          });
        }
      }
      const handleDeleteEvidence = async (evidenceId: number) => {
        try {
          await api.delete(`training-control/delete-evidence/${evidenceId}`);
          queryClient.invalidateQueries({
            queryKey: ['get-evidence-files'],
          });
          toast.success(`Evidência deletada com sucesso!`, {
            theme: 'colored',
            toastId: 'success',
          });
          queryClient.invalidateQueries({
            queryKey: ['employee-trainings'],
          });
        } catch {
          toast.error('Não foi possível deletar a evidência!', {
            theme: 'colored',
            toastId: 'error',
          });
        }
      };
      if (props.row.original.onlineCompletionDate !== '-')
        return (
          <div className="w-30 ml-1 flex items-start justify-center">
            {isLoading ? (
              <CircularProgress className="ml-8" />
            ) : (
              <>
                <FileUploadComponent
                  onFilesSelected={(filesList: FileList | null) => {
                    const filesArray = filesList ? Array.from(filesList) : [];
                    handleFilesSelected(
                      filesArray,
                      String(props.row.original.id),
                    );
                  }}
                  rowId={`${String(props.row.original.id)}-online`}
                />
                <ViewFiles
                  files={files ?? []}
                  onDeleteFunction={handleDeleteEvidence}
                  deleteFileDescrption={`do histórico do colaborador ${props.row.original.employee.name} não poderá ser recuperado`}
                  queryKey={['get-evidence-files', historicId]}
                  isLoading={isFetchingFiles}
                  filesCount={props.row.original?.evidencesCount}
                />
              </>
            )}
          </div>
        );
    },
  },
];
