export enum RequirementCategoryEnum {
  activeProfessionalRecords = 'Registros Profissionais Ativos',
  knowledge = 'Conhecimentos',
  softwares = 'Softwares',
  certifications = 'Certificações',
  levelOfEducation = 'Grau de Escolaridade',
  technicalEducation = 'Ensino Técnico',
  undergraduate = 'Graduação',
  postgraduate = 'Pós Graduação',
  otherCourses = 'Outros Cursos',
  languages = 'Idiomas',
  other = 'Outros',
}
