import { Autocomplete, TextField } from '@mui/material';
import { Control, Controller } from 'react-hook-form';

import { cn } from '@/lib/utils';
import { Option } from '@/types/Filter';

type Props = {
  title: string;
  options: Option[];
  control: Control<any>;
  name: string;
  setSearch: (val: string) => void;
  required?: boolean;
  disabled?: boolean;
  className?: string;
};

const SearchSelect = ({
  title,
  options,
  control,
  name,
  setSearch,
  required,
  disabled,
  className,
}: Props) => {
  return (
    <div>
      <div className={'text-[12px] text-[#4A4A4A]'}>
        {title}
        {required && <span className="text-red">*</span>}
      </div>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value, ref } }) => (
          <Autocomplete
            disablePortal
            options={options}
            value={options.find((option) => option.value === value) || null}
            onChange={(event, newValue) => {
              onChange(newValue?.value || '');
            }}
            disabled={disabled}
            renderInput={(params) => (
              <TextField
                {...params}
                required={required}
                inputRef={ref}
                onChange={(e) => setSearch(e.target.value)}
                sx={{
                  height: '2rem',
                  '& input': {
                    padding: '0.5rem 0',
                    fontSize: '12px',
                  },
                  '& .MuiOutlinedInput-root': {
                    height: '2rem',
                    fontSize: '12px',
                  },
                }}
              />
            )}
            noOptionsText="Nenhum resultado encontrado"
            className={cn('w-[13.5rem]', className)}
            sx={{
              fontSize: '12px',
              '& .MuiAutocomplete-paper': {
                height: 'auto',
              },
              '& .MuiAutocomplete-inputRoot': {
                height: '2rem',
              },
              '& .MuiAutocomplete-clearIndicator': {
                height: '1.5rem',
              },
              '& + .MuiAutocomplete-popper .MuiAutocomplete-option': {
                fontSize: '12px',
              },
            }}
          />
        )}
      />
    </div>
  );
};

export default SearchSelect;
