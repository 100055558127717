import { Team } from '../types/ViewSchedule';
export const subtitle: Record<Team, string> = {
  T1: 'Trabalha na turma 1',
  T1D: 'Trabalha na turma 1 do dia',
  T1N: 'Trabalha na turma 1 da noite',
  T2: 'Trabalha na turma 2',
  T2D: 'Trabalha na turma 2 do dia',
  T2N: 'Trabalha na turma 2 da noite',
  T3: 'Trabalha na turma 3',
  T3D: 'Trabalha na turma 3 do dia',
  T3N: 'Trabalha na turma 3 da noite',
  T4: 'Trabalha na turma 4',
  T4D: 'Trabalha na turma 4 do dia',
  T4N: 'Trabalha na turma 4 da noite',
  ADM: 'Administrativo',
  FE: 'Férias',
  'FE !': 'Férias sem cobertura',
  FO: 'Folga',
  FD: 'Feriado',
  LC: 'Licença',
  CL: 'Cobrindo licença',
  CF: 'Cobrindo férias',
  CFE: 'Consequência de férias',
  TR: 'Em Treinamento',
  SA: 'Sobreaviso',
  'SA*': 'Sobreaviso acionado',
  IE: 'Ida extra ao polo',
  'T1*': 'Extensão de jornada',
  'N/A': 'Colaborador não alocado',
  '-': 'Antes do início da estrutura',
};

export const subtitleEntries: [Team, string][] = Object.entries(subtitle) as [
  Team,
  string,
][];

export const getSubtitleByTeamName = (teamName: Team, message?: string) => {
  let subtitleText = Object.keys(subtitle).includes(teamName)
    ? subtitle[teamName]
    : '';

  if (message) {
    subtitleText += `${message}`;
  }

  return subtitleText;
};
