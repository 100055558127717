import { useQuery } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useRecoilState } from 'recoil';
import { z } from 'zod';

import { queryClient } from '@/App';
import { FormSchema } from '@/constants/formSchema/PositionFormSchema';
import api from '@/services/apiSgft';
import { multipleReportsAtom } from '@/state/MultipleReports.atom';
import { positionParentsAtom } from '@/state/PositionParents.atom';

import { Button } from '../atoms/Button';
import ControlledCheckbox from '../atoms/ControlledCheckbox';
import ControlledSimpleSelect from '../atoms/ControlledSimpleSelect';
import Modal from '../atoms/Modal';
import NumericInput from '../atoms/NumericInput';
import Spinner from '../atoms/Spinner';
import TooltipIconText from '../atoms/TooltipIconText';
import { Position } from '../organisms/ChartGraph';

interface PositionModalProps {
  isOpen: boolean;
  onClose: () => void;
  data: Position;
  type: 'create' | 'edit' | 'delete';
}

const PositionModal: React.FC<PositionModalProps> = ({
  isOpen,
  onClose,
  data,
  type,
}) => {
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [multipleReports, setMultipleReports] =
    useRecoilState(multipleReportsAtom);
  const [_, setPositionParents] = useRecoilState(positionParentsAtom);
  const id = data.id;
  const methods = useForm<z.infer<typeof FormSchema>>({
    defaultValues: {
      companyId: data.board?.company?.id ?? 1,
      board: data.board.id,
      management: data?.management?.id,
      coordination: data?.coordination?.id,
      workStation: data?.workStation.id,
      regime: data.regime,
      reportTo:
        type === 'edit' || type === 'delete'
          ? data.reportsTo.at(0)?.id
          : data.id,
      amountOfExpectedEmployees:
        type === 'edit' || type === 'delete'
          ? data.amountOfExpectedEmployees
          : 1,
      jobTitle:
        type === 'edit' || type === 'delete'
          ? data.generalInformationJobTitle?.id
          : undefined,
      isIntermediary:
        type === 'edit' || type === 'delete' ? data.isIntermediary : false,
      amountOfPositions: type === 'edit' || type === 'delete' ? undefined : 1,
      multipleReports: multipleReports.parentsId,
      multiplePositions: false,
      otherCompany: !!data?.otherCompanyConnection?.id,
      otherCompanyId: data?.otherCompanyConnection?.id ?? undefined,
    },
  });
  const companyWatch = methods.watch('companyId');
  const boardWatch = methods.watch('board');
  const managementWatch = methods.watch('management');
  const jobTitlesWatch = methods.watch('jobTitle');
  const multiplePositionsWatch = methods.watch('multiplePositions');
  const otherCompaniesWatch = methods.watch('otherCompany');

  const onSubmit = async (data: z.infer<typeof FormSchema>) => {
    setIsSubmiting(true);

    try {
      if (type === 'create') {
        await api.post('position/create-position', {
          coordinationId: data.coordination,
          workStationId: data.workStation,
          regime: data.regime,
          reportToIds: data.multipleReports.length
            ? data.multipleReports
            : [data.reportTo],
          generalInformationJobTitleId: data.jobTitle,
          isIntermediary: data.isIntermediary,
          amountOfExpectedEmployees: data.amountOfExpectedEmployees,
          amountOfPositions: data.amountOfPositions,
          boardId: data.board,
          companyId: data.companyId,
          otherCompanyId: data.otherCompanyId ?? undefined,
        });
        toast.success('Nova posição de reporte criada com sucesso!', {
          theme: 'colored',
          toastId: 'success',
        });
      } else if (type === 'edit') {
        await api.put(`position/update-position/${id}`, {
          regime: data.regime,
          isIntermediary: data.isIntermediary,
          amountOfExpectedEmployees: data.amountOfExpectedEmployees,
          workStationId: data.workStation,
          boardId: data.board,
          companyId: data.companyId,
          otherCompanyId: otherCompaniesWatch ? data.otherCompanyId : null,
        });
        toast.success('Posição editada com sucesso!', {
          theme: 'colored',
          toastId: 'success',
        });
      } else if (type === 'delete') {
        await api.delete(`position/delete-position/${id}`);

        toast.success('Posição de reporte excluída com sucesso!', {
          theme: 'colored',
          toastId: 'success',
        });
        queryClient.invalidateQueries(['roles-chart']);
      }

      closeAndClear();
    } catch (error: any) {
      let errorMessage = `Erro ao ${type === 'create' ? 'criar' : type === 'edit' ? 'editar' : 'excluir'} posição de reporte, tente novamente mais tarde!`;
      if (error.response.status !== 500) {
        errorMessage = error.response.data.message;
      }
      toast.error(errorMessage, {
        theme: 'colored',
        toastId: 'error',
      });
      throw error;
    } finally {
      setIsSubmiting(false);
    }
  };

  const closeAndClear = () => {
    setMultipleReports(() => ({
      parentsId: [],
      positionId: null,
    }));
    setPositionParents([]);
    methods.reset();
    onClose();
    queryClient.invalidateQueries(['roles-chart']);
    queryClient.invalidateQueries(['employees-chart']);
  };
  const fetchData = async (url: string, params?: Record<string, any>) => {
    const response = await api.get(url, { params });
    return response.data.map((item: { id: any; name: any }) => ({
      value: item.id,
      label: item.name,
    }));
  };

  const { data: boardsData, isLoading: boardsIsLoading } = useQuery(
    ['all-board', companyWatch],
    () =>
      fetchData('/board/filter', {
        companyFilter: companyWatch ? [companyWatch] : [],
      }),
    {
      retry: false,
      enabled: !!companyWatch,
    },
  );

  const { data: managementsData, isLoading: managementsIsLoading } = useQuery(
    ['all-managements', boardWatch],
    () =>
      fetchData('/new-management/all', {
        boardFilter: boardWatch ? [boardWatch] : [],
      }),
    {
      retry: false,
      enabled: !!boardWatch,
    },
  );

  const { data: coordinationsData, isLoading: coordinationsIsLoading } =
    useQuery(
      ['all-coordinations', managementWatch],
      () =>
        fetchData('/coordination/all', {
          managementFilter: managementWatch ? [managementWatch] : [],
        }),
      {
        retry: false,
        enabled: !!managementWatch,
      },
    );

  const fetchJobTitles = async () => {
    const response = await api.get(
      '/job-title/general-information-job-titles/all',
      {
        params: {
          managementFilter: managementWatch ? [managementWatch] : [],
        },
      },
    );
    return response.data;
  };
  const { data: jobTitles, isLoading: jobTitlesIsLoading } = useQuery(
    ['all-job-titles', managementWatch],
    fetchJobTitles,
    {
      retry: false,
      enabled: !!managementWatch,
      refetchOnWindowFocus: 'always',
    },
  );
  const jobTitlesData = jobTitles?.map(
    (item: { id: number; name: string }) => ({
      value: item.id,
      label: item.name,
    }),
  );
  const workStationsData =
    jobTitles
      ?.find(
        (it: { id: number }) =>
          it.id === jobTitlesWatch ||
          it.id === data.generalInformationJobTitle.id,
      )
      ?.workStations.map((item: { id: number; name: string }) => ({
        value: item.id,
        label: item.name,
      })) ?? [];

  useEffect(() => {
    methods.reset({});
  }, []);

  const positionsToReport =
    type === 'edit' || type === 'delete'
      ? [
          {
            value: data.reportsTo.at(0)?.id ?? '',
            label: data.reportsTo.at(0)?.name ?? '',
          },
        ]
      : [
          {
            value: data.id,
            label: data.name,
          },
        ];

  const multipleReportsOptions = [
    {
      value: data.id,
      label: 'MÚLTIPLO REPORTES',
    },
  ];

  const getCompanies = async () => {
    try {
      const response = await api.get('/company/all');
      return response.data.map((el: { id: number; name: string }) => ({
        value: el.id,
        label: el.name,
      }));
    } catch (e) {
      toast.error('Erro ao carregar empresas!', {
        theme: 'colored',
        toastId: 'error',
      });
    }
  };

  const { data: companiesData, isLoading: companiesIsLoading } = useQuery(
    ['get-all-companies'],
    getCompanies,
  );

  return (
    <Modal
      isOpen={isOpen}
      onClose={closeAndClear}
      title={`${type === 'create' ? 'Criar nova posição na estrutura' : type === 'edit' ? 'Editar posição' : 'Excluir posição na estrutura'}`}
      className="w-[48rem] max-w-[85vw]"
    >
      <FormProvider {...methods}>
        <form
          className="space-between flex h-[90%] flex-col px-5 py-2"
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          {companiesIsLoading ||
          boardsIsLoading ||
          managementsIsLoading ||
          coordinationsIsLoading ||
          jobTitlesIsLoading ? (
            <div className="flex h-[19rem] w-full justify-center">
              <Spinner />
            </div>
          ) : (
            <>
              <div className="mb-4 flex w-full justify-between">
                <ControlledSimpleSelect
                  title={'Empresa'}
                  required
                  control={methods.control}
                  name={`companyId`}
                  width="14.5rem"
                  values={companiesData}
                  readOnly={type === 'delete' || type === 'edit'}
                  onChange={() => {
                    methods.resetField('otherCompanyId');
                  }}
                />
                <ControlledSimpleSelect
                  title={'Diretoria'}
                  required
                  control={methods.control}
                  name={`board`}
                  width="14.5rem"
                  values={boardsData}
                  disabled={!companyWatch}
                  readOnly={type === 'delete' || type === 'edit'}
                />
                <ControlledSimpleSelect
                  title={'Gerência'}
                  required
                  control={methods.control}
                  name={'management'}
                  width="14.5rem"
                  values={managementsData}
                  disabled={!boardWatch}
                  readOnly={type === 'delete' || type === 'edit'}
                />
              </div>
              <div className="mb-4 flex w-full justify-between">
                <ControlledSimpleSelect
                  title={'Coordenação'}
                  required
                  control={methods.control}
                  name={'coordination'}
                  width="14.5rem"
                  values={coordinationsData}
                  disabled={!managementWatch}
                  readOnly={type === 'delete' || type === 'edit'}
                />
                <ControlledSimpleSelect
                  title={'Cargo'}
                  required
                  control={methods.control}
                  name={`jobTitle`}
                  width="14.5rem"
                  values={jobTitlesData}
                  disabled={!managementWatch}
                  readOnly={type === 'delete' || type === 'edit'}
                  onClick={fetchJobTitles}
                />
                <ControlledSimpleSelect
                  title={'Local de Trabalho'}
                  required
                  control={methods.control}
                  name={'workStation'}
                  width="14.5rem"
                  values={workStationsData}
                  disabled={!jobTitlesWatch}
                  readOnly={type === 'delete'}
                />
              </div>
              <div className="mb-4 flex justify-between">
                <ControlledSimpleSelect
                  title={'Regime'}
                  required
                  control={methods.control}
                  name={'regime'}
                  width="14.5rem"
                  values={[
                    { value: 'ADM', label: 'ADM' },
                    { value: 'TURNO', label: 'TURNO' },
                  ]}
                  readOnly={type === 'delete'}
                />
                <ControlledSimpleSelect
                  title={'Reporte (posição)'}
                  required
                  control={methods.control}
                  name={'reportTo'}
                  width="14.5rem"
                  values={
                    multipleReports.parentsId.length > 1
                      ? multipleReportsOptions
                      : positionsToReport
                  }
                  readOnly={type === 'delete' || type === 'edit'}
                />
                <NumericInput
                  required
                  title={'Colaboradores previstos'}
                  {...methods.register('amountOfExpectedEmployees')}
                  className="w-[14.5rem]"
                  disabled={type === 'delete'}
                />
              </div>
              <div className="flex justify-between">
                {type === 'create' && (
                  <div className="flex w-[24rem] flex-col">
                    <div className="flex">
                      <ControlledCheckbox
                        title={'Criar mais de uma representação na estrutura'}
                        name="multiplePositions"
                        control={methods.control}
                      />
                      <TooltipIconText
                        text={
                          'Criar mais de uma posição com estrutura de liderados diferente entre elas'
                        }
                      />
                    </div>
                    {multiplePositionsWatch && (
                      <NumericInput
                        required
                        title={'Número de posições'}
                        {...methods.register('amountOfPositions')}
                        className="w-[22rem]"
                      />
                    )}
                  </div>
                )}
                {
                  <div className="flex w-[24rem] flex-col">
                    <div className="flex">
                      <ControlledCheckbox
                        title={'Incluir posição na estrutura de outra empresa'}
                        name="otherCompany"
                        control={methods.control}
                      />
                      <TooltipIconText
                        text={
                          'Incluir a posição também no organograma da empresa selecionada'
                        }
                      />
                    </div>
                    {otherCompaniesWatch && (
                      <ControlledSimpleSelect
                        title={'Empresa'}
                        required
                        control={methods.control}
                        name={`otherCompanyId`}
                        width="22rem"
                        values={companiesData.filter(
                          (el: { value: number; label: string }) =>
                            el.value !== methods.getValues('companyId'),
                        )}
                      />
                    )}
                  </div>
                }
              </div>
              <div className="flex justify-between">
                <ControlledCheckbox
                  title={'Posicionar em nível intermediário na estrutura'}
                  name="isIntermediary"
                  control={methods.control}
                  disabled={type === 'delete'}
                />
              </div>
              <div className="my-2 mt-[1.5rem] flex w-full justify-between">
                <Button
                  type="button"
                  className="h-8 min-w-36 border-2 border-[#193CB9] bg-white text-[#193CB9] hover:bg-[#e9eaf1]"
                  onClick={closeAndClear}
                >
                  Cancelar
                </Button>
                <div className="flex">
                  {type !== 'delete' && (
                    <Button
                      type="button"
                      className="mr-2 h-8 min-w-36 border-2 border-[#193CB9] bg-white text-[#193CB9] hover:bg-[#e9eaf1]"
                      onClick={() =>
                        window.open('/home/cargos-e-requisitos', '_blank')
                      }
                    >
                      Criar/Editar Cargo
                    </Button>
                  )}
                  <Button
                    type="submit"
                    className=" h-8 min-w-36"
                    isLoading={isSubmiting}
                  >
                    {`${type === 'delete' ? 'Excluir' : 'Salvar'}`}
                  </Button>
                </div>
              </div>
            </>
          )}
        </form>
      </FormProvider>
    </Modal>
  );
};

export default PositionModal;
