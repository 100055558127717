import { UserTypesEnum } from '@/enums/UserTypesEnum';
import Board from '@/pages/Board';
import Chart from '@/pages/Chart';
import Employees from '@/pages/Employees';
import FixedAllocation from '@/pages/FixedAllocation';
import Home from '@/pages/Home';
import { NoPermission } from '@/pages/NoPermission';
import NotFound from '@/pages/NotFound';
import Pendencies from '@/pages/Pendencies';
import RequirementControlPage from '@/pages/RequirementControlPage';
import SuppliersView from '@/pages/SuppliersView';
import TeamsRegister from '@/pages/TeamsEdit';
import TeamsRegisterPreview from '@/pages/TeamsRegisterPreview';
import TrainingControlPage from '@/pages/TrainingControlPage';
import TrainingFiles from '@/pages/TrainingFiles';
import TrainingFileTeams from '@/pages/TrainingFileTeams';
import TrainingPage from '@/pages/TrainingPage';
import TrainingPlanningPage from '@/pages/TrainingPlanningPage';
import ViewJobTitles from '@/pages/ViewJobTitles';
import ViewSchedule from '@/pages/ViewSchedule';

export const routes = {
  home: {
    name: 'Home',
    path: '/home',
    element: <Home />,
    requiredRoles: [],
  },
  schedule: {
    name: 'Planejamento de Escalas e Férias',
    path: '/home/planejamento-de-escalas-e-ferias',
    element: <ViewSchedule />,
    requiredRoles: [UserTypesEnum.User],
  },
  employees: {
    name: 'Colaboradores',
    path: '/home/colaboradores',
    element: <Employees />,
    requiredRoles: [UserTypesEnum.Rh],
  },
  teamRegister: {
    name: 'Cadastrar Estrutura de Turma',
    path: '/home/planejamento-de-escalas-e-ferias/cadastrar-estrutura-de-turma',
    element: <TeamsRegister />,
    requiredRoles: [UserTypesEnum.Rh],
  },
  teamRegisterPreview: {
    name: 'Cadastrar Estrutura de Turma',
    path: '/home/planejamento-de-escalas-e-ferias/cadastrar-estrutura-de-turma/preview',
    element: <TeamsRegisterPreview />,
    requiredRoles: [UserTypesEnum.Rh],
  },
  trainingMatrix: {
    name: 'Matriz de Treinamento',
    path: '/home/matriz-treinamento',
    element: <TrainingPage />,
    requiredRoles: [
      UserTypesEnum.User,
      UserTypesEnum.RhTreinamento,
      UserTypesEnum.QsmsTreinamento,
    ],
  },
  trainingControl: {
    name: 'Controle de Treinamento',
    path: '/home/controle-treinamentos',
    element: <TrainingControlPage />,
    requiredRoles: [UserTypesEnum.RhTreinamento, UserTypesEnum.QsmsTreinamento],
  },
  trainingSuppliers: {
    name: 'Fornecedores',
    path: '/home/visualizar-fornecedores',
    element: <SuppliersView />,
    requiredRoles: [UserTypesEnum.RhTreinamento, UserTypesEnum.QsmsTreinamento],
  },
  trainingFiles: {
    name: 'Ficheiros',
    path: '/home/ficheiros',
    element: <TrainingFiles />,
    requiredRoles: [UserTypesEnum.RhTreinamento, UserTypesEnum.QsmsTreinamento],
  },
  trainingPlanning: {
    name: 'Planejamento de Turma',
    path: '/home/ficheiros/:trainingFileId/planejamento-de-turma',
    element: <TrainingPlanningPage />,
    requiredRoles: [UserTypesEnum.RhTreinamento, UserTypesEnum.QsmsTreinamento],
  },
  trainingPlanningEdit: {
    name: 'Planejamento de Turma',
    path: '/home/ficheiros/:trainingFileId/planejamento-de-turma/:trainingPlanningId',
    element: <TrainingPlanningPage />,
    requiredRoles: [UserTypesEnum.RhTreinamento, UserTypesEnum.QsmsTreinamento],
  },
  trainingPlanningTeams: {
    name: 'Detalhes do Planejamento',
    path: '/home/ficheiros/detalhamento/:id/',
    element: <TrainingFileTeams />,
    requiredRoles: [UserTypesEnum.RhTreinamento, UserTypesEnum.QsmsTreinamento],
  },
  organizationChart: {
    name: 'Visualizar Estrutura e Organograma',
    path: '/home/visualizar-estrutura-organograma',
    element: <Chart />,
    requiredRoles: [],
  },
  fixedAllocations: {
    name: 'Cadastro de Alocações Fixas',
    path: '/home/cadastro-alocacoes-fixas',
    element: <FixedAllocation />,
    requiredRoles: [UserTypesEnum.Rh],
  },
  jobTitle: {
    name: 'Cargos',
    path: '/home/cargos-e-requisitos',
    element: <ViewJobTitles />,
    requiredRoles: [],
  },
  requirementsControl: {
    name: 'Controle de Requisitos',
    path: '/home/controle-de-requisitos',
    element: <RequirementControlPage />,
    requiredRoles: [UserTypesEnum.Rh],
  },
  pendencies: {
    name: 'Pendências',
    path: '/home/pendencias',
    element: <Pendencies />,
    requiredRoles: [UserTypesEnum.Rh],
  },
  boardRegister: {
    name: 'Cadastro de Diretoria',
    path: '/home/cadastro-de-diretoria',
    element: <Board />,
    requiredRoles: [UserTypesEnum.Rh],
  },
  noPermission: {
    name: 'Sem permissão',
    path: '/home/sem-permissao',
    element: <NoPermission />,
    requiredRoles: [],
  },
  notFound: {
    name: 'Página não encontrada',
    path: '*',
    element: <NotFound />,
    requiredRoles: [],
  },
};
