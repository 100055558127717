import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';

import { UserTypesEnum } from '@/enums/UserTypesEnum';
import { useRoles } from '@/hooks/useRoles';

type ProtectedRouteProps = {
  requiredRoles: UserTypesEnum[];
  children: ReactNode;
};

export const ProtectedRoute = ({
  requiredRoles,
  children,
}: ProtectedRouteProps) => {
  const { hasSomeRoles } = useRoles();
  if (hasSomeRoles(requiredRoles)) {
    return children;
  }
  return <Navigate to="/home/sem-permissao" />;
};
