import { DialogActions, Modal, Tooltip } from '@mui/material';
import { useInfiniteQuery } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { extraordinaryThirdPartyAllocationColumns } from '@/constants/tableColumns/TrainingPlanningTableColumns';
import { OnlineTrainingType } from '@/constants/TrainingConstants';
import { TrainingDay } from '@/types/TrainingPlanning';
import { TrainingPlanningEmployee } from '@/types/TrainingPlanningEmployee';
import { TrainingPlanningForm } from '@/types/TrainingPlanningForm';

import { PermissionMessages } from '../../constants/PermissionMessages';
import api from '../../services/apiSgft';
import { Button } from '../atoms/Button';
import CloseButton from '../atoms/CloseButton';
import Line from '../atoms/Line';
import SubtitleText from '../atoms/SubtitleText';
import SearchInput from '../molecules/SearchInput';
import InfiniteTable from './InfiniteTable';

interface ThirdPartyAllocationModalProps {
  isOpen: boolean;
  training: string;
  trainingDays: TrainingDay[];
  modality: string;
  onClose: () => void;
  trainingPlanning: TrainingPlanningForm;
  setTrainingPlanning: (a: TrainingPlanningForm) => void;
  trainingPlanningId?: string;
  onlineTrainingType?: string;
  isThirdParty?: boolean;
}

const ThirdPartyAllocationModal: React.FC<ThirdPartyAllocationModalProps> = ({
  isOpen,
  training,
  trainingDays,
  modality,
  onClose,
  onlineTrainingType,
  trainingPlanningId,
  trainingPlanning,
  setTrainingPlanning,
}) => {
  const methods = useForm();
  const { watch } = methods;

  const [searchedEmployeeName, setSearchedEmployeeName] = useState('');
  const canSave: boolean | undefined = trainingPlanning.selectedEmployee.some(
    (el) => el.isThirdParty,
  );
  const asyncTraining = onlineTrainingType === OnlineTrainingType.Async;

  const fetchEmployees = async ({
    pageParam = 1,
  }): Promise<{
    employees: TrainingPlanningEmployee[];
    totalResults: number;
    nextPage: number | null;
  }> => {
    try {
      const response = await api.get(
        `training-plannings/trainings/${training}/extra-allocations`,
        {
          params: {
            size: 6,
            page: pageParam,
            employee: searchedEmployeeName,
            trainingDays,
            trainingPlanningId,
            modality,
            onlineTrainingType,
            alreadySelectedEmployees: trainingPlanning.selectedEmployee.map(
              (el) => el.employee.employeeNumber,
            ),
            isThirdParty: true,
          },
        },
      );
      return response.data;
    } catch (e) {
      toast.error('Erro ao carregar os dados', {
        theme: 'colored',
        toastId: 'error',
      });
      throw e;
    }
  };

  const onSubmit = () => {
    handleCreate();
    methods.reset();
    setSearchedEmployeeName('');
    onClose();
  };

  const handleCreate = async () => {
    const index = trainingPlanning.selectedEmployee.findIndex(
      (el) =>
        el.employee.employeeNumber ===
        trainingPlanning.extraordinaryAllocationId,
    );

    if (index !== -1) {
      const updatedEmployee = {
        ...trainingPlanning.selectedEmployee[index],
        extraordinaryAllocationMotive: 'TERCEIRO',
      };

      const updatedSelectedEmployee = [
        ...trainingPlanning.selectedEmployee.slice(0, index),
        updatedEmployee,
        ...trainingPlanning.selectedEmployee.slice(index + 1),
      ];

      setTrainingPlanning({
        selectedEmployee: updatedSelectedEmployee,
        isAllEmployeeSelected: trainingPlanning.isAllEmployeeSelected,
        trainingDate: trainingPlanning.trainingDate,
        extraordinaryAllocationId: trainingPlanning.extraordinaryAllocationId,
      });

      methods.reset();
      setSearchedEmployeeName('');
      onClose();
    }
  };

  const handleClose = () => {
    const updatedSelectedEmployee = trainingPlanning.selectedEmployee.filter(
      (el) =>
        !el.isExtraordinaryAllocation ||
        (!el.isThirdParty && el.extraordinaryAllocationMotive !== ''),
    );

    setTrainingPlanning({
      selectedEmployee: updatedSelectedEmployee,
      extraordinaryAllocationId: '',
      isAllEmployeeSelected: false,
      trainingDate: trainingPlanning.trainingDate,
    });

    setSearchedEmployeeName('');
    methods.reset();
    onClose();
  };

  const handleInputChange = (name: string) => {
    setSearchedEmployeeName(name);
  };

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (
        name === 'employeeName' &&
        type === 'change' &&
        typeof value?.employeeName === 'string'
      ) {
        handleInputChange(value?.employeeName);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const {
    data: employees,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
  } = useInfiniteQuery(
    ['training-planning-employee-third-party', searchedEmployeeName],
    fetchEmployees,
    {
      retry: false,
      getNextPageParam: (actualPage) => {
        return actualPage.nextPage;
      },
    },
  );

  const employeesData =
    employees?.pages.flatMap((page) => page.employees) ?? [];

  return (
    <Modal open={isOpen} onClose={handleClose} style={{ display: 'block' }}>
      <div onClick={(e) => e.stopPropagation()}>
        <div className="fixed inset-0 flex w-screen items-center justify-center bg-black/30 p-4">
          <div className="custom-scrollbar max-h-[85vh] w-[90rem] flex-col justify-between overflow-auto overflow-y-scroll rounded-lg bg-white">
            <div className="flex h-auto items-center justify-between px-4 pb-1 pt-3">
              <SubtitleText
                className="text-[20px]"
                subtitle={'Adicionar alocação para terceiros'}
              />
              <CloseButton onClick={handleClose} className="mt-[-1rem]" />
            </div>
            <Line />
            <FormProvider {...methods}>
              <form
                className="space-between flex h-[90%] flex-col gap-8 pt-5"
                onSubmit={methods.handleSubmit(onSubmit)}
              >
                <div className="flex max-h-[52vh] flex-col items-start gap-2 px-6">
                  <div className="w-full px-4">
                    <SearchInput
                      placeholder="Pesquisar por colaborador"
                      autoFocus
                      {...methods.register('employeeName')}
                    />
                  </div>
                  {employeesData && employeesData.length > 0 && (
                    <InfiniteTable
                      hasNextPage={hasNextPage}
                      data={employeesData}
                      columns={extraordinaryThirdPartyAllocationColumns(
                        trainingPlanning,
                        setTrainingPlanning,
                      )}
                      fetchNextPage={fetchNextPage}
                      isFetchingNextPage={isFetchingNextPage}
                      tableContainerClassName="min-h-32"
                      columnVisibility={{
                        trainings: !asyncTraining,
                      }}
                    />
                  )}
                </div>

                <DialogActions
                  sx={{
                    width: 'full',
                    display: 'flex',
                    justifyContent: 'space-between',
                    paddingBottom: '25px',
                    marginX: '10px',
                  }}
                >
                  <Button
                    type="button"
                    className="min-w-36 border-2 border-[#193CB9] bg-white text-[#193CB9] hover:bg-[#e9eaf1]"
                    onClick={handleClose}
                  >
                    Cancelar
                  </Button>
                  <Tooltip
                    title={PermissionMessages.NoPermission}
                    disableHoverListener={canSave}
                  >
                    <Button
                      type="submit"
                      className="min-w-36"
                      disabled={!canSave}
                    >
                      Confirmar
                    </Button>
                  </Tooltip>
                </DialogActions>
              </form>
            </FormProvider>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ThirdPartyAllocationModal;
